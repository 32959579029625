import { Modal } from '../ui/Modal';
import { useQuery } from '@tanstack/react-query';
import { Budget } from '../../types';
import { ArrowRight } from 'lucide-react';

interface BudgetComparisonModalProps {
  isOpen: boolean;
  onClose: () => void;
  budgetIds: string[];
}

export function BudgetComparisonModal({ isOpen, onClose, budgetIds }: BudgetComparisonModalProps) {
  const { data: budgets, isLoading } = useQuery({
    queryKey: ['budgets', budgetIds],
    queryFn: async () => {
      // Mock data for development
      return budgetIds.map(id => ({
        id,
        projectName: `Project ${id}`,
        total: 150000,
        margin: 30,
        taxRate: 15,
        items: [
          {
            description: 'Development',
            category: 'labor',
            quantity: 160,
            unitPrice: 150,
            total: 24000,
          },
        ],
      })) as Budget[];
    },
    enabled: budgetIds.length === 2,
  });

  if (isLoading || !budgets) {
    return null;
  }

  const [budget1, budget2] = budgets;

  const calculateDifference = (value1: number, value2: number) => {
    const diff = value2 - value1;
    const percentage = (diff / value1) * 100;
    return {
      value: diff,
      percentage,
      isPositive: diff > 0,
    };
  };

  const totalDiff = calculateDifference(budget1.total, budget2.total);
  const marginDiff = calculateDifference(budget1.margin, budget2.margin);
  const taxDiff = calculateDifference(budget1.taxRate, budget2.taxRate);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Budget Comparison"
    >
      {/* Modal content */}
    </Modal>
  );
}