import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Repeat } from 'lucide-react';

const costSchema = z.object({
  description: z.string().min(1, 'Descrição é obrigatória'),
  category: z.string().min(1, 'Categoria é obrigatória'),
  amount: z.number().min(0.01, 'Valor deve ser maior que zero'),
  dueDate: z.string(),
  type: z.enum(['fixed', 'variable']),
  supplier: z.string().min(1, 'Fornecedor é obrigatório'),
  notes: z.string().optional(),
  status: z.enum(['pending', 'paid', 'overdue']),
});

type CostFormData = z.infer<typeof costSchema>;

interface CostCategoryFormProps {
  cost?: any;
  onSubmit: (data: CostFormData) => Promise<void>;
  onCancel: () => void;
  onRecurringSetup: (cost: CostFormData) => void;
}

export function CostCategoryForm({
  cost,
  onSubmit,
  onCancel,
  onRecurringSetup,
}: CostCategoryFormProps) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<CostFormData>({
    resolver: zodResolver(costSchema),
    defaultValues: cost || {
      type: 'variable',
      status: 'pending',
      dueDate: new Date().toISOString().split('T')[0],
    },
  });

  const costType = watch('type');

  const handleFormSubmit = async (data: CostFormData) => {
    if (data.type === 'fixed') {
      onRecurringSetup(data);
    } else {
      await onSubmit(data);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Tipo de Custo
        </label>
        <select
          {...register('type')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="variable">Variável</option>
          <option value="fixed">Fixo</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Descrição
        </label>
        <input
          type="text"
          {...register('description')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        {errors.description && (
          <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Categoria
        </label>
        <select
          {...register('category')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="">Selecione uma categoria</option>
          <option value="Infraestrutura">Infraestrutura</option>
          <option value="Software">Software</option>
          <option value="Marketing">Marketing</option>
          <option value="RH">RH</option>
          <option value="Administrativo">Administrativo</option>
          <option value="Financeiro">Financeiro</option>
          <option value="Outros">Outros</option>
        </select>
        {errors.category && (
          <p className="mt-1 text-sm text-red-600">{errors.category.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Valor
        </label>
        <input
          type="number"
          step="0.01"
          {...register('amount', { valueAsNumber: true })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        {errors.amount && (
          <p className="mt-1 text-sm text-red-600">{errors.amount.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Data de Vencimento
        </label>
        <input
          type="date"
          {...register('dueDate')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Fornecedor
        </label>
        <input
          type="text"
          {...register('supplier')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        {errors.supplier && (
          <p className="mt-1 text-sm text-red-600">{errors.supplier.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Status
        </label>
        <select
          {...register('status')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="pending">Pendente</option>
          <option value="paid">Pago</option>
          <option value="overdue">Atrasado</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Observações
        </label>
        <textarea
          {...register('notes')}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>

      {costType === 'fixed' && (
        <div className="bg-blue-50 p-4 rounded-lg">
          <div className="flex items-center">
            <Repeat className="h-5 w-5 text-blue-400 mr-2" />
            <p className="text-sm text-blue-700">
              Este é um custo fixo. Na próxima etapa você poderá configurar a recorrência.
            </p>
          </div>
        </div>
      )}

      <div className="mt-6 flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancelar
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
        >
          {isSubmitting ? 'Salvando...' : costType === 'fixed' ? 'Próximo' : 'Salvar'}
        </button>
      </div>
    </form>
  );
}