import { CalendarEvent } from '../../types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  Calendar,
  Clock,
  MapPin,
  Users,
  Link,
  DollarSign,
  Building2,
  Flag,
} from 'lucide-react';

interface EventDetailsProps {
  event: CalendarEvent;
  onClose: () => void;
  onEdit: () => void;
}

export function EventDetails({ event, onClose, onEdit }: EventDetailsProps) {
  const getEventTypeLabel = (type: string) => {
    switch (type) {
      case 'client_meeting':
        return 'Reunião com Cliente';
      case 'internal_meeting':
        return 'Reunião Interna';
      case 'project_milestone':
        return 'Marco do Projeto';
      case 'sales_meeting':
        return 'Reunião de Vendas';
      case 'budget_review':
        return 'Revisão de Orçamento';
      default:
        return 'Outro';
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium text-gray-900">{event.title}</h3>
        <span className="mt-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
          {getEventTypeLabel(event.type)}
        </span>
      </div>

      <div className="space-y-4">
        <div className="flex items-center text-sm text-gray-500">
          <Calendar className="h-5 w-5 mr-2" />
          <span>
            {format(event.start, "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}
          </span>
        </div>

        <div className="flex items-center text-sm text-gray-500">
          <Clock className="h-5 w-5 mr-2" />
          <span>
            {format(event.start, 'HH:mm')} - {format(event.end, 'HH:mm')}
          </span>
        </div>

        {event.location && (
          <div className="flex items-center text-sm text-gray-500">
            <MapPin className="h-5 w-5 mr-2" />
            <span>{event.location}</span>
          </div>
        )}

        {event.attendees.length > 0 && (
          <div className="flex items-start text-sm text-gray-500">
            <Users className="h-5 w-5 mr-2 mt-0.5" />
            <div>
              <p className="font-medium mb-1">Participantes:</p>
              <ul className="space-y-1">
                {event.attendees.map((attendee) => (
                  <li key={attendee}>{attendee}</li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {event.metadata && (
          <div className="border-t pt-4 mt-4 space-y-3">
            {event.metadata.projectName && (
              <div className="flex items-center text-sm">
                <Building2 className="h-5 w-5 mr-2 text-gray-400" />
                <span className="text-gray-900">{event.metadata.projectName}</span>
              </div>
            )}

            {event.metadata.clientName && (
              <div className="flex items-center text-sm">
                <Users className="h-5 w-5 mr-2 text-gray-400" />
                <span className="text-gray-900">{event.metadata.clientName}</span>
              </div>
            )}

            {event.metadata.milestone && (
              <div className="flex items-center text-sm">
                <Flag className="h-5 w-5 mr-2 text-gray-400" />
                <span className="text-gray-900">{event.metadata.milestone}</span>
              </div>
            )}

            {(event.metadata.saleValue || event.metadata.budgetValue) && (
              <div className="flex items-center text-sm">
                <DollarSign className="h-5 w-5 mr-2 text-gray-400" />
                <span className="text-gray-900">
                  R${' '}
                  {(
                    event.metadata.saleValue || event.metadata.budgetValue
                  ).toLocaleString('pt-BR')}
                </span>
              </div>
            )}
          </div>
        )}

        {event.relatedId && (
          <div className="flex items-center text-sm text-indigo-600 hover:text-indigo-700">
            <Link className="h-5 w-5 mr-2" />
            <a href={`/${event.category}s/${event.relatedId}`}>
              Ver {event.category === 'project' ? 'projeto' : event.category === 'sale' ? 'venda' : 'orçamento'} relacionado
            </a>
          </div>
        )}
      </div>

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Fechar
        </button>
        <button
          type="button"
          onClick={onEdit}
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
        >
          Editar
        </button>
      </div>
    </div>
  );
}