import { useAuthStore } from '../store/authStore';

type Permission = 'read' | 'write' | 'delete' | 'manage';
type Resource = 'dashboard' | 'companies' | 'finance' | 'users' | 'settings';

const permissionsByRole = {
  admin: {
    dashboard: ['read', 'write'],
    companies: ['read', 'write', 'delete', 'manage'],
    finance: ['read', 'write', 'delete', 'manage'],
    users: ['read', 'write', 'delete', 'manage'],
    settings: ['read', 'write', 'manage'],
  },
  manager: {
    dashboard: ['read'],
    companies: ['read', 'write'],
    finance: ['read', 'write'],
    users: ['read'],
    settings: ['read'],
  },
  user: {
    dashboard: ['read'],
    companies: ['read'],
    finance: ['read'],
    users: ['read'],
    settings: ['read'],
  },
} as const;

export function usePermissions() {
  const { user } = useAuthStore();

  const hasPermission = (resource: Resource, permission: Permission): boolean => {
    if (!user) return false;
    return permissionsByRole[user.role][resource]?.includes(permission) ?? false;
  };

  return {
    hasPermission,
    isAdmin: user?.role === 'admin',
    isManager: user?.role === 'manager',
    isUser: user?.role === 'user',
  };
}