import { create } from 'zustand';
import { Product, ProductStore } from '../types/product';

export const useProductStore = create<ProductStore>((set) => ({
  products: [],
  loading: false,
  error: null,

  fetchProducts: async () => {
    set({ loading: true });
    try {
      // TODO: Replace with actual API call
      const mockProducts = [
        {
          id: '1',
          name: 'AI Chatbot Basic',
          description: 'Basic AI chatbot with standard NLP capabilities',
          category: 'AI Solutions',
          type: 'service',
          basePrice: 50000,
          status: 'active',
          features: [
            'Natural Language Processing',
            'Basic Intent Recognition',
            'Standard Response Templates',
            '24/7 Availability',
          ],
          customizable: true,
          customizationOptions: [
            {
              name: 'Language Models',
              type: 'select',
              options: ['GPT-3', 'GPT-4', 'Custom'],
              affects: 'price',
              impact: 10000,
            },
            {
              name: 'Training Hours',
              type: 'number',
              min: 40,
              max: 200,
              affects: 'price',
              impact: 500,
            },
          ],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
      ];
      set({ products: mockProducts, error: null });
    } catch (error) {
      set({ error: 'Failed to fetch products' });
    } finally {
      set({ loading: false });
    }
  },

  addProduct: async (product) => {
    set({ loading: true });
    try {
      const newProduct = {
        ...product,
        id: Date.now().toString(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      set((state) => ({
        products: [...state.products, newProduct as Product],
        error: null,
      }));
    } catch (error) {
      set({ error: 'Failed to add product' });
    } finally {
      set({ loading: false });
    }
  },

  updateProduct: async (id, product) => {
    set({ loading: true });
    try {
      set((state) => ({
        products: state.products.map((p) =>
          p.id === id
            ? {
                ...p,
                ...product,
                updatedAt: new Date().toISOString(),
              }
            : p
        ),
        error: null,
      }));
    } catch (error) {
      set({ error: 'Failed to update product' });
    } finally {
      set({ loading: false });
    }
  },

  deleteProduct: async (id) => {
    set({ loading: true });
    try {
      set((state) => ({
        products: state.products.filter((p) => p.id !== id),
        error: null,
      }));
    } catch (error) {
      set({ error: 'Failed to delete product' });
    } finally {
      set({ loading: false });
    }
  },
}));