import { useState } from 'react';
import { Modal } from '../ui/Modal';
import { Mail, Paperclip } from 'lucide-react';
import { Budget } from '../../types';

interface BudgetEmailModalProps {
  isOpen: boolean;
  onClose: () => void;
  budget: Budget;
}

export function BudgetEmailModal({ isOpen, onClose, budget }: BudgetEmailModalProps) {
  const [email, setEmail] = useState(budget.clientEmail);
  const [subject, setSubject] = useState(`Orçamento - ${budget.projectName}`);
  const [message, setMessage] = useState(`Prezado(a) ${budget.clientName},

Segue em anexo o orçamento para o projeto "${budget.projectName}".

Valor total: R$ ${budget.total.toLocaleString('pt-BR')}
Validade: ${new Date(budget.validUntil).toLocaleDateString()}

Aguardamos seu retorno.

Atenciosamente,`);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // TODO: Implement email sending
      console.log('Sending email:', { email, subject, message });
      onClose();
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Enviar Orçamento por Email"
    >
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Para
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Assunto
          </label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Mensagem
          </label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={8}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />
        </div>

        <div className="flex items-center text-sm text-gray-500">
          <Paperclip className="h-4 w-4 mr-2" />
          <span>Orçamento_{budget.projectName.replace(/\s+/g, '_')}.pdf</span>
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Mail className="h-4 w-4 mr-2" />
            Enviar
          </button>
        </div>
      </form>
    </Modal>
  );
}