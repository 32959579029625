import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { useQuery } from '@tanstack/react-query';
import { Users, Clock, TrendingUp, AlertCircle } from 'lucide-react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export function ResourceDashboard() {
  const { data: metrics } = useQuery({
    queryKey: ['resource-metrics'],
    queryFn: async () => ({
      totalTeamMembers: 15,
      allocatedResources: 12,
      averageUtilization: 85,
      overallocatedResources: 3,
      utilizationByRole: {
        developer: 90,
        designer: 75,
        manager: 80,
        analyst: 85,
      },
      projectAllocation: {
        'Project A': 35,
        'Project B': 25,
        'Project C': 20,
        'Others': 20,
      },
      upcomingCapacityGaps: [
        { role: 'Developer', gap: -2 },
        { role: 'Designer', gap: -1 },
      ],
    }),
  });

  if (!metrics) return null;

  const utilizationData = {
    labels: Object.keys(metrics.utilizationByRole),
    datasets: [
      {
        label: 'Utilização por Função',
        data: Object.values(metrics.utilizationByRole),
        backgroundColor: 'rgba(99, 102, 241, 0.8)',
      },
    ],
  };

  const allocationData = {
    labels: Object.keys(metrics.projectAllocation),
    datasets: [
      {
        data: Object.values(metrics.projectAllocation),
        backgroundColor: [
          'rgba(99, 102, 241, 0.8)',
          'rgba(59, 130, 246, 0.8)',
          'rgba(147, 51, 234, 0.8)',
          'rgba(249, 115, 22, 0.8)',
        ],
      },
    ],
  };

  return (
    <div className="space-y-6">
      {/* Metrics Cards */}
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Users className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Total de Recursos
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {metrics.totalTeamMembers}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Clock className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Recursos Alocados
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {metrics.allocatedResources}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <TrendingUp className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Utilização Média
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {metrics.averageUtilization}%
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <AlertCircle className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Sobrecarregados
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {metrics.overallocatedResources}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Utilização por Função
          </h3>
          <div className="h-80">
            <Bar
              data={utilizationData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                    max: 100,
                    ticks: {
                      callback: (value) => `${value}%`,
                    },
                  },
                },
              }}
            />
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Alocação por Projeto
          </h3>
          <div className="h-80">
            <Doughnut
              data={allocationData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>

      {/* Capacity Gaps */}
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Gaps de Capacidade Previstos
        </h3>
        <div className="space-y-4">
          {metrics.upcomingCapacityGaps.map((gap) => (
            <div
              key={gap.role}
              className="flex items-center justify-between p-4 bg-red-50 rounded-lg"
            >
              <div>
                <p className="text-sm font-medium text-red-800">{gap.role}</p>
                <p className="text-sm text-red-600">
                  Necessidade de {Math.abs(gap.gap)} recursos
                </p>
              </div>
              <AlertCircle className="h-5 w-5 text-red-500" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}