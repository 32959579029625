import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DollarSign, Calendar, User, Building2 } from 'lucide-react';

interface FunnelCardProps {
  proposal: any;
  isDragging?: boolean;
}

export function FunnelCard({ proposal, isDragging }: FunnelCardProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: proposal.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="bg-white p-3 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-grab active:cursor-grabbing touch-manipulation"
    >
      <div className="flex items-center justify-between">
        <h4 className="text-sm font-medium text-gray-900">{proposal.title}</h4>
      </div>
      
      <div className="mt-2 space-y-2">
        <div className="flex items-center text-sm text-gray-500">
          <Building2 className="h-4 w-4 mr-1" />
          {proposal.clientName}
        </div>
        
        <div className="flex items-center justify-between text-sm">
          <div className="flex items-center text-gray-500">
            <DollarSign className="h-4 w-4 mr-1" />
            R$ {proposal.value.toLocaleString('pt-BR')}
          </div>
          <div className="flex items-center text-gray-500">
            <Calendar className="h-4 w-4 mr-1" />
            {new Date(proposal.validUntil).toLocaleDateString()}
          </div>
        </div>

        <div className="flex items-center text-sm text-gray-500">
          <User className="h-4 w-4 mr-1" />
          {proposal.responsibleName}
        </div>
      </div>
    </div>
  );
}