import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Budget } from '../types';

interface BudgetTemplate {
  id: string;
  name: string;
  budget: Budget;
  createdAt: string;
}

export function useBudgetTemplates() {
  const queryClient = useQueryClient();

  const { data: templates = [], isLoading } = useQuery({
    queryKey: ['budget-templates'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      const storedTemplates = localStorage.getItem('budgetTemplates');
      return storedTemplates ? JSON.parse(storedTemplates) : [];
    },
  });

  const saveTemplate = useMutation({
    mutationFn: async ({ name, budget }: { name: string; budget: Budget }) => {
      const template: BudgetTemplate = {
        id: Date.now().toString(),
        name,
        budget: {
          ...budget,
          id: undefined,
          clientId: undefined,
          projectId: undefined,
          status: 'draft',
        },
        createdAt: new Date().toISOString(),
      };

      const currentTemplates = templates;
      const updatedTemplates = [...currentTemplates, template];
      localStorage.setItem('budgetTemplates', JSON.stringify(updatedTemplates));
      return template;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['budget-templates'] });
    },
  });

  const deleteTemplate = useMutation({
    mutationFn: async (templateId: string) => {
      const currentTemplates = templates;
      const updatedTemplates = currentTemplates.filter((t) => t.id !== templateId);
      localStorage.setItem('budgetTemplates', JSON.stringify(updatedTemplates));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['budget-templates'] });
    },
  });

  return {
    templates,
    isLoading,
    saveTemplate,
    deleteTemplate,
  };
}