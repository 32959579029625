import { Building2, Mail, Phone, MapPin, Calendar, DollarSign } from 'lucide-react';
import { Company } from '../../types';

interface CompanyDetailsProps {
  company: Company;
}

export function CompanyDetails({ company }: CompanyDetailsProps) {
  return (
    <div className="space-y-6">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-24 w-24">
          <Building2 className="h-24 w-24 rounded-full bg-gray-100 p-4 text-gray-500" />
        </div>
        <div className="ml-6">
          <h3 className="text-2xl font-medium text-gray-900">{company.name}</h3>
          <p className="text-sm text-gray-500">{company.industry}</p>
          <div className="mt-2 flex items-center">
            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
              company.status === 'active'
                ? 'bg-green-100 text-green-800'
                : 'bg-red-100 text-red-800'
            }`}>
              {company.status === 'active' ? 'Ativo' : 'Inativo'}
            </span>
            {company.isAICompany && (
              <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indigo-800">
                AI Company
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div className="space-y-4">
          <h4 className="text-lg font-medium text-gray-900">
            Informações de Contato
          </h4>
          <div className="space-y-2">
            <div className="flex items-center text-sm">
              <Phone className="h-5 w-5 text-gray-400 mr-2" />
              <span>{company.phone}</span>
            </div>
            <div className="flex items-start text-sm">
              <MapPin className="h-5 w-5 text-gray-400 mr-2 mt-0.5" />
              <span>{company.address}</span>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <h4 className="text-lg font-medium text-gray-900">
            Informações Comerciais
          </h4>
          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">Projetos Ativos:</span>
              <span className="font-medium">3</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">Receita Total:</span>
              <span className="font-medium">R$ 150.000,00</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">Cliente Desde:</span>
              <span className="font-medium">
                {new Date(company.createdAt).toLocaleDateString()}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <h4 className="text-lg font-medium text-gray-900">Histórico de Projetos</h4>
        <div className="bg-gray-50 rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Projeto
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Valor
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Data
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  AI Chatbot
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Concluído
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  R$ 50.000,00
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  01/03/2024
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}