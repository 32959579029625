import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';

const allocationSchema = z.object({
  resourceId: z.string().min(1, 'Recurso é obrigatório'),
  projectId: z.string().min(1, 'Projeto é obrigatório'),
  role: z.string().min(1, 'Função é obrigatória'),
  startDate: z.string(),
  endDate: z.string(),
  hoursPerDay: z.number().min(1, 'Mínimo de 1 hora').max(8, 'Máximo de 8 horas'),
  status: z.enum(['active', 'upcoming', 'completed']),
});

type AllocationFormData = z.infer<typeof allocationSchema>;

interface AllocationFormProps {
  allocation?: any;
  onSubmit: (data: AllocationFormData) => Promise<void>;
  onCancel: () => void;
}

export function AllocationForm({ allocation, onSubmit, onCancel }: AllocationFormProps) {
  const { data: resources } = useQuery({
    queryKey: ['resources'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        { id: '1', name: 'João Silva', role: 'Developer' },
        { id: '2', name: 'Maria Santos', role: 'Designer' },
      ];
    },
  });

  const { data: projects } = useQuery({
    queryKey: ['projects'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        { id: '1', name: 'Project A' },
        { id: '2', name: 'Project B' },
      ];
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<AllocationFormData>({
    resolver: zodResolver(allocationSchema),
    defaultValues: allocation || {
      startDate: new Date().toISOString().split('T')[0],
      endDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
      hoursPerDay: 8,
      status: 'active',
    },
  });

  const selectedResource = watch('resourceId');
  const selectedResourceData = resources?.find(r => r.id === selectedResource);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Recurso
        </label>
        <select
          {...register('resourceId')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="">Selecione um recurso</option>
          {resources?.map((resource) => (
            <option key={resource.id} value={resource.id}>
              {resource.name} - {resource.role}
            </option>
          ))}
        </select>
        {errors.resourceId && (
          <p className="mt-1 text-sm text-red-600">{errors.resourceId.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Projeto
        </label>
        <select
          {...register('projectId')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="">Selecione um projeto</option>
          {projects?.map((project) => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </select>
        {errors.projectId && (
          <p className="mt-1 text-sm text-red-600">{errors.projectId.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Função
        </label>
        <select
          {...register('role')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="">Selecione uma função</option>
          <option value="developer">Desenvolvedor</option>
          <option value="designer">Designer</option>
          <option value="manager">Gerente</option>
          <option value="analyst">Analista</option>
        </select>
        {errors.role && (
          <p className="mt-1 text-sm text-red-600">{errors.role.message}</p>
        )}
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Data de Início
          </label>
          <input
            type="date"
            {...register('startDate')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Data de Término
          </label>
          <input
            type="date"
            {...register('endDate')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Horas por Dia
        </label>
        <input
          type="number"
          min="1"
          max="8"
          step="0.5"
          {...register('hoursPerDay', { valueAsNumber: true })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        {errors.hoursPerDay && (
          <p className="mt-1 text-sm text-red-600">{errors.hoursPerDay.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Status
        </label>
        <select
          {...register('status')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="active">Ativo</option>
          <option value="upcoming">Futuro</option>
          <option value="completed">Concluído</option>
        </select>
      </div>

      {selectedResourceData && (
        <div className="bg-gray-50 p-4 rounded-lg">
          <h4 className="text-sm font-medium text-gray-900 mb-2">
            Informações do Recurso
          </h4>
          <dl className="grid grid-cols-2 gap-4">
            <div>
              <dt className="text-sm font-medium text-gray-500">Nome</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {selectedResourceData.name}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Função</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {selectedResourceData.role}
              </dd>
            </div>
          </dl>
        </div>
      )}

      <div className="mt-6 flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancelar
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {isSubmitting ? 'Salvando...' : 'Salvar'}
        </button>
      </div>
    </form>
  );
}