import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Plus, Trash2 } from 'lucide-react';
import { useState } from 'react';

const salesmanSchema = z.object({
  name: z.string().min(1, 'Nome é obrigatório'),
  email: z.string().email('Email inválido'),
  phone: z.string().min(1, 'Telefone é obrigatório'),
  document: z.string().min(1, 'Documento é obrigatório'),
  status: z.enum(['active', 'inactive']),
  commissionRules: z.object({
    baseRate: z.number().min(0).max(100),
    specialRates: z.array(z.object({
      category: z.string(),
      rate: z.number().min(0).max(100),
    })),
    minimumValue: z.number().min(0),
    maximumValue: z.number().optional(),
  }),
  paymentInfo: z.object({
    bank: z.string().min(1, 'Banco é obrigatório'),
    agency: z.string().min(1, 'Agência é obrigatória'),
    account: z.string().min(1, 'Conta é obrigatória'),
    accountType: z.enum(['checking', 'savings']),
  }),
});

type SalesmanFormData = z.infer<typeof salesmanSchema>;

interface SalesmanFormProps {
  salesman?: any;
  onSubmit: (data: SalesmanFormData) => Promise<void>;
  onCancel: () => void;
}

export function SalesmanForm({ salesman, onSubmit, onCancel }: SalesmanFormProps) {
  const [specialRates, setSpecialRates] = useState(
    salesman?.commissionRules?.specialRates || []
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<SalesmanFormData>({
    resolver: zodResolver(salesmanSchema),
    defaultValues: salesman || {
      status: 'active',
      commissionRules: {
        baseRate: 5,
        specialRates: [],
        minimumValue: 0,
      },
      paymentInfo: {
        accountType: 'checking',
      },
    },
  });

  const addSpecialRate = () => {
    setSpecialRates([...specialRates, { category: '', rate: 0 }]);
  };

  const removeSpecialRate = (index: number) => {
    setSpecialRates(specialRates.filter((_, i) => i !== index));
  };

  const handleFormSubmit = async (data: SalesmanFormData) => {
    data.commissionRules.specialRates = specialRates;
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-6">
      {/* Basic Information */}
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-gray-900">
          Informações Básicas
        </h3>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Nome
          </label>
          <input
            type="text"
            {...register('name')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.name && (
            <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            {...register('email')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.email && (
            <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Telefone
          </label>
          <input
            type="text"
            {...register('phone')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.phone && (
            <p className="mt-1 text-sm text-red-600">{errors.phone.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            CPF
          </label>
          <input
            type="text"
            {...register('document')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.document && (
            <p className="mt-1 text-sm text-red-600">{errors.document.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Status
          </label>
          <select
            {...register('status')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="active">Ativo</option>
            <option value="inactive">Inativo</option>
          </select>
        </div>
      </div>

      {/* Commission Rules */}
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-gray-900">
          Regras de Comissão
        </h3>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Taxa Base (%)
          </label>
          <input
            type="number"
            step="0.1"
            {...register('commissionRules.baseRate', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.commissionRules?.baseRate && (
            <p className="mt-1 text-sm text-red-600">
              {errors.commissionRules.baseRate.message}
            </p>
          )}
        </div>

        <div>
          <div className="flex items-center justify-between mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Taxas Especiais
            </label>
            <button
              type="button"
              onClick={addSpecialRate}
              className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
            >
              <Plus className="h-4 w-4 mr-1" />
              Adicionar
            </button>
          </div>
          <div className="space-y-2">
            {specialRates.map((rate, index) => (
              <div key={index} className="flex gap-2">
                <input
                  type="text"
                  value={rate.category}
                  onChange={(e) => {
                    const newRates = [...specialRates];
                    newRates[index].category = e.target.value;
                    setSpecialRates(newRates);
                  }}
                  placeholder="Categoria"
                  className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <input
                  type="number"
                  step="0.1"
                  value={rate.rate}
                  onChange={(e) => {
                    const newRates = [...specialRates];
                    newRates[index].rate = Number(e.target.value);
                    setSpecialRates(newRates);
                  }}
                  placeholder="Taxa (%)"
                  className="w-32 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <button
                  type="button"
                  onClick={() => removeSpecialRate(index)}
                  className="p-2 text-red-600 hover:text-red-800"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Valor Mínimo para Comissão
          </label>
          <input
            type="number"
            step="0.01"
            {...register('commissionRules.minimumValue', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.commissionRules?.minimumValue && (
            <p className="mt-1 text-sm text-red-600">
              {errors.commissionRules.minimumValue.message}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Valor Máximo para Comissão (opcional)
          </label>
          <input
            type="number"
            step="0.01"
            {...register('commissionRules.maximumValue', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </div>

      {/* Payment Information */}
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-gray-900">
          Dados Bancários
        </h3>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Banco
          </label>
          <input
            type="text"
            {...register('paymentInfo.bank')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.paymentInfo?.bank && (
            <p className="mt-1 text-sm text-red-600">
              {errors.paymentInfo.bank.message}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Agência
          </label>
          <input
            type="text"
            {...register('paymentInfo.agency')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.paymentInfo?.agency && (
            <p className="mt-1 text-sm text-red-600">
              {errors.paymentInfo.agency.message}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Conta
          </label>
          <input
            type="text"
            {...register('paymentInfo.account')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.paymentInfo?.account && (
            <p className="mt-1 text-sm text-red-600">
              {errors.paymentInfo.account.message}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Tipo de Conta
          </label>
          <select
            {...register('paymentInfo.accountType')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="checking">Corrente</option>
            <option value="savings">Poupança</option>
          </select>
        </div>
      </div>

      <div className="mt-6 flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancelar
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
        >
          {isSubmitting ? 'Salvando...' : 'Salvar'}
        </button>
      </div>
    </form>
  );
}