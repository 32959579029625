import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Plus, Search, UserCheck, DollarSign, Users, Star } from 'lucide-react';
import { Tab } from '@headlessui/react';
import { SalesmanForm } from '../components/sales/SalesmanForm';
import { CommissionReport } from '../components/sales/CommissionReport';
import { Modal } from '../components/ui/Modal';
import { toast } from 'react-hot-toast';
import { useSalesmanStore } from '../store/salesmanStore';

export default function Salespeople() {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSalesman, setSelectedSalesman] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const { salesmen, loading, error, addSalesman, updateSalesman } = useSalesmanStore();

  const { data: metrics } = useQuery({
    queryKey: ['salespeople-metrics'],
    queryFn: async () => ({
      totalSalespeople: salesmen.length,
      activeSalespeople: salesmen.filter(s => s.status === 'active').length,
      totalCommissions: 25000,
      averageCommission: 5000,
    }),
  });

  const handleSubmit = async (data: any) => {
    try {
      if (selectedSalesman) {
        await updateSalesman(selectedSalesman.id, data);
        toast.success('Vendedor atualizado com sucesso!');
      } else {
        await addSalesman(data);
        toast.success('Vendedor cadastrado com sucesso!');
      }
      setIsModalOpen(false);
      setSelectedSalesman(null);
    } catch (error) {
      toast.error('Erro ao salvar vendedor');
    }
  };

  const tabs = [
    { name: 'Vendedores', icon: Users },
    { name: 'Comissões', icon: DollarSign },
  ];

  if (loading) return <div>Carregando...</div>;
  if (error) return <div>Erro ao carregar dados</div>;

  const filteredSalesmen = salesmen.filter(salesman => {
    if (filter !== 'all' && salesman.status !== filter) return false;
    if (search && !salesman.name.toLowerCase().includes(search.toLowerCase())) return false;
    return true;
  });

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">
            Vendedores e Comissões
          </h1>
          <p className="mt-1 text-sm text-gray-500">
            Gerencie sua equipe de vendas e comissionamento
          </p>
        </div>
        <button
          onClick={() => {
            setSelectedSalesman(null);
            setIsModalOpen(true);
          }}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          Novo Vendedor
        </button>
      </div>

      {/* Metrics */}
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-4">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <UserCheck className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Total de Vendedores
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {metrics?.totalSalespeople}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Users className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Vendedores Ativos
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {metrics?.activeSalespeople}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <DollarSign className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Total em Comissões
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    R$ {metrics?.totalCommissions.toLocaleString('pt-BR')}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Star className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Média por Vendedor
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    R$ {metrics?.averageCommission.toLocaleString('pt-BR')}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg">
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.List className="flex space-x-4 p-4 border-b">
            {tabs.map((tab) => (
              <Tab
                key={tab.name}
                className={({ selected }) =>
                  `flex items-center px-4 py-2 text-sm font-medium rounded-md transition-colors ${
                    selected
                      ? 'bg-indigo-50 text-indigo-700'
                      : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                  }`
                }
              >
                <tab.icon className="h-5 w-5 mr-2" />
                {tab.name}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel>
              <div className="p-6">
                {/* Filters */}
                <div className="flex flex-col sm:flex-row gap-4 mb-6">
                  <div className="flex-1 max-w-xs">
                    <div className="relative">
                      <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                      <input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Buscar vendedores..."
                        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <select
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="all">Todos</option>
                    <option value="active">Ativos</option>
                    <option value="inactive">Inativos</option>
                  </select>
                </div>

                {/* Salespeople List */}
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  {filteredSalesmen.map((salesman) => (
                    <div
                      key={salesman.id}
                      className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                      onClick={() => {
                        setSelectedSalesman(salesman);
                        setIsModalOpen(true);
                      }}
                    >
                      <div className="p-6">
                        <div className="flex items-center justify-between">
                          <h3 className="text-lg font-medium text-gray-900">
                            {salesman.name}
                          </h3>
                          <span
                            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              salesman.status === 'active'
                                ? 'bg-green-100 text-green-800'
                                : 'bg-red-100 text-red-800'
                            }`}
                          >
                            {salesman.status === 'active' ? 'Ativo' : 'Inativo'}
                          </span>
                        </div>

                        <div className="mt-4 space-y-2">
                          <div className="flex items-center text-sm">
                            <DollarSign className="h-4 w-4 text-gray-400 mr-2" />
                            <span className="text-gray-600">
                              Taxa Base: {salesman.commissionRules.baseRate}%
                            </span>
                          </div>
                          <div className="flex items-center text-sm">
                            <Users className="h-4 w-4 text-gray-400 mr-2" />
                            <span className="text-gray-600">{salesman.email}</span>
                          </div>
                        </div>

                        {salesman.commissionRules.specialRates.length > 0 && (
                          <div className="mt-4">
                            <p className="text-sm text-gray-500 mb-2">
                              Taxas Especiais:
                            </p>
                            <div className="flex flex-wrap gap-2">
                              {salesman.commissionRules.specialRates.map((rate, index) => (
                                <span
                                  key={index}
                                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
                                >
                                  {rate.category}: {rate.rate}%
                                </span>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="p-6">
                <CommissionReport />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      {/* Salesman Form Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedSalesman(null);
        }}
        title={selectedSalesman ? 'Editar Vendedor' : 'Novo Vendedor'}
      >
        <SalesmanForm
          salesman={selectedSalesman}
          onSubmit={handleSubmit}
          onCancel={() => {
            setIsModalOpen(false);
            setSelectedSalesman(null);
          }}
        />
      </Modal>
    </div>
  );
}