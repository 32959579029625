import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface ProposalPreviewProps {
  proposal: any;
}

export function ProposalPreview({ proposal }: ProposalPreviewProps) {
  return (
    <div className="prose max-w-none">
      <div className="text-center mb-8">
        <h1 className="text-2xl font-bold text-gray-900">{proposal.title}</h1>
        <p className="text-gray-500">
          Preparado para: {proposal.clientName}
          {proposal.projectName && ` - ${proposal.projectName}`}
        </p>
        <p className="text-sm text-gray-500">
          Válido até: {format(new Date(proposal.validUntil), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}
        </p>
      </div>

      <div className="space-y-6">
        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-3">Introdução</h2>
          <div className="text-gray-700 whitespace-pre-wrap">
            {proposal.content.introduction}
          </div>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-3">Escopo do Projeto</h2>
          <div className="text-gray-700 whitespace-pre-wrap">
            {proposal.content.scope}
          </div>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-3">Entregas</h2>
          <ul className="list-disc pl-5 space-y-2">
            {proposal.content.deliverables.map((item: string, index: number) => (
              <li key={index} className="text-gray-700">
                {item}
              </li>
            ))}
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-3">Cronograma</h2>
          <p className="text-gray-700">{proposal.content.timeline}</p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-3">Investimento</h2>
          <div className="bg-gray-50 p-4 rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="text-left text-sm font-medium text-gray-500">Item</th>
                  <th className="text-right text-sm font-medium text-gray-500">Valor</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {proposal.content.investment.breakdown.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="py-2 text-sm text-gray-700">{item.description}</td>
                    <td className="py-2 text-sm text-gray-700 text-right">
                      R$ {item.value.toLocaleString('pt-BR')}
                    </td>
                  </tr>
                ))}
                <tr className="font-medium">
                  <td className="py-2 text-sm text-gray-900">Total</td>
                  <td className="py-2 text-sm text-gray-900 text-right">
                    R$ {proposal.content.investment.total.toLocaleString('pt-BR')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-3">Termos e Condições</h2>
          <div className="text-gray-700 whitespace-pre-wrap">
            {proposal.content.terms}
          </div>
        </section>

        <div className="mt-8 pt-8 border-t border-gray-200">
          <div className="text-center text-sm text-gray-500">
            <p>Esta proposta é válida até {format(new Date(proposal.validUntil), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}</p>
            <p className="mt-2">
              Em caso de dúvidas ou para aceitar esta proposta, entre em contato conosco.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}