import { EventSource } from '../../types';

interface EventSourceSelectorProps {
  sources: EventSource[];
  onChange: (sources: EventSource[]) => void;
}

export function EventSourceSelector({ sources, onChange }: EventSourceSelectorProps) {
  const handleToggle = (sourceId: string) => {
    const updatedSources = sources.map((source) =>
      source.id === sourceId ? { ...source, enabled: !source.enabled } : source
    );
    onChange(updatedSources);
  };

  return (
    <div className="space-y-2">
      <h3 className="text-sm font-medium text-gray-700">Fontes de Eventos</h3>
      <div className="space-y-2">
        {sources.map((source) => (
          <label key={source.id} className="flex items-center">
            <input
              type="checkbox"
              checked={source.enabled}
              onChange={() => handleToggle(source.id)}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <span className="ml-2 text-sm text-gray-900">{source.name}</span>
            <span
              className="ml-2 w-3 h-3 rounded-full"
              style={{ backgroundColor: source.color }}
            />
          </label>
        ))}
      </div>
    </div>
  );
}