import { Package, Check, Settings } from 'lucide-react';
import { Product } from '@/types/product';

interface ProductCardProps {
  product: Product;
  onClick: () => void;
}

export function ProductCard({ product, onClick }: ProductCardProps) {
  return (
    <div
      className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
      onClick={onClick}
    >
      <div className="p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Package className="h-8 w-8 text-indigo-600" />
            <div className="ml-3">
              <h3 className="text-lg font-medium text-gray-900">{product.name}</h3>
              <p className="text-sm text-gray-500">{product.category}</p>
            </div>
          </div>
          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
              product.status === 'active'
                ? 'bg-green-100 text-green-800'
                : 'bg-gray-100 text-gray-800'
            }`}
          >
            {product.status === 'active' ? 'Ativo' : 'Inativo'}
          </span>
        </div>

        <p className="mt-4 text-sm text-gray-600 line-clamp-2">
          {product.description}
        </p>

        <div className="mt-4">
          <div className="flex justify-between items-center text-sm">
            <span className="text-gray-500">Preço Base:</span>
            <span className="font-medium">
              R$ {product.basePrice.toLocaleString('pt-BR')}
            </span>
          </div>
        </div>

        <div className="mt-4">
          <div className="flex flex-wrap gap-2">
            {product.features.slice(0, 3).map((feature, index) => (
              <span
                key={index}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
              >
                <Check className="h-3 w-3 mr-1" />
                {feature}
              </span>
            ))}
            {product.features.length > 3 && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                +{product.features.length - 3}
              </span>
            )}
          </div>
        </div>

        {product.customizable && (
          <div className="mt-4 pt-4 border-t border-gray-200">
            <div className="flex items-center text-sm text-gray-600">
              <Settings className="h-4 w-4 mr-1" />
              <span>Customizável</span>
              <span className="ml-1 text-xs text-gray-500">
                ({product.customizationOptions?.length || 0} opções)
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}