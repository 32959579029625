import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Plus, Trash2 } from 'lucide-react';
import { Product } from '@/types/product';

const productSchema = z.object({
  name: z.string().min(1, 'Nome é obrigatório'),
  description: z.string().min(1, 'Descrição é obrigatória'),
  category: z.string().min(1, 'Categoria é obrigatória'),
  type: z.enum(['service', 'product']),
  basePrice: z.number().min(0, 'Preço base deve ser maior ou igual a zero'),
  status: z.enum(['active', 'inactive']),
  features: z.array(z.string()),
  customizable: z.boolean(),
  customizationOptions: z.array(
    z.object({
      name: z.string(),
      type: z.enum(['select', 'number', 'text']),
      options: z.array(z.string()).optional(),
      min: z.number().optional(),
      max: z.number().optional(),
      affects: z.enum(['price', 'scope']),
      impact: z.number().optional(),
    })
  ).optional(),
});

type ProductFormData = z.infer<typeof productSchema>;

interface ProductFormProps {
  product?: Product | null;
  onSubmit: (data: ProductFormData) => Promise<void>;
  onCancel: () => void;
}

export function ProductForm({ product, onSubmit, onCancel }: ProductFormProps) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ProductFormData>({
    resolver: zodResolver(productSchema),
    defaultValues: product || {
      type: 'service',
      status: 'active',
      features: [],
      customizable: false,
      customizationOptions: [],
    },
  });

  const isCustomizable = watch('customizable');
  const customizationType = watch('type');

  const addFeature = () => {
    const features = watch('features');
    setValue('features', [...features, '']);
  };

  const removeFeature = (index: number) => {
    const features = watch('features');
    setValue('features', features.filter((_, i) => i !== index));
  };

  const addCustomizationOption = () => {
    const options = watch('customizationOptions') || [];
    setValue('customizationOptions', [
      ...options,
      {
        name: '',
        type: 'select',
        options: [],
        affects: 'price',
        impact: 0,
      },
    ]);
  };

  const removeCustomizationOption = (index: number) => {
    const options = watch('customizationOptions') || [];
    setValue(
      'customizationOptions',
      options.filter((_, i) => i !== index)
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">
            Nome
          </label>
          <input
            type="text"
            {...register('name')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.name && (
            <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
          )}
        </div>

        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">
            Descrição
          </label>
          <textarea
            {...register('description')}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.description && (
            <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Categoria
          </label>
          <input
            type="text"
            {...register('category')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.category && (
            <p className="mt-1 text-sm text-red-600">{errors.category.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Tipo
          </label>
          <select
            {...register('type')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="service">Serviço</option>
            <option value="product">Produto</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Preço Base
          </label>
          <input
            type="number"
            step="0.01"
            {...register('basePrice', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.basePrice && (
            <p className="mt-1 text-sm text-red-600">{errors.basePrice.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Status
          </label>
          <select
            {...register('status')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="active">Ativo</option>
            <option value="inactive">Inativo</option>
          </select>
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <h4 className="text-sm font-medium text-gray-900">Características</h4>
          <button
            type="button"
            onClick={addFeature}
            className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
          >
            <Plus className="h-4 w-4 mr-1" />
            Adicionar
          </button>
        </div>

        {watch('features')?.map((_, index) => (
          <div key={index} className="flex gap-2">
            <input
              type="text"
              {...register(`features.${index}`)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
            <button
              type="button"
              onClick={() => removeFeature(index)}
              className="p-2 text-red-600 hover:text-red-800"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          </div>
        ))}
      </div>

      <div className="space-y-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            {...register('customizable')}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label className="ml-2 block text-sm text-gray-900">
            Produto customizável
          </label>
        </div>

        {isCustomizable && (
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h4 className="text-sm font-medium text-gray-900">
                Opções de Customização
              </h4>
              <button
                type="button"
                onClick={addCustomizationOption}
                className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
              >
                <Plus className="h-4 w-4 mr-1" />
                Adicionar
              </button>
            </div>

            {watch('customizationOptions')?.map((_, index) => (
              <div key={index} className="space-y-4 p-4 bg-gray-50 rounded-lg">
                <div className="flex justify-between">
                  <div className="flex-1 mr-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Nome da Opção
                    </label>
                    <input
                      type="text"
                      {...register(`customizationOptions.${index}.name`)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => removeCustomizationOption(index)}
                    className="p-2 text-red-600 hover:text-red-800"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Tipo
                    </label>
                    <select
                      {...register(`customizationOptions.${index}.type`)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="select">Seleção</option>
                      <option value="number">Número</option>
                      <option value="text">Texto</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Afeta
                    </label>
                    <select
                      {...register(`customizationOptions.${index}.affects`)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="price">Preço</option>
                      <option value="scope">Escopo</option>
                    </select>
                  </div>
                </div>

                {watch(`customizationOptions.${index}.type`) === 'select' && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Opções (separadas por vírgula)
                    </label>
                    <input
                      type="text"
                      {...register(`customizationOptions.${index}.options`)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                )}

                {watch(`customizationOptions.${index}.type`) === 'number' && (
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Valor Mínimo
                      </label>
                      <input
                        type="number"
                        {...register(`customizationOptions.${index}.min`, {
                          valueAsNumber: true,
                        })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Valor Máximo
                      </label>
                      <input
                        type="number"
                        {...register(`customizationOptions.${index}.max`, {
                          valueAsNumber: true,
                        })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                )}

                {watch(`customizationOptions.${index}.affects`) === 'price' && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Impacto no Preço (R$ ou %)
                    </label>
                    <input
                      type="number"
                      {...register(`customizationOptions.${index}.impact`, {
                        valueAsNumber: true,
                      })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="mt-6 flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancelar
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
        >
          {isSubmitting ? 'Salvando...' : 'Salvar'}
        </button>
      </div>
    </form>
  );
}