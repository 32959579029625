import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { 
  Plus, 
  Search, 
  Calendar, 
  Download,
  Filter,
  TrendingUp,
  Users,
  DollarSign,
  ShoppingCart,
  ArrowUpRight,
  BarChart2,
  ListFilter
} from 'lucide-react';
import { Modal } from '../components/ui/Modal';
import { SaleForm } from '../components/sales/SaleForm';
import { SalesDashboard } from '../components/sales/SalesDashboard';
import { SalesReport } from '../components/sales/SalesReport';
import { SalesPipeline } from '../components/sales/SalesPipeline';
import { SalesFunnel } from '../components/sales/SalesFunnel';
import { Tab } from '@headlessui/react';
import { toast } from 'react-hot-toast';

export default function Sales() {
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [filter, setFilter] = useState('all');

  const { data, isLoading } = useQuery({
    queryKey: ['sales', { search, dateRange, filter }],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return {
        sales: [
          {
            id: '1',
            customer: 'João Silva',
            product: 'AI Consulting',
            amount: 5000,
            status: 'completed',
            date: '2024-03-01',
          },
          {
            id: '2',
            customer: 'Maria Santos',
            product: 'ML Implementation',
            amount: 7500,
            status: 'pending',
            date: '2024-03-02',
          },
        ],
        totalPages: 1,
        metrics: {
          totalSales: 450000,
          averageTicket: 75000,
          conversionRate: 65,
          activeDeals: 8,
          salesGrowth: 12,
          topProducts: [
            { name: 'AI Chatbot', value: 150000 },
            { name: 'ML Pipeline', value: 120000 },
            { name: 'Data Analytics', value: 100000 },
          ],
          salesByStatus: {
            completed: 65,
            pending: 25,
            cancelled: 10,
          },
        },
      };
    },
  });

  const exportSales = () => {
    // TODO: Implement export functionality
    toast.success('Exportação iniciada');
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-full">Carregando...</div>;
  }

  const tabs = [
    { name: 'Dashboard', icon: BarChart2 },
    { name: 'Funil de Vendas', icon: TrendingUp },
    { name: 'Pipeline', icon: TrendingUp },
    { name: 'Vendas', icon: ShoppingCart },
    { name: 'Relatórios', icon: ListFilter },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Vendas</h1>
          <p className="mt-1 text-sm text-gray-500">
            Gestão completa de vendas e oportunidades
          </p>
        </div>
        <div className="flex items-center space-x-3">
          <button
            onClick={exportSales}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <Download className="h-4 w-4 mr-2" />
            Exportar
          </button>
          <button
            onClick={() => setIsModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Nova Venda
          </button>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg">
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.List className="flex space-x-4 p-4 border-b">
            {tabs.map((tab) => (
              <Tab
                key={tab.name}
                className={({ selected }) =>
                  `flex items-center px-4 py-2 text-sm font-medium rounded-md transition-colors ${
                    selected
                      ? 'bg-indigo-50 text-indigo-700'
                      : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                  }`
                }
              >
                <tab.icon className="h-5 w-5 mr-2" />
                {tab.name}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel>
              <div className="p-6">
                <SalesDashboard metrics={data.metrics} />
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="p-6">
                <SalesFunnel />
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="p-6">
                <SalesPipeline />
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="p-6 space-y-6">
                {/* Sales List Content */}
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="p-6">
                <SalesReport />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedSale(null);
        }}
        title={selectedSale ? 'Editar Venda' : 'Nova Venda'}
      >
        <SaleForm
          sale={selectedSale}
          onSubmit={async (data) => {
            // TODO: Implement form submission
            console.log('Form submitted:', data);
            toast.success(
              selectedSale ? 'Venda atualizada com sucesso!' : 'Venda criada com sucesso!'
            );
            setIsModalOpen(false);
          }}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
}