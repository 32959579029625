import { create } from 'zustand';
import { Salesman } from '../types/commission';

interface SalesmanState {
  salesmen: Salesman[];
  loading: boolean;
  error: string | null;
  fetchSalesmen: () => Promise<void>;
  addSalesman: (salesman: Partial<Salesman>) => Promise<void>;
  updateSalesman: (id: string, data: Partial<Salesman>) => Promise<void>;
  deleteSalesman: (id: string) => Promise<void>;
}

export const useSalesmanStore = create<SalesmanState>((set) => ({
  salesmen: [],
  loading: false,
  error: null,

  fetchSalesmen: async () => {
    set({ loading: true });
    try {
      // Mock data for development
      const mockSalesmen: Salesman[] = [
        {
          id: '1',
          name: 'João Silva',
          email: 'joao@example.com',
          phone: '(11) 99999-9999',
          document: '123.456.789-00',
          status: 'active',
          commissionRules: {
            implementation: {
              id: '1',
              salesmanId: '1',
              type: 'implementation',
              percentage: 5,
              conditions: {
                minValue: 10000,
                maxValue: 1000000,
                categories: ['AI', 'ML'],
              },
            },
            firstPayment: {
              id: '2',
              salesmanId: '1',
              type: 'first_payment',
              percentage: 3,
            },
            recurring: {
              id: '3',
              salesmanId: '1',
              type: 'recurring',
              percentage: 1,
            },
          },
          paymentInfo: {
            bank: 'Banco do Brasil',
            agency: '1234-5',
            account: '12345-6',
            accountType: 'checking',
          },
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
      ];

      set({ salesmen: mockSalesmen, error: null });
    } catch (error) {
      set({ error: 'Failed to fetch salesmen' });
    } finally {
      set({ loading: false });
    }
  },

  addSalesman: async (salesman) => {
    set({ loading: true });
    try {
      const newSalesman: Salesman = {
        ...salesman,
        id: Date.now().toString(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      } as Salesman;

      set((state) => ({
        salesmen: [...state.salesmen, newSalesman],
        error: null,
      }));
    } catch (error) {
      set({ error: 'Failed to add salesman' });
    } finally {
      set({ loading: false });
    }
  },

  updateSalesman: async (id, data) => {
    set({ loading: true });
    try {
      set((state) => ({
        salesmen: state.salesmen.map((s) =>
          s.id === id
            ? {
                ...s,
                ...data,
                updatedAt: new Date().toISOString(),
              }
            : s
        ),
        error: null,
      }));
    } catch (error) {
      set({ error: 'Failed to update salesman' });
    } finally {
      set({ loading: false });
    }
  },

  deleteSalesman: async (id) => {
    set({ loading: true });
    try {
      set((state) => ({
        salesmen: state.salesmen.filter((s) => s.id !== id),
        error: null,
      }));
    } catch (error) {
      set({ error: 'Failed to delete salesman' });
    } finally {
      set({ loading: false });
    }
  },
}));