import { useState } from 'react';
import { Modal } from '../ui/Modal';
import { toast } from 'react-hot-toast';

interface CalendarSyncModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function CalendarSyncModal({ isOpen, onClose }: CalendarSyncModalProps) {
  const [selectedProvider, setSelectedProvider] = useState('google');
  const [isConnecting, setIsConnecting] = useState(false);

  const handleSync = async () => {
    try {
      setIsConnecting(true);
      // TODO: Implement calendar sync
      await new Promise((resolve) => setTimeout(resolve, 1500));
      toast.success('Calendário sincronizado com sucesso!');
      onClose();
    } catch (error) {
      toast.error('Erro ao sincronizar calendário');
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Sincronizar Calendário">
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Provedor
          </label>
          <select
            value={selectedProvider}
            onChange={(e) => setSelectedProvider(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="google">Google Calendar</option>
            <option value="outlook">Microsoft Outlook</option>
          </select>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg">
          <h4 className="text-sm font-medium text-gray-900 mb-2">
            O que será sincronizado?
          </h4>
          <ul className="space-y-2 text-sm text-gray-600">
            <li>• Eventos e reuniões</li>
            <li>• Disponibilidade da equipe</li>
            <li>• Notificações</li>
            <li>• Atualizações em tempo real</li>
          </ul>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="bidirectional"
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label htmlFor="bidirectional" className="ml-2 block text-sm text-gray-900">
            Sincronização bidirecional
          </label>
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancelar
          </button>
          <button
            type="button"
            onClick={handleSync}
            disabled={isConnecting}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {isConnecting ? 'Conectando...' : 'Conectar'}
          </button>
        </div>
      </div>
    </Modal>
  );
}