import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { Plus, Trash2, Package } from 'lucide-react';
import { useProductStore } from '@/store/productStore';
import { Product } from '@/types/product';

const proposalSchema = z.object({
  title: z.string().min(1, 'Título é obrigatório'),
  clientId: z.string().min(1, 'Cliente é obrigatório'),
  projectId: z.string().optional(),
  salesmanId: z.string().optional(),
  content: z.object({
    introduction: z.string().min(1, 'Introdução é obrigatória'),
    scope: z.string().min(1, 'Escopo é obrigatório'),
    deliverables: z.array(z.object({
      description: z.string(),
      productId: z.string().optional(),
      customizations: z.record(z.any()).optional(),
      value: z.number(),
    })),
    timeline: z.string().min(1, 'Cronograma é obrigatório'),
    investment: z.object({
      total: z.number().min(0),
      breakdown: z.array(z.object({
        description: z.string(),
        value: z.number()
      }))
    }),
    terms: z.string().min(1, 'Termos são obrigatórios')
  }),
  validUntil: z.string()
});

type ProposalFormData = z.infer<typeof proposalSchema>;

interface ProposalFormProps {
  proposal?: any;
  onSubmit: (data: ProposalFormData) => Promise<void>;
  onCancel: () => void;
}

export function ProposalForm({ proposal, onSubmit, onCancel }: ProposalFormProps) {
  const { products } = useProductStore();

  const { data: clients } = useQuery({
    queryKey: ['clients'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        { id: '1', name: 'Tech Corp', email: 'contact@techcorp.com' },
        { id: '2', name: 'Data Inc', email: 'info@datainc.com' },
      ];
    },
  });

  const { data: salesmen } = useQuery({
    queryKey: ['salesmen'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        { id: '1', name: 'João Silva' },
        { id: '2', name: 'Maria Santos' },
      ];
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<ProposalFormData>({
    resolver: zodResolver(proposalSchema),
    defaultValues: proposal || {
      content: {
        deliverables: [],
        investment: {
          total: 0,
          breakdown: []
        }
      },
      validUntil: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
    }
  });

  const addDeliverable = () => {
    const deliverables = watch('content.deliverables') || [];
    setValue('content.deliverables', [...deliverables, {
      description: '',
      value: 0,
      customizations: {}
    }]);
  };

  const removeDeliverable = (index: number) => {
    const deliverables = watch('content.deliverables') || [];
    setValue('content.deliverables', deliverables.filter((_, i) => i !== index));
    updateTotal();
  };

  const handleProductSelect = (index: number, productId: string) => {
    const product = products.find(p => p.id === productId);
    if (!product) return;

    const deliverables = watch('content.deliverables');
    deliverables[index] = {
      ...deliverables[index],
      description: product.name,
      productId,
      value: product.basePrice,
      customizations: {}
    };

    setValue('content.deliverables', deliverables);
    updateTotal();
  };

  const updateTotal = () => {
    const deliverables = watch('content.deliverables') || [];
    const total = deliverables.reduce((sum, item) => sum + item.value, 0);
    setValue('content.investment.total', total);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">
            Título
          </label>
          <input
            type="text"
            {...register('title')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.title && (
            <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Cliente
          </label>
          <select
            {...register('clientId')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Selecione um cliente</option>
            {clients?.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </select>
          {errors.clientId && (
            <p className="mt-1 text-sm text-red-600">{errors.clientId.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Vendedor
          </label>
          <select
            {...register('salesmanId')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Selecione um vendedor</option>
            {salesmen?.map((salesman) => (
              <option key={salesman.id} value={salesman.id}>
                {salesman.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">
            Introdução
          </label>
          <textarea
            {...register('content.introduction')}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.content?.introduction && (
            <p className="mt-1 text-sm text-red-600">
              {errors.content.introduction.message}
            </p>
          )}
        </div>

        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">
            Escopo
          </label>
          <textarea
            {...register('content.scope')}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.content?.scope && (
            <p className="mt-1 text-sm text-red-600">
              {errors.content.scope.message}
            </p>
          )}
        </div>

        <div className="col-span-2 space-y-4">
          <div className="flex items-center justify-between">
            <h4 className="text-sm font-medium text-gray-900">Itens e Entregas</h4>
            <button
              type="button"
              onClick={addDeliverable}
              className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
            >
              <Plus className="h-4 w-4 mr-1" />
              Adicionar
            </button>
          </div>

          {watch('content.deliverables')?.map((deliverable, index) => (
            <div key={index} className="space-y-4 p-4 bg-gray-50 rounded-lg">
              <div className="flex justify-between gap-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Produto/Serviço
                  </label>
                  <div className="mt-1 flex gap-2">
                    <select
                      value={deliverable.productId || ''}
                      onChange={(e) => handleProductSelect(index, e.target.value)}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="">Selecione um produto</option>
                      {products.map((product) => (
                        <option key={product.id} value={product.id}>
                          {product.name} - R$ {product.basePrice.toLocaleString('pt-BR')}
                        </option>
                      ))}
                    </select>
                    <button
                      type="button"
                      onClick={() => removeDeliverable(index)}
                      className="p-2 text-red-600 hover:text-red-800"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>

              {deliverable.productId && (
                <div className="space-y-4">
                  {products
                    .find(p => p.id === deliverable.productId)
                    ?.customizationOptions?.map((option, optionIndex) => (
                      <div key={optionIndex}>
                        <label className="block text-sm font-medium text-gray-700">
                          {option.name}
                        </label>
                        {option.type === 'select' ? (
                          <select
                            {...register(`content.deliverables.${index}.customizations.${option.name}`)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          >
                            {option.options?.map((opt) => (
                              <option key={opt} value={opt}>
                                {opt}
                              </option>
                            ))}
                          </select>
                        ) : option.type === 'number' ? (
                          <input
                            type="number"
                            min={option.min}
                            max={option.max}
                            {...register(`content.deliverables.${index}.customizations.${option.name}`, {
                              valueAsNumber: true,
                            })}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        ) : (
                          <input
                            type="text"
                            {...register(`content.deliverables.${index}.customizations.${option.name}`)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        )}
                      </div>
                    ))}
                </div>
              )}

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Descrição Personalizada
                </label>
                <textarea
                  {...register(`content.deliverables.${index}.description`)}
                  rows={2}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Valor
                </label>
                <input
                  type="number"
                  step="0.01"
                  {...register(`content.deliverables.${index}.value`, {
                    valueAsNumber: true,
                    onChange: () => updateTotal(),
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">
            Cronograma
          </label>
          <textarea
            {...register('content.timeline')}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.content?.timeline && (
            <p className="mt-1 text-sm text-red-600">
              {errors.content.timeline.message}
            </p>
          )}
        </div>

        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">
            Termos e Condições
          </label>
          <textarea
            {...register('content.terms')}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.content?.terms && (
            <p className="mt-1 text-sm text-red-600">
              {errors.content.terms.message}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Validade
          </label>
          <input
            type="date"
            {...register('validUntil')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div className="col-span-2">
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium text-gray-700">
                Valor Total:
              </span>
              <span className="text-lg font-semibold text-gray-900">
                R$ {watch('content.investment.total').toLocaleString('pt-BR')}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancelar
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
        >
          {isSubmitting ? 'Salvando...' : 'Salvar'}
        </button>
      </div>
    </form>
  );
}