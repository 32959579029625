import { Modal } from '../ui/Modal';
import { History, User, Calendar } from 'lucide-react';
import { Budget } from '../../types';

interface BudgetHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  budget: Budget;
}

export function BudgetHistoryModal({ isOpen, onClose, budget }: BudgetHistoryModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Histórico do Orçamento"
    >
      <div className="space-y-4">
        <div className="border-b pb-4">
          <h3 className="text-lg font-medium text-gray-900">
            {budget.projectName}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Cliente: {budget.clientName}
          </p>
        </div>

        <div className="space-y-6">
          {budget.revisions.map((revision) => (
            <div
              key={revision.id}
              className="relative pl-8 pb-6 border-l-2 border-gray-200 last:pb-0"
            >
              <div className="absolute -left-2 top-0 bg-white">
                <History className="h-4 w-4 text-gray-400" />
              </div>
              
              <div className="flex items-center justify-between">
                <div className="flex items-center text-sm text-gray-500">
                  <User className="h-4 w-4 mr-1" />
                  <span>{revision.createdBy}</span>
                </div>
                <div className="flex items-center text-sm text-gray-500">
                  <Calendar className="h-4 w-4 mr-1" />
                  <span>{new Date(revision.createdAt).toLocaleString()}</span>
                </div>
              </div>

              <div className="mt-2">
                <span className="text-sm font-medium text-gray-900">
                  Versão {revision.version}
                </span>
                <ul className="mt-2 space-y-1">
                  {revision.changes.map((change, index) => (
                    <li key={index} className="text-sm text-gray-600">
                      • {change}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}