import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Modal } from '../ui/Modal';
import { useQuery } from '@tanstack/react-query';
import { Client, AIProject } from '../../types';
import { toast } from 'react-hot-toast';

const projectSchema = z.object({
  name: z.string().min(1, 'Nome é obrigatório'),
  description: z.string().min(1, 'Descrição é obrigatória'),
  clientId: z.string().min(1, 'Cliente é obrigatório'),
  status: z.enum(['planning', 'in_progress', 'review', 'completed', 'on_hold']),
  priority: z.enum(['low', 'medium', 'high']),
  progress: z.number().min(0).max(100),
  startDate: z.string(),
  endDate: z.string(),
  budget: z.number().min(0),
  technologies: z.string(),
});

type ProjectFormData = z.infer<typeof projectSchema>;

interface ProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  project?: AIProject;
  onSubmit: (data: ProjectFormData) => Promise<void>;
}

export function ProjectModal({ isOpen, onClose, project, onSubmit }: ProjectModalProps) {
  const { data: clients } = useQuery<Client[]>({
    queryKey: ['clients'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        {
          id: '1',
          name: 'João Silva',
          email: 'joao@email.com',
          phone: '(11) 99999-9999',
          company: 'Tech Corp',
          status: 'active',
          createdAt: '2024-03-01',
        },
      ];
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ProjectFormData>({
    resolver: zodResolver(projectSchema),
    defaultValues: project || {
      status: 'planning',
      priority: 'medium',
      progress: 0,
      startDate: new Date().toISOString().split('T')[0],
      technologies: project?.technologies?.join(', ') || '',
    },
  });

  const handleFormSubmit = async (data: ProjectFormData) => {
    try {
      // Convert comma-separated technologies string to array
      const formattedData = {
        ...data,
        technologies: data.technologies.split(',').map(tech => tech.trim()).filter(tech => tech),
      };
      await onSubmit(formattedData as any);
      toast.success(project ? 'Projeto atualizado com sucesso!' : 'Projeto criado com sucesso!');
      onClose();
    } catch (error) {
      console.error('Error submitting project:', error);
      toast.error('Erro ao salvar projeto');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={project ? 'Editar Projeto' : 'Novo Projeto'}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Cliente
          </label>
          <select
            {...register('clientId')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Selecione um cliente</option>
            {clients?.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name} - {client.company}
              </option>
            ))}
          </select>
          {errors.clientId && (
            <p className="mt-1 text-sm text-red-600">{errors.clientId.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Nome do Projeto
          </label>
          <input
            type="text"
            {...register('name')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.name && (
            <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Descrição
          </label>
          <textarea
            {...register('description')}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.description && (
            <p className="mt-1 text-sm text-red-600">
              {errors.description.message}
            </p>
          )}
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Status
            </label>
            <select
              {...register('status')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value="planning">Planejamento</option>
              <option value="in_progress">Em Progresso</option>
              <option value="review">Revisão</option>
              <option value="completed">Concluído</option>
              <option value="on_hold">Em Espera</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Prioridade
            </label>
            <select
              {...register('priority')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value="low">Baixa</option>
              <option value="medium">Média</option>
              <option value="high">Alta</option>
            </select>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Progresso (%)
          </label>
          <input
            type="number"
            min="0"
            max="100"
            {...register('progress', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.progress && (
            <p className="mt-1 text-sm text-red-600">{errors.progress.message}</p>
          )}
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Data de Início
            </label>
            <input
              type="date"
              {...register('startDate')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Data de Término
            </label>
            <input
              type="date"
              {...register('endDate')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Orçamento Previsto (R$)
          </label>
          <input
            type="number"
            step="0.01"
            {...register('budget', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.budget && (
            <p className="mt-1 text-sm text-red-600">{errors.budget.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Tecnologias (separadas por vírgula)
          </label>
          <input
            type="text"
            {...register('technologies')}
            placeholder="Ex: Python, TensorFlow, NLP"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.technologies && (
            <p className="mt-1 text-sm text-red-600">
              {errors.technologies.message}
            </p>
          )}
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancelar
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {isSubmitting ? 'Salvando...' : 'Salvar'}
          </button>
        </div>
      </form>
    </Modal>
  );
}