import { useQuery } from '@tanstack/react-query';
import { CalendarEvent, EventSource } from '../types';
import { format } from 'date-fns';

const defaultEventSources: EventSource[] = [
  {
    id: 'project',
    name: 'Projetos',
    type: 'project',
    color: 'rgb(99, 102, 241)', // indigo
    enabled: true,
  },
  {
    id: 'sale',
    name: 'Vendas',
    type: 'sale',
    color: 'rgb(34, 197, 94)', // green
    enabled: true,
  },
  {
    id: 'budget',
    name: 'Orçamentos',
    type: 'budget',
    color: 'rgb(234, 179, 8)', // yellow
    enabled: true,
  },
  {
    id: 'general',
    name: 'Geral',
    type: 'general',
    color: 'rgb(107, 114, 128)', // gray
    enabled: true,
  },
];

export function useCalendarEvents(filters: any) {
  const { data: projects = [] } = useQuery({
    queryKey: ['projects'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        {
          id: '1',
          name: 'AI Chatbot Development',
          description: 'Developing an advanced AI chatbot for customer service',
          status: 'in_progress',
          priority: 'high',
          progress: 65,
          startDate: '2024-03-01',
          endDate: '2024-06-30',
          milestones: [
            {
              id: '1',
              title: 'Kickoff Meeting',
              date: '2024-03-15',
              duration: 2,
            },
            {
              id: '2',
              title: 'Sprint Review',
              date: '2024-03-20',
              duration: 1,
            },
          ],
        },
      ];
    },
  });

  const { data: sales = [] } = useQuery({
    queryKey: ['sales'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        {
          id: '1',
          title: 'AI Solution Presentation',
          clientName: 'Tech Corp',
          date: '2024-03-16',
          duration: 1.5,
          value: 50000,
        },
      ];
    },
  });

  const { data: budgets = [] } = useQuery({
    queryKey: ['budgets'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        {
          id: '1',
          title: 'ML Pipeline Budget Review',
          clientName: 'Data Inc',
          date: '2024-03-17',
          duration: 1,
          value: 75000,
        },
      ];
    },
  });

  const { data: generalEvents = [] } = useQuery({
    queryKey: ['calendar-events'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        {
          id: '1',
          title: 'Team Meeting',
          start: new Date(2024, 2, 18, 10, 0),
          end: new Date(2024, 2, 18, 11, 0),
          type: 'internal_meeting',
          category: 'general',
          attendees: ['João Silva', 'Maria Santos'],
          status: 'confirmed',
        },
      ];
    },
  });

  // Convert project start/end dates to calendar events
  const projectDateEvents: CalendarEvent[] = projects.flatMap((project) => [
    {
      id: `project-start-${project.id}`,
      title: `Início do Projeto: ${project.name}`,
      start: new Date(project.startDate),
      end: new Date(project.startDate),
      type: 'project_date',
      category: 'project',
      relatedId: project.id,
      attendees: [], // TODO: Get project team
      status: 'confirmed',
      metadata: {
        projectName: project.name,
        type: 'start',
      },
    },
    {
      id: `project-end-${project.id}`,
      title: `Entrega do Projeto: ${project.name}`,
      start: new Date(project.endDate),
      end: new Date(project.endDate),
      type: 'project_date',
      category: 'project',
      relatedId: project.id,
      attendees: [], // TODO: Get project team
      status: 'confirmed',
      metadata: {
        projectName: project.name,
        type: 'end',
      },
    },
  ]);

  // Convert project milestones to calendar events
  const projectEvents: CalendarEvent[] = projects.flatMap((project) =>
    (project.milestones || []).map((milestone) => {
      const start = new Date(milestone.date);
      const end = new Date(start.getTime() + milestone.duration * 60 * 60 * 1000);

      return {
        id: `project-${project.id}-milestone-${milestone.id}`,
        title: milestone.title,
        start,
        end,
        type: 'project_milestone',
        category: 'project',
        relatedId: project.id,
        attendees: [], // TODO: Get project team
        status: 'confirmed',
        metadata: {
          projectName: project.name,
          milestone: milestone.title,
        },
      };
    })
  );

  // Convert sales meetings to calendar events
  const salesEvents: CalendarEvent[] = sales.map((sale) => {
    const start = new Date(sale.date);
    const end = new Date(start.getTime() + sale.duration * 60 * 60 * 1000);

    return {
      id: `sale-${sale.id}`,
      title: sale.title,
      start,
      end,
      type: 'sales_meeting',
      category: 'sale',
      relatedId: sale.id,
      attendees: [], // TODO: Get sales team
      status: 'confirmed',
      metadata: {
        clientName: sale.clientName,
        saleValue: sale.value,
      },
    };
  });

  // Convert budget reviews to calendar events
  const budgetEvents: CalendarEvent[] = budgets.map((budget) => {
    const start = new Date(budget.date);
    const end = new Date(start.getTime() + budget.duration * 60 * 60 * 1000);

    return {
      id: `budget-${budget.id}`,
      title: budget.title,
      start,
      end,
      type: 'budget_review',
      category: 'budget',
      relatedId: budget.id,
      attendees: [], // TODO: Get budget team
      status: 'confirmed',
      metadata: {
        clientName: budget.clientName,
        budgetValue: budget.value,
      },
    };
  });

  // Filter events based on user preferences
  const allEvents = [
    ...projectDateEvents,
    ...projectEvents,
    ...salesEvents,
    ...budgetEvents,
    ...(generalEvents || []),
  ].filter((event) => {
    // Filter by search
    if (filters.search && !event.title.toLowerCase().includes(filters.search.toLowerCase())) {
      return false;
    }

    // Filter by type
    if (filters.type !== 'all' && event.type !== filters.type) {
      return false;
    }

    // Filter by status
    if (filters.status !== 'all' && event.status !== filters.status) {
      return false;
    }

    // Filter by team members
    if (filters.team.length > 0) {
      return event.attendees.some((attendee) => filters.team.includes(attendee));
    }

    return true;
  });

  return {
    events: allEvents,
    eventSources: defaultEventSources,
  };
}