import { Filter } from 'lucide-react';

interface CalendarFiltersProps {
  filters: {
    type: string;
    team: string[];
    status: string;
  };
  onFilterChange: (filters: any) => void;
  team: any[];
}

export function CalendarFilters({ filters, onFilterChange, team }: CalendarFiltersProps) {
  return (
    <div className="flex flex-wrap gap-4 items-center">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Tipo
        </label>
        <select
          value={filters.type}
          onChange={(e) => onFilterChange({ ...filters, type: e.target.value })}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="all">Todos</option>
          <option value="client_meeting">Reuniões com Clientes</option>
          <option value="internal_meeting">Reuniões Internas</option>
          <option value="other">Outros</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Equipe
        </label>
        <select
          multiple
          value={filters.team}
          onChange={(e) =>
            onFilterChange({
              ...filters,
              team: Array.from(e.target.selectedOptions, (option) => option.value),
            })
          }
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          {team.map((member) => (
            <option key={member.id} value={member.id}>
              {member.name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Status
        </label>
        <select
          value={filters.status}
          onChange={(e) => onFilterChange({ ...filters, status: e.target.value })}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="all">Todos</option>
          <option value="confirmed">Confirmados</option>
          <option value="tentative">Tentativos</option>
          <option value="cancelled">Cancelados</option>
        </select>
      </div>
    </div>
  );
}