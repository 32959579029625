import { NavLink } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { NavItem } from './types';

interface NavMenuProps {
  items: NavItem[];
  onItemClick?: () => void;
}

export function NavMenu({ items, onItemClick }: NavMenuProps) {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const toggleExpand = (name: string) => {
    setExpandedItems(prev => 
      prev.includes(name) 
        ? prev.filter(item => item !== name)
        : [...prev, name]
    );
  };

  const renderNavItem = (item: NavItem) => {
    const isExpanded = expandedItems.includes(item.name);
    const Icon = item.icon;

    if (item.children) {
      return (
        <div key={item.name}>
          <button
            onClick={() => toggleExpand(item.name)}
            className="w-full group flex items-center px-4 py-3 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 rounded-md transition-colors"
          >
            <Icon className="mr-3 h-5 w-5 flex-shrink-0" />
            <span className="flex-1 text-left">{item.name}</span>
            <ChevronDown
              className={`h-4 w-4 transition-transform ${
                isExpanded ? 'transform rotate-180' : ''
              }`}
            />
          </button>
          {isExpanded && (
            <div className="ml-4 mt-1 space-y-1">
              {item.children.map((child) => (
                <NavLink
                  key={child.name}
                  to={child.href!}
                  onClick={onItemClick}
                  className={({ isActive }) =>
                    `group flex items-center px-4 py-2 text-sm font-medium rounded-md transition-colors ${
                      isActive
                        ? 'bg-indigo-50 text-indigo-600'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                    }`
                  }
                >
                  <child.icon className="mr-3 h-5 w-5 flex-shrink-0" />
                  {child.name}
                </NavLink>
              ))}
            </div>
          )}
        </div>
      );
    }

    return (
      <NavLink
        key={item.name}
        to={item.href!}
        onClick={onItemClick}
        className={({ isActive }) =>
          `group flex items-center px-4 py-3 text-sm font-medium rounded-md transition-colors ${
            isActive
              ? 'bg-indigo-50 text-indigo-600'
              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
          }`
        }
      >
        <Icon className="mr-3 h-5 w-5 flex-shrink-0" />
        {item.name}
      </NavLink>
    );
  };

  return (
    <nav className="flex-1 space-y-1 px-2 py-4">
      {items.map(renderNavItem)}
    </nav>
  );
}