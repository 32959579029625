import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Download, Filter, Calendar, FileText } from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function FinancialReports() {
  const [period, setPeriod] = useState('month');
  const [reportType, setReportType] = useState('summary');

  const { data: reportData, isLoading } = useQuery({
    queryKey: ['financial-reports', { period, reportType }],
    queryFn: async () => ({
      summary: {
        revenue: 250000,
        expenses: 180000,
        profit: 70000,
        profitMargin: 28,
        revenueByProject: [
          { project: 'Projeto A', value: 80000 },
          { project: 'Projeto B', value: 65000 },
          { project: 'Projeto C', value: 45000 },
        ],
        expensesByCategory: [
          { category: 'RH', value: 85000 },
          { category: 'Marketing', value: 35000 },
          { category: 'Infraestrutura', value: 30000 },
          { category: 'Software', value: 20000 },
          { category: 'Outros', value: 10000 },
        ],
      },
      trends: {
        labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
        revenue: [180000, 190000, 210000, 220000, 235000, 250000],
        expenses: [150000, 155000, 165000, 170000, 175000, 180000],
        profit: [30000, 35000, 45000, 50000, 60000, 70000],
      },
      kpis: {
        revenueGrowth: 15,
        profitGrowth: 25,
        averageProjectValue: 63333,
        clientRetentionRate: 92,
      },
    }),
  });

  if (isLoading) return <div>Carregando...</div>;

  const trendData = {
    labels: reportData?.trends.labels,
    datasets: [
      {
        label: 'Receita',
        data: reportData?.trends.revenue,
        borderColor: 'rgb(34, 197, 94)',
        backgroundColor: 'rgba(34, 197, 94, 0.5)',
      },
      {
        label: 'Despesas',
        data: reportData?.trends.expenses,
        borderColor: 'rgb(239, 68, 68)',
        backgroundColor: 'rgba(239, 68, 68, 0.5)',
      },
      {
        label: 'Lucro',
        data: reportData?.trends.profit,
        borderColor: 'rgb(99, 102, 241)',
        backgroundColor: 'rgba(99, 102, 241, 0.5)',
      },
    ],
  };

  const downloadReport = () => {
    // TODO: Implement report download
    console.log('Downloading report...');
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <select
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            className="border rounded-md px-3 py-2"
          >
            <option value="month">Este Mês</option>
            <option value="quarter">Este Trimestre</option>
            <option value="year">Este Ano</option>
            <option value="custom">Personalizado</option>
          </select>

          <select
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            className="border rounded-md px-3 py-2"
          >
            <option value="summary">Resumo Financeiro</option>
            <option value="detailed">Relatório Detalhado</option>
            <option value="trends">Análise de Tendências</option>
            <option value="projects">Análise por Projeto</option>
          </select>
        </div>

        <button
          onClick={downloadReport}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <Download className="h-4 w-4 mr-2" />
          Exportar Relatório
        </button>
      </div>

      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        {/* Financial Summary */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Resumo Financeiro
          </h3>
          <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2">
            <div className="px-4 py-5 bg-gray-50 rounded-lg overflow-hidden">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Receita Total
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                R$ {reportData?.summary.revenue.toLocaleString('pt-BR')}
              </dd>
            </div>
            <div className="px-4 py-5 bg-gray-50 rounded-lg overflow-hidden">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Despesas Totais
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                R$ {reportData?.summary.expenses.toLocaleString('pt-BR')}
              </dd>
            </div>
            <div className="px-4 py-5 bg-gray-50 rounded-lg overflow-hidden">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Lucro
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                R$ {reportData?.summary.profit.toLocaleString('pt-BR')}
              </dd>
            </div>
            <div className="px-4 py-5 bg-gray-50 rounded-lg overflow-hidden">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Margem de Lucro
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {reportData?.summary.profitMargin}%
              </dd>
            </div>
          </dl>
        </div>

        {/* KPIs */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Indicadores de Desempenho
          </h3>
          <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2">
            <div className="px-4 py-5 bg-gray-50 rounded-lg overflow-hidden">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Crescimento da Receita
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-green-600">
                +{reportData?.kpis.revenueGrowth}%
              </dd>
            </div>
            <div className="px-4 py-5 bg-gray-50 rounded-lg overflow-hidden">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Crescimento do Lucro
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-green-600">
                +{reportData?.kpis.profitGrowth}%
              </dd>
            </div>
            <div className="px-4 py-5 bg-gray-50 rounded-lg overflow-hidden">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Valor Médio por Projeto
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                R$ {reportData?.kpis.averageProjectValue.toLocaleString('pt-BR')}
              </dd>
            </div>
            <div className="px-4 py-5 bg-gray-50 rounded-lg overflow-hidden">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Taxa de Retenção
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {reportData?.kpis.clientRetentionRate}%
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {/* Trends Chart */}
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Tendências Financeiras
        </h3>
        <div className="h-80">
          <Line
            data={trendData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'bottom',
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    callback: (value) =>
                      `R$ ${Number(value).toLocaleString('pt-BR')}`,
                  },
                },
              },
            }}
          />
        </div>
      </div>

      {/* Revenue by Project */}
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Receita por Projeto
        </h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Projeto
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Valor
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  % do Total
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {reportData?.summary.revenueByProject.map((item) => (
                <tr key={item.project}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {item.project}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    R$ {item.value.toLocaleString('pt-BR')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {((item.value / reportData.summary.revenue) * 100).toFixed(1)}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Expenses by Category */}
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Despesas por Categoria
        </h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Categoria
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Valor
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  % do Total
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {reportData?.summary.expensesByCategory.map((item) => (
                <tr key={item.category}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {item.category}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    R$ {item.value.toLocaleString('pt-BR')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {((item.value / reportData.summary.expenses) * 100).toFixed(1)}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}