import { useState } from 'react';
import { Modal } from '../ui/Modal';
import { Budget } from '../../types';
import { Save, FileText } from 'lucide-react';
import { toast } from 'react-hot-toast';

interface BudgetTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  budget: Budget | null;
  onSaveTemplate: (templateName: string, budget: Budget) => Promise<void>;
}

export function BudgetTemplateModal({ 
  isOpen, 
  onClose, 
  budget,
  onSaveTemplate 
}: BudgetTemplateModalProps) {
  const [templateName, setTemplateName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!budget) return;

    try {
      setIsSubmitting(true);
      await onSaveTemplate(templateName, budget);
      toast.success('Template salvo com sucesso!');
      onClose();
    } catch (error) {
      toast.error('Erro ao salvar template');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Salvar como Template"
    >
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Nome do Template
          </label>
          <input
            type="text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="Ex: Projeto AI Básico"
            required
          />
        </div>

        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex items-center">
            <FileText className="h-5 w-5 text-gray-400 mr-2" />
            <span className="text-sm text-gray-600">
              Este template incluirá todos os itens e configurações do orçamento atual
            </span>
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancelar
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Save className="h-4 w-4 mr-2" />
            {isSubmitting ? 'Salvando...' : 'Salvar Template'}
          </button>
        </div>
      </form>
    </Modal>
  );
}