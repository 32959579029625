import { useState } from 'react';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Plus, Calendar as CalendarIcon, Link2, Filter } from 'lucide-react';
import { useCalendarEvents } from '../hooks/useCalendarEvents';
import { EventSourceSelector } from '../components/calendar/EventSourceSelector';
import { EventDetails } from '../components/calendar/EventDetails';
import { EventForm } from '../components/calendar/EventForm';
import { CalendarFilters } from '../components/calendar/CalendarFilters';
import { CalendarSyncModal } from '../components/calendar/CalendarSyncModal';
import { Modal } from '../components/ui/Modal';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const locales = {
  'pt-BR': ptBR,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export default function Calendar() {
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    type: 'all',
    team: [],
    status: 'all',
  });
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isSyncModalOpen, setIsSyncModalOpen] = useState(false);
  const [view, setView] = useState('month');

  const { events, eventSources } = useCalendarEvents({
    search,
    filters,
    sources: [],
  });

  // Mock team data - replace with actual data from your API
  const team = [
    {
      id: '1',
      name: 'João Silva',
      role: 'Developer',
      availability: [
        {
          date: new Date().toISOString(),
          slots: [
            { start: '09:00', end: '12:00' },
            { start: '14:00', end: '18:00' },
          ],
        },
      ],
    },
    {
      id: '2',
      name: 'Maria Santos',
      role: 'Designer',
      availability: [
        {
          date: new Date().toISOString(),
          slots: [
            { start: '08:00', end: '12:00' },
            { start: '13:00', end: '17:00' },
          ],
        },
      ],
    },
  ];

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsDetailsModalOpen(true);
  };

  const handleEventSubmit = async (data) => {
    try {
      // TODO: Implement event creation/update
      console.log('Event data:', data);
      setIsEventModalOpen(false);
      setSelectedEvent(null);
    } catch (error) {
      console.error('Error submitting event:', error);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Calendário</h1>
          <p className="mt-1 text-sm text-gray-500">
            Gerencie eventos e reuniões
          </p>
        </div>
        <div className="flex items-center space-x-3">
          <button
            onClick={() => setIsSyncModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <Link2 className="h-4 w-4 mr-2" />
            Sincronizar
          </button>
          <button
            onClick={() => {
              setSelectedEvent(null);
              setIsEventModalOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Novo Evento
          </button>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-6">
        <div className="lg:flex-1">
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <BigCalendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 700 }}
              views={['month', 'week', 'day', 'agenda']}
              defaultView={view}
              onView={setView}
              onSelectEvent={handleEventClick}
              messages={{
                today: 'Hoje',
                previous: 'Anterior',
                next: 'Próximo',
                month: 'Mês',
                week: 'Semana',
                day: 'Dia',
                agenda: 'Agenda',
                date: 'Data',
                time: 'Hora',
                event: 'Evento',
                noEventsInRange: 'Não há eventos no período selecionado.',
              }}
              eventPropGetter={(event) => ({
                className: `bg-${event.category}-600 hover:bg-${event.category}-700`,
              })}
            />
          </div>
        </div>

        <div className="lg:w-80 space-y-6">
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <EventSourceSelector
              sources={eventSources}
              onChange={() => {}}
            />
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm">
            <CalendarFilters
              filters={filters}
              onFilterChange={setFilters}
              team={team}
            />
          </div>
        </div>
      </div>

      {/* Event Form Modal */}
      <Modal
        isOpen={isEventModalOpen}
        onClose={() => {
          setIsEventModalOpen(false);
          setSelectedEvent(null);
        }}
        title={selectedEvent ? 'Editar Evento' : 'Novo Evento'}
      >
        <EventForm
          event={selectedEvent}
          onSubmit={handleEventSubmit}
          onCancel={() => {
            setIsEventModalOpen(false);
            setSelectedEvent(null);
          }}
          team={team}
        />
      </Modal>

      {/* Event Details Modal */}
      {selectedEvent && (
        <Modal
          isOpen={isDetailsModalOpen}
          onClose={() => {
            setIsDetailsModalOpen(false);
            setSelectedEvent(null);
          }}
          title="Detalhes do Evento"
        >
          <EventDetails
            event={selectedEvent}
            onClose={() => {
              setIsDetailsModalOpen(false);
              setSelectedEvent(null);
            }}
            onEdit={() => {
              setIsDetailsModalOpen(false);
              setIsEventModalOpen(true);
            }}
          />
        </Modal>
      )}

      {/* Calendar Sync Modal */}
      <CalendarSyncModal
        isOpen={isSyncModalOpen}
        onClose={() => setIsSyncModalOpen(false)}
      />
    </div>
  );
}