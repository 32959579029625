import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Calendar, Search, Filter, Plus, Repeat, AlertCircle } from 'lucide-react';
import { Modal } from '../ui/Modal';
import { CostCategoryForm } from './CostCategoryForm';
import { RecurringCostModal } from './RecurringCostModal';
import { toast } from 'react-hot-toast';

interface Cost {
  id: string;
  description: string;
  category: string;
  amount: number;
  dueDate: string;
  status: 'pending' | 'paid' | 'overdue';
  type: 'fixed' | 'variable';
  recurrence?: {
    frequency: 'monthly' | 'quarterly' | 'yearly';
    nextDueDate: string;
    endDate?: string;
  };
  supplier: string;
  notes?: string;
}

export function AccountsPayable() {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');
  const [costType, setCostType] = useState<'fixed' | 'variable' | 'all'>('all');
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRecurringModalOpen, setIsRecurringModalOpen] = useState(false);
  const [selectedCost, setSelectedCost] = useState<Cost | null>(null);

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ['accounts-payable', { search, filter, costType, dateRange }],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return {
        costs: [
          {
            id: '1',
            description: 'Aluguel do Escritório',
            category: 'Infraestrutura',
            amount: 5000,
            dueDate: '2024-03-15',
            status: 'pending',
            type: 'fixed',
            recurrence: {
              frequency: 'monthly',
              nextDueDate: '2024-04-15',
            },
            supplier: 'Imobiliária XYZ',
          },
          {
            id: '2',
            description: 'Licenças de Software',
            category: 'Software',
            amount: 2500,
            dueDate: '2024-03-20',
            status: 'pending',
            type: 'fixed',
            recurrence: {
              frequency: 'monthly',
              nextDueDate: '2024-04-20',
            },
            supplier: 'Tech Solutions Inc',
          },
          {
            id: '3',
            description: 'Manutenção de Equipamentos',
            category: 'Infraestrutura',
            amount: 1500,
            dueDate: '2024-03-25',
            status: 'pending',
            type: 'variable',
            supplier: 'Maintenance Co.',
          },
        ],
        summary: {
          totalFixed: 7500,
          totalVariable: 1500,
          totalPending: 9000,
          totalOverdue: 0,
          nextMonthProjection: 7500,
        },
      };
    },
  });

  const createMutation = useMutation({
    mutationFn: async (cost: Partial<Cost>) => {
      // TODO: Replace with actual API call
      console.log('Creating cost:', cost);
      return { ...cost, id: Date.now().toString() };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['accounts-payable'] });
      setIsModalOpen(false);
      setSelectedCost(null);
      toast.success('Custo criado com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao criar custo');
    },
  });

  const updateMutation = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<Cost> }) => {
      // TODO: Replace with actual API call
      console.log('Updating cost:', { id, data });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['accounts-payable'] });
      setIsModalOpen(false);
      setSelectedCost(null);
      toast.success('Custo atualizado com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao atualizar custo');
    },
  });

  const handleSubmit = async (data: Partial<Cost>) => {
    if (selectedCost) {
      await updateMutation.mutateAsync({ id: selectedCost.id, data });
    } else {
      await createMutation.mutateAsync(data);
    }
  };

  if (isLoading) return <div>Carregando...</div>;

  const { costs, summary } = data;

  return (
    <div className="space-y-6">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-5">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Repeat className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Custos Fixos
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    R$ {summary.totalFixed.toLocaleString('pt-BR')}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <AlertCircle className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Custos Variáveis
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    R$ {summary.totalVariable.toLocaleString('pt-BR')}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Calendar className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Projeção Próximo Mês
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    R$ {summary.nextMonthProjection.toLocaleString('pt-BR')}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <AlertCircle className="h-6 w-6 text-yellow-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Pendente
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    R$ {summary.totalPending.toLocaleString('pt-BR')}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <AlertCircle className="h-6 w-6 text-red-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Atrasado
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    R$ {summary.totalOverdue.toLocaleString('pt-BR')}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Filters */}
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex-1 max-w-xs">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar custos..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <select
            value={costType}
            onChange={(e) => setCostType(e.target.value as 'fixed' | 'variable' | 'all')}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="all">Todos os Tipos</option>
            <option value="fixed">Custos Fixos</option>
            <option value="variable">Custos Variáveis</option>
          </select>

          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="all">Todos os Status</option>
            <option value="pending">Pendentes</option>
            <option value="paid">Pagos</option>
            <option value="overdue">Atrasados</option>
          </select>

          <div className="flex items-center gap-2">
            <input
              type="date"
              value={dateRange.startDate}
              onChange={(e) =>
                setDateRange((prev) => ({ ...prev, startDate: e.target.value }))
              }
              className="border rounded-md px-3 py-2"
            />
            <span>até</span>
            <input
              type="date"
              value={dateRange.endDate}
              onChange={(e) =>
                setDateRange((prev) => ({ ...prev, endDate: e.target.value }))
              }
              className="border rounded-md px-3 py-2"
            />
          </div>

          <button
            onClick={() => {
              setSelectedCost(null);
              setIsModalOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Novo Custo
          </button>
        </div>
      </div>

      {/* Costs Table */}
      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Descrição
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Fornecedor
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Valor
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Vencimento
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Tipo
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Recorrência
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {costs.map((cost) => (
              <tr
                key={cost.id}
                className="hover:bg-gray-50 cursor-pointer"
                onClick={() => {
                  setSelectedCost(cost);
                  setIsModalOpen(true);
                }}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {cost.description}
                  </div>
                  <div className="text-sm text-gray-500">{cost.category}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {cost.supplier}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  R$ {cost.amount.toLocaleString('pt-BR')}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(cost.dueDate).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      cost.type === 'fixed'
                        ? 'bg-blue-100 text-blue-800'
                        : 'bg-purple-100 text-purple-800'
                    }`}
                  >
                    {cost.type === 'fixed' ? 'Fixo' : 'Variável'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      cost.status === 'paid'
                        ? 'bg-green-100 text-green-800'
                        : cost.status === 'overdue'
                        ? 'bg-red-100 text-red-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}
                  >
                    {cost.status === 'paid'
                      ? 'Pago'
                      : cost.status === 'overdue'
                      ? 'Atrasado'
                      : 'Pendente'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {cost.recurrence ? (
                    <div>
                      <span className="font-medium">
                        {cost.recurrence.frequency === 'monthly'
                          ? 'Mensal'
                          : cost.recurrence.frequency === 'quarterly'
                          ? 'Trimestral'
                          : 'Anual'}
                      </span>
                      <br />
                      <span className="text-xs">
                        Próximo: {new Date(cost.recurrence.nextDueDate).toLocaleDateString()}
                      </span>
                    </div>
                  ) : (
                    'Não recorrente'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Cost Form Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedCost(null);
        }}
        title={selectedCost ? 'Editar Custo' : 'Novo Custo'}
      >
        <CostCategoryForm
          cost={selectedCost}
          onSubmit={handleSubmit}
          onCancel={() => {
            setIsModalOpen(false);
            setSelectedCost(null);
          }}
          onRecurringSetup={(cost) => {
            setSelectedCost(cost);
            setIsModalOpen(false);
            setIsRecurringModalOpen(true);
          }}
        />
      </Modal>

      {/* Recurring Cost Modal */}
      {selectedCost && (
        <RecurringCostModal
          isOpen={isRecurringModalOpen}
          onClose={() => {
            setIsRecurringModalOpen(false);
            setSelectedCost(null);
          }}
          cost={selectedCost}
          onSubmit={async (data) => {
            if (selectedCost.id) {
              await updateMutation.mutateAsync({
                id: selectedCost.id,
                data: { ...selectedCost, ...data },
              });
            } else {
              await createMutation.mutateAsync({ ...selectedCost, ...data });
            }
            setIsRecurringModalOpen(false);
            setSelectedCost(null);
          }}
        />
      )}
    </div>
  );
}