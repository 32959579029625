import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FunnelCard } from './FunnelCard';

interface FunnelStageProps {
  stage: {
    id: string;
    name: string;
    color: string;
  };
  proposals: any[];
  metrics: {
    count: number;
    value: number;
  };
}

export function FunnelStage({ stage, proposals, metrics }: FunnelStageProps) {
  const {
    setNodeRef,
    attributes,
    listeners,
    transition,
    transform,
    isDragging,
  } = useSortable({
    id: stage.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="flex-shrink-0 w-80 flex flex-col bg-gray-50 rounded-lg"
    >
      <div className={`p-4 rounded-t-lg ${stage.color}`}>
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-medium text-gray-900">{stage.name}</h3>
          <span className="text-sm text-gray-500">{metrics.count}</span>
        </div>
        <div className="mt-1 text-sm text-gray-500">
          R$ {metrics.value.toLocaleString('pt-BR')}
        </div>
      </div>

      <div className="flex-1 p-2 space-y-2 min-h-[200px]">
        {proposals.map((proposal) => (
          <FunnelCard key={proposal.id} proposal={proposal} />
        ))}
      </div>
    </div>
  );
}