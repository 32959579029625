import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Plus } from 'lucide-react';
import { PipelineDeal } from './PipelineDeal';

interface Stage {
  id: string;
  name: string;
  color: string;
  deals: any[];
}

interface PipelineStageProps {
  stage: Stage;
  deals: any[];
}

export function PipelineStage({ stage, deals }: PipelineStageProps) {
  const { setNodeRef } = useDroppable({
    id: stage.id,
  });

  return (
    <div className="flex-shrink-0 w-80 flex flex-col bg-gray-50 rounded-lg">
      <div className={`p-4 rounded-t-lg ${stage.color}`}>
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-medium text-gray-900">{stage.name}</h3>
          <span className="text-sm text-gray-500">{deals.length}</span>
        </div>
        <div className="mt-1 text-sm text-gray-500">
          R${' '}
          {deals
            .reduce((sum, deal) => sum + deal.value, 0)
            .toLocaleString('pt-BR')}
        </div>
      </div>

      <div
        ref={setNodeRef}
        className="flex-1 p-2 space-y-2 min-h-[200px]"
      >
        <SortableContext
          items={deals.map((d) => d.id)}
          strategy={verticalListSortingStrategy}
        >
          {deals.map((deal) => (
            <PipelineDeal key={deal.id} deal={deal} />
          ))}
        </SortableContext>
      </div>

      <div className="p-2">
        <button className="w-full flex items-center justify-center px-4 py-2 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-md">
          <Plus className="h-4 w-4 mr-1" />
          Adicionar
        </button>
      </div>
    </div>
  );
}