import { NavLink } from 'react-router-dom';
import { useAuthStore } from '@/store/authStore';
import { navigation } from './navigation';
import { Logo } from './Logo';
import { CompanyInfo } from './CompanyInfo';
import { NavMenu } from './NavMenu';

interface SidebarProps {
  onItemClick?: () => void;
}

export function Sidebar({ onItemClick }: SidebarProps) {
  return (
    <div className="flex h-full flex-col border-r border-gray-200 bg-white">
      <Logo />
      <div className="flex flex-1 flex-col overflow-y-auto">
        <NavMenu items={navigation} onItemClick={onItemClick} />
      </div>
      <CompanyInfo />
    </div>
  );
}