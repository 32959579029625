import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { 
  Plus, 
  Search, 
  Building2, 
  Users, 
  DollarSign,
  Brain,
  Calendar,
  Download,
  Filter,
  BarChart2,
  Mail,
  Phone,
  User,
} from 'lucide-react';
import { Client, CompanyContact } from '../types/company';
import { Modal } from '../components/ui/Modal';
import { ClientForm } from '../components/companies/ClientForm';
import { CompanyContactForm } from '../components/companies/CompanyContactForm';
import { CompanyDetails } from '../components/companies/CompanyDetails';
import { CompanyAnalytics } from '../components/companies/CompanyAnalytics';
import { CompanyProjects } from '../components/companies/CompanyProjects';
import { Tab } from '@headlessui/react';
import { toast } from 'react-hot-toast';
import { useCompanyStore } from '../store/companyStore';

export default function Companies() {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');
  const [view, setView] = useState<'grid' | 'list'>('list');
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedContact, setSelectedContact] = useState<CompanyContact | null>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ['clients', { page, search, filter }],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return {
        clients: [
          {
            id: '1',
            type: 'company' as const,
            name: 'Tech Corp',
            tradingName: 'TechCorp',
            document: '12.345.678/0001-90',
            stateRegistration: '123456789',
            industry: 'Technology',
            isAICompany: true,
            website: 'https://techcorp.com',
            email: 'contact@techcorp.com',
            phone: '(11) 3456-7890',
            address: {
              street: 'Av. Paulista',
              number: '1000',
              complement: 'Sala 1010',
              neighborhood: 'Bela Vista',
              city: 'São Paulo',
              state: 'SP',
              zipCode: '01310-100',
              country: 'Brasil',
            },
            contacts: [
              {
                id: '1',
                name: 'John Doe',
                email: 'john@techcorp.com',
                phone: '(11) 98765-4321',
                position: 'CEO',
                role: 'owner',
                isPrimaryContact: true,
                isFinancialContact: false,
                status: 'active',
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
              },
            ],
            status: 'active',
            tags: ['Technology', 'Enterprise'],
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            createdBy: '1',
            updatedBy: '1',
          },
        ] as Client[],
        totalPages: 1,
        metrics: {
          totalClients: 150,
          activeClients: 120,
          averageRevenue: 75000,
          clientSatisfaction: 4.5,
        },
      };
    },
  });

  const createMutation = useMutation({
    mutationFn: async (data: any) => {
      // TODO: Replace with actual API call
      console.log('Creating client:', data);
      return { ...data, id: Date.now().toString() };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clients'] });
      setIsModalOpen(false);
      toast.success('Cliente criado com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao criar cliente');
    },
  });

  const updateMutation = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: any }) => {
      // TODO: Replace with actual API call
      console.log('Updating client:', { id, data });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clients'] });
      setIsModalOpen(false);
      toast.success('Cliente atualizado com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao atualizar cliente');
    },
  });

  const contactMutation = useMutation({
    mutationFn: async ({ clientId, contact }: { clientId: string; contact: any }) => {
      // TODO: Replace with actual API call
      console.log('Managing contact:', { clientId, contact });
      return contact;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clients'] });
      setIsContactModalOpen(false);
      toast.success(selectedContact ? 'Contato atualizado com sucesso!' : 'Contato adicionado com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao gerenciar contato');
    },
  });

  const handleSubmit = async (data: any) => {
    if (selectedClient) {
      await updateMutation.mutateAsync({ id: selectedClient.id, data });
    } else {
      await createMutation.mutateAsync(data);
    }
  };

  const handleContactSubmit = async (data: any) => {
    if (!selectedClient) return;

    await contactMutation.mutateAsync({
      clientId: selectedClient.id,
      contact: {
        ...data,
        id: selectedContact?.id || Date.now().toString(),
      },
    });
  };

  const exportClients = () => {
    // TODO: Implement export functionality
    toast.success('Exportação iniciada');
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-full">Carregando...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Clientes</h1>
          <p className="mt-1 text-sm text-gray-500">
            Gerencie seus clientes e contatos
          </p>
        </div>
        <div className="flex items-center space-x-3">
          <button
            onClick={() => setView(view === 'grid' ? 'list' : 'grid')}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            {view === 'grid' ? 'Visualização em Lista' : 'Visualização em Grid'}
          </button>
          <button
            onClick={exportClients}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <Download className="h-4 w-4 mr-2" />
            Exportar
          </button>
          <button
            onClick={() => {
              setSelectedClient(null);
              setIsModalOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Novo Cliente
          </button>
        </div>
      </div>

      {/* Analytics Cards */}
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-4">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Users className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Total de Clientes
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {data?.metrics.totalClients}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Brain className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Clientes Ativos
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {data?.metrics.activeClients}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <DollarSign className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Receita Média
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    R$ {data?.metrics.averageRevenue.toLocaleString('pt-BR')}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <BarChart2 className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Satisfação
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {data?.metrics.clientSatisfaction.toFixed(1)}/5.0
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Filters */}
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex-1 max-w-xs">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar clientes..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="all">Todos os Tipos</option>
            <option value="company">Empresas</option>
            <option value="person">Pessoas Físicas</option>
            <option value="active">Ativos</option>
            <option value="inactive">Inativos</option>
          </select>
        </div>
      </div>

      {/* Client List/Grid */}
      {view === 'list' ? (
        <div className="bg-white shadow-sm rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Cliente
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Tipo
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contato
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Projetos
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Última Atualização
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data?.clients.map((client) => (
                <tr
                  key={client.id}
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => {
                    setSelectedClient(client);
                    setIsDetailsOpen(true);
                  }}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        {client.type === 'company' ? (
                          <Building2 className="h-10 w-10 rounded-full bg-gray-100 p-2 text-gray-500" />
                        ) : (
                          <User className="h-10 w-10 rounded-full bg-gray-100 p-2 text-gray-500" />
                        )}
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {client.name}
                        </div>
                        {client.type === 'company' && client.tradingName && (
                          <div className="text-sm text-gray-500">
                            {client.tradingName}
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {client.type === 'company' ? 'Empresa' : 'Pessoa Física'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{client.email}</div>
                    <div className="text-sm text-gray-500">{client.phone}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      client.status === 'active'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    }`}>
                      {client.status === 'active' ? 'Ativo' : 'Inativo'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    3 projetos ativos
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(client.updatedAt).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {data?.clients.map((client) => (
            <div
              key={client.id}
              className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
              onClick={() => {
                setSelectedClient(client);
                setIsDetailsOpen(true);
              }}
            >
              <div className="p-6">
                <div className="flex items-center">
                  <div className="flex-shrink-0 h-12 w-12">
                    {client.type === 'company' ? (
                      <Building2 className="h-12 w-12 rounded-full bg-gray-100 p-2 text-gray-500" />
                    ) : (
                      <User className="h-12 w-12 rounded-full bg-gray-100 p-2 text-gray-500" />
                    )}
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-medium text-gray-900">
                      {client.name}
                    </h3>
                    {client.type === 'company' && client.tradingName && (
                      <p className="text-sm text-gray-500">{client.tradingName}</p>
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    client.type === 'company'
                      ? 'bg-blue-100 text-blue-800'
                      : 'bg-purple-100 text-purple-800'
                  }`}>
                    {client.type === 'company' ? 'Empresa' : 'Pessoa Física'}
                  </span>
                  {client.type === 'company' && client.isAICompany && (
                    <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                      AI Company
                    </span>
                  )}
                </div>

                <div className="mt-4 space-y-2">
                  <div className="flex items-center text-sm">
                    <Mail className="h-4 w-4 text-gray-400 mr-2" />
                    {client.email}
                  </div>
                  <div className="flex items-center text-sm">
                    <Phone className="h-4 w-4 text-gray-400 mr-2" />
                    {client.phone}
                  </div>
                  {client.type === 'company' && (
                    <div className="flex items-center text-sm">
                      <Building2 className="h-4 w-4 text-gray-400 mr-2" />
                      {client.industry}
                    </div>
                  )}
                </div>

                <div className="mt-4">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Status:</span>
                    <span className={`font-medium ${
                      client.status === 'active'
                        ? 'text-green-600'
                        : 'text-red-600'
                    }`}>
                      {client.status === 'active' ? 'Ativo' : 'Inativo'}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm mt-1">
                    <span className="text-gray-500">Projetos Ativos:</span>
                    <span className="font-medium">3</span>
                  </div>
                </div>

                {client.type === 'company' && client.contacts && (
                  <div className="mt-4 pt-4 border-t border-gray-200">
                    <h4 className="text-sm font-medium text-gray-900 mb-2">
                      Contatos Principais
                    </h4>
                    <div className="space-y-2">
                      {client.contacts
                        .filter((c) => c.isPrimaryContact || c.isFinancialContact)
                        .map((contact) => (
                          <div
                            key={contact.id}
                            className="flex items-center justify-between text-sm"
                          >
                            <span className="text-gray-600">{contact.name}</span>
                            <span className="text-gray-500">{contact.position}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                <div className="mt-4 pt-4 border-t border-gray-200">
                  <div className="flex justify-between text-sm text-gray-500">
                    <span>Última atualização:</span>
                    <span>{new Date(client.updatedAt).toLocaleDateString()}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Client Form Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedClient(null);
        }}
        title={selectedClient ? 'Editar Cliente' : 'Novo Cliente'}
      >
        <ClientForm
          client={selectedClient}
          onSubmit={handleSubmit}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>

      {/* Contact Form Modal */}
      {selectedClient?.type === 'company' && (
        <Modal
          isOpen={isContactModalOpen}
          onClose={() => {
            setIsContactModalOpen(false);
            setSelectedContact(null);
          }}
          title={selectedContact ? 'Editar Contato' : 'Novo Contato'}
        >
          <CompanyContactForm
            contact={selectedContact}
            onSubmit={handleContactSubmit}
            onCancel={() => setIsContactModalOpen(false)}
          />
        </Modal>
      )}

      {/* Client Details Modal */}
      <Modal
        isOpen={isDetailsOpen}
        onClose={() => {
          setIsDetailsOpen(false);
          setSelectedClient(null);
        }}
        title="Detalhes do Cliente"
      >
        {selectedClient && (
          <div className="space-y-6">
            <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
              <Tab.List className="flex space-x-4 border-b">
                <Tab
                  className={({ selected }) =>
                    `px-4 py-2 text-sm font-medium border-b-2 ${
                      selected
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`
                  }
                >
                  Informações
                </Tab>
                {selectedClient.type === 'company' && (
                  <Tab
                    className={({ selected }) =>
                      `px-4 py-2 text-sm font-medium border-b-2 ${
                        selected
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      }`
                    }
                  >
                    Contatos
                  </Tab>
                )}
                <Tab
                  className={({ selected }) =>
                    `px-4 py-2 text-sm font-medium border-b-2 ${
                      selected
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`
                  }
                >
                  Projetos
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `px-4 py-2 text-sm font-medium border-b-2 ${
                      selected
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`
                  }
                >
                  Análise
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-4">
                <Tab.Panel>
                  <CompanyDetails company={selectedClient} />
                </Tab.Panel>
                {selectedClient.type === 'company' && (
                  <Tab.Panel>
                    <div className="space-y-4">
                      <div className="flex justify-between items-center">
                        <h3 className="text-lg font-medium text-gray-900">
                          Contatos
                        </h3>
                        <button
                          onClick={() => {
                            setSelectedContact(null);
                            setIsContactModalOpen(true);
                          }}
                          className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                        >
                          <Plus className="h-4 w-4 mr-1" />
                          Novo Contato
                        </button>
                      </div>

                      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Nome
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Cargo
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Contato
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Função
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {selectedClient.contacts.map((contact) => (
                              <tr
                                key={contact.id}
                                className="hover:bg-gray-50 cursor-pointer"
                                onClick={() => {
                                  setSelectedContact(contact);
                                  setIsContactModalOpen(true);
                                }}
                              >
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm font-medium text-gray-900">
                                    {contact.name}
                                  </div>
                                  {(contact.isPrimaryContact || contact.isFinancialContact) && (
                                    <div className="mt-1">
                                      {contact.isPrimaryContact && (
                                        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                                          Principal
                                        </span>
                                      )}
                                      {contact.isFinancialContact && (
                                        <span className="ml-1 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                                          Financeiro
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {contact.position}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900">
                                    {contact.email}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {contact.phone}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {contact.role === 'owner'
                                    ? 'Proprietário'
                                    : contact.role === 'director'
                                    ? 'Diretor'
                                    : contact.role === 'manager'
                                    ? 'Gerente'
                                    : contact.role === 'supervisor'
                                    ? 'Supervisor'
                                    : contact.role === 'employee'
                                    ? 'Colaborador'
                                    : 'Sócio'}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <span
                                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                      contact.status === 'active'
                                        ? 'bg-green-100  text-green-800'
                                        : 'bg-red-100 text-red-800'
                                    }`}
                                  >
                                    {contact.status === 'active' ? 'Ativo' : 'Inativo'}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Tab.Panel>
                )}
                <Tab.Panel>
                  <CompanyProjects company={selectedClient} />
                </Tab.Panel>
                <Tab.Panel>
                  <CompanyAnalytics company={selectedClient} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        )}
      </Modal>
    </div>
  );
}