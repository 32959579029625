import { Calendar, Users, Brain, TrendingUp, DollarSign } from 'lucide-react';
import { AIProject } from '../../types';

interface ProjectCardProps {
  project: AIProject;
  onClick: () => void;
  onBudgetClick: (e: React.MouseEvent) => void;
}

export function ProjectCard({ project, onClick, onBudgetClick }: ProjectCardProps) {
  const statusColors = {
    planning: 'bg-yellow-100 text-yellow-800',
    in_progress: 'bg-blue-100 text-blue-800',
    review: 'bg-purple-100 text-purple-800',
    completed: 'bg-green-100 text-green-800',
    on_hold: 'bg-gray-100 text-gray-800',
  };

  const priorityColors = {
    low: 'bg-green-100 text-green-800',
    medium: 'bg-yellow-100 text-yellow-800',
    high: 'bg-red-100 text-red-800',
  };

  return (
    <div className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow">
      <div className="p-6">
        <div className="flex justify-between items-start">
          <h3 className="text-lg font-medium text-gray-900">{project.name}</h3>
          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
              statusColors[project.status]
            }`}
          >
            {project.status.replace('_', ' ').toUpperCase()}
          </span>
        </div>

        <p className="mt-2 text-sm text-gray-500 line-clamp-2">
          {project.description}
        </p>

        <div className="mt-4">
          <div className="flex justify-between items-center text-sm text-gray-500">
            <span>Progresso</span>
            <span>{project.progress}%</span>
          </div>
          <div className="mt-1 w-full bg-gray-200 rounded-full h-2">
            <div
              className="bg-indigo-600 rounded-full h-2"
              style={{ width: `${project.progress}%` }}
            />
          </div>
        </div>

        <div className="mt-4 flex items-center justify-between text-sm">
          <div className="flex items-center text-gray-500">
            <Calendar className="h-4 w-4 mr-1" />
            <span>{new Date(project.endDate).toLocaleDateString()}</span>
          </div>
          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
              priorityColors[project.priority]
            }`}
          >
            {project.priority.toUpperCase()}
          </span>
        </div>

        <div className="mt-4 flex flex-wrap gap-2">
          {project.technologies.map((tech) => (
            <span
              key={tech}
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
            >
              {tech}
            </span>
          ))}
        </div>

        <div className="mt-6 flex justify-between items-center">
          <button
            onClick={onClick}
            className="text-indigo-600 hover:text-indigo-800 text-sm font-medium"
          >
            Ver Detalhes
          </button>
          <button
            onClick={onBudgetClick}
            className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <DollarSign className="h-4 w-4 mr-1" />
            Orçamento
          </button>
        </div>
      </div>
    </div>
  );
}