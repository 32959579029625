import { create } from 'zustand';
import { AuthState } from '../types';

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  company: null,
  isAuthenticated: false,
  
  login: async (email: string, password: string) => {
    // TODO: Implement actual authentication
    const mockUser = {
      id: '1',
      name: 'John Doe',
      email,
      role: 'admin' as const,
      companyId: '1',
    };
    
    const mockCompany = {
      id: '1',
      name: 'AI Solutions Inc',
      industry: 'Artificial Intelligence',
      isAICompany: true,
    };
    
    set({ user: mockUser, company: mockCompany, isAuthenticated: true });
  },
  
  logout: () => {
    set({ user: null, company: null, isAuthenticated: false });
  },
}));