import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TrendingUp, AlertCircle } from 'lucide-react';

export function ResourceForecast() {
  const [period, setPeriod] = useState('quarter');
  const [role, setRole] = useState('all');

  const { data: forecast, isLoading } = useQuery({
    queryKey: ['resource-forecast', { period, role }],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return {
        periods: [
          {
            name: 'Q2 2024',
            roles: [
              {
                role: 'Developer',
                requiredHours: 960,
                availableHours: 800,
                gap: -160,
                projects: [
                  { name: 'Project A', hours: 480 },
                  { name: 'Project B', hours: 480 },
                ],
              },
              {
                role: 'Designer',
                requiredHours: 480,
                availableHours: 400,
                gap: -80,
                projects: [
                  { name: 'Project A', hours: 240 },
                  { name: 'Project C', hours: 240 },
                ],
              },
            ],
          },
          {
            name: 'Q3 2024',
            roles: [
              {
                role: 'Developer',
                requiredHours: 1040,
                availableHours: 880,
                gap: -160,
                projects: [
                  { name: 'Project B', hours: 520 },
                  { name: 'Project D', hours: 520 },
                ],
              },
            ],
          },
        ],
        summary: {
          totalGap: -320,
          criticalRoles: ['Developer', 'Designer'],
          recommendations: [
            'Contratar 2 desenvolvedores para Q2 2024',
            'Ajustar cronograma do Projeto B',
            'Considerar terceirização para demanda extra',
          ],
        },
      };
    },
  });

  if (isLoading) return <div>Carregando...</div>;

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <select
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="quarter">Trimestral</option>
            <option value="semester">Semestral</option>
            <option value="year">Anual</option>
          </select>

          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="all">Todas as Funções</option>
            <option value="developer">Desenvolvedores</option>
            <option value="designer">Designers</option>
            <option value="manager">Gerentes</option>
          </select>
        </div>

        <div className="text-sm text-gray-500">
          <span className="font-medium">Gap Total:</span>{' '}
          <span className="text-red-600">{forecast?.summary.totalGap}h</span>
        </div>
      </div>

      {/* Critical Roles Warning */}
      {forecast?.summary.criticalRoles.length > 0 && (
        <div className="bg-red-50 p-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Funções Críticas Identificadas
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <ul className="list-disc pl-5 space-y-1">
                  {forecast.summary.criticalRoles.map((role) => (
                    <li key={role}>{role}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Forecast by Period */}
      {forecast?.periods.map((period) => (
        <div key={period.name} className="bg-white shadow-sm rounded-lg">
          <div className="px-6 py-4 border-b border-gray-200">
            <h3 className="text-lg font-medium text-gray-900">{period.name}</h3>
          </div>
          <div className="divide-y divide-gray-200">
            {period.roles.map((roleData) => (
              <div key={roleData.role} className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h4 className="text-base font-medium text-gray-900">
                      {roleData.role}
                    </h4>
                    <p className="text-sm text-gray-500">
                      Gap: {roleData.gap}h
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-sm text-gray-500">
                      Necessário: {roleData.requiredHours}h
                    </p>
                    <p className="text-sm text-gray-500">
                      Disponível: {roleData.availableHours}h
                    </p>
                  </div>
                </div>

                <div className="space-y-4">
                  <div>
                    <div className="flex justify-between text-sm text-gray-500 mb-1">
                      <span>Utilização</span>
                      <span>
                        {((roleData.availableHours / roleData.requiredHours) * 100).toFixed(1)}%
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2">
                      <div
                        className={`h-2 rounded-full ${
                          roleData.gap < 0 ? 'bg-red-500' : 'bg-green-500'
                        }`}
                        style={{
                          width: `${(roleData.availableHours / roleData.requiredHours) * 100}%`,
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <h5 className="text-sm font-medium text-gray-900 mb-2">
                      Distribuição por Projeto
                    </h5>
                    <div className="space-y-2">
                      {roleData.projects.map((project) => (
                        <div
                          key={project.name}
                          className="flex justify-between text-sm"
                        >
                          <span className="text-gray-500">{project.name}</span>
                          <span className="text-gray-900">{project.hours}h</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}

      {/* Recommendations */}
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Recomendações
        </h3>
        <ul className="space-y-2">
          {forecast?.summary.recommendations.map((recommendation, index) => (
            <li
              key={index}
              className="flex items-start text-sm text-gray-600"
            >
              <TrendingUp className="h-5 w-5 text-indigo-500 mr-2" />
              {recommendation}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}