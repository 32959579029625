import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { 
  Plus, 
  Search, 
  Brain, 
  Users, 
  Calendar,
  TrendingUp,
  AlertCircle,
} from 'lucide-react';
import { useProjectStore } from '../store/projectStore';
import { AIProject } from '../types';
import { ProjectCard } from '../components/projects/ProjectCard';
import { ProjectModal } from '../components/projects/ProjectModal';
import { ProjectDashboard } from '../components/projects/ProjectDashboard';
import { BudgetModal } from '../components/projects/BudgetModal';
import { toast } from 'react-hot-toast';

export default function AIProjects() {
  const [search, setSearch] = useState('');
  const [selectedProject, setSelectedProject] = useState<AIProject | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBudgetModalOpen, setIsBudgetModalOpen] = useState(false);
  const [budgetProjectId, setBudgetProjectId] = useState<string | null>(null);
  const [view, setView] = useState<'grid' | 'dashboard'>('grid');
  const queryClient = useQueryClient();

  const { data: projects, isLoading } = useQuery({
    queryKey: ['projects', { search }],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        {
          id: '1',
          name: 'AI Chatbot Development',
          description: 'Developing an advanced AI chatbot for customer service',
          status: 'in_progress',
          priority: 'high',
          progress: 65,
          startDate: '2024-03-01',
          endDate: '2024-06-30',
          companyId: '1',
          clientId: '1',
          budget: 150000,
          technologies: ['NLP', 'Machine Learning', 'Python', 'TensorFlow'],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
      ];
    },
  });

  const createMutation = useMutation({
    mutationFn: async (data: any) => {
      // TODO: Replace with actual API call
      console.log('Creating project:', data);
      return { ...data, id: Date.now().toString() };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
      setIsModalOpen(false);
      setSelectedProject(null);
      toast.success('Projeto criado com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao criar projeto');
    },
  });

  const updateMutation = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: any }) => {
      // TODO: Replace with actual API call
      console.log('Updating project:', { id, data });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
      setIsModalOpen(false);
      setSelectedProject(null);
      toast.success('Projeto atualizado com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao atualizar projeto');
    },
  });

  const handleSubmit = async (data: any) => {
    if (selectedProject) {
      await updateMutation.mutateAsync({ id: selectedProject.id, data });
    } else {
      await createMutation.mutateAsync(data);
    }
  };

  const handleBudgetClick = (projectId: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setBudgetProjectId(projectId);
    setIsBudgetModalOpen(true);
  };

  const handleBudgetSubmit = async (data: any) => {
    try {
      // TODO: Implement API call to save budget
      console.log('Budget submitted:', data);
      toast.success('Orçamento salvo com sucesso!');
      setIsBudgetModalOpen(false);
      setBudgetProjectId(null);
    } catch (error) {
      console.error('Error submitting budget:', error);
      toast.error('Erro ao salvar orçamento');
    }
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-full">Carregando...</div>;
  }

  const filteredProjects = projects?.filter((project) =>
    project.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Projetos IA</h1>
          <p className="mt-1 text-sm text-gray-500">
            Gerencie seus projetos de inteligência artificial
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setView(view === 'grid' ? 'dashboard' : 'grid')}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            {view === 'grid' ? 'Ver Dashboard' : 'Ver Projetos'}
          </button>
          <button
            onClick={() => {
              setSelectedProject(null);
              setIsModalOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Novo Projeto
          </button>
        </div>
      </div>

      {view === 'dashboard' ? (
        <ProjectDashboard projects={filteredProjects || []} />
      ) : (
        <>
          <div className="flex items-center space-x-4">
            <div className="flex-1 max-w-xs">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Buscar projetos..."
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {filteredProjects?.map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
                onClick={() => {
                  setSelectedProject(project);
                  setIsModalOpen(true);
                }}
                onBudgetClick={handleBudgetClick(project.id)}
              />
            ))}
          </div>
        </>
      )}

      <ProjectModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedProject(null);
        }}
        project={selectedProject}
        onSubmit={handleSubmit}
      />

      {budgetProjectId && (
        <BudgetModal
          isOpen={isBudgetModalOpen}
          onClose={() => {
            setIsBudgetModalOpen(false);
            setBudgetProjectId(null);
          }}
          projectId={budgetProjectId}
          onSubmit={handleBudgetSubmit}
        />
      )}
    </div>
  );
}