import { 
  LayoutDashboard,
  Building2,
  DollarSign,
  Brain,
  Package,
  ShoppingCart,
  Calendar,
  FileText,
  Settings,
  UserCog,
  Users,
  FolderInput
} from 'lucide-react';
import type { NavItem } from './types';

export const navigation: NavItem[] = [
  { name: 'Dashboard', href: '/', icon: LayoutDashboard },
  { 
    name: 'Cadastros',
    icon: FolderInput,
    children: [
      { name: 'Clientes', href: '/companies', icon: Building2 },
      { name: 'Colaboradores', href: '/employees', icon: Users },
      { name: 'Produtos', href: '/products', icon: Package },
      { name: 'Vendedores', href: '/salespeople', icon: UserCog },
    ]
  },
  { name: 'Financeiro', href: '/finance', icon: DollarSign },
  { name: 'Projetos IA', href: '/ai-projects', icon: Brain },
  { name: 'Vendas', href: '/sales', icon: ShoppingCart },
  { name: 'Agenda', href: '/calendar', icon: Calendar },
  { name: 'Orçamentos', href: '/budgets', icon: FileText },
  { name: 'Propostas', href: '/proposals', icon: FileText },
  { name: 'Recursos', href: '/resources', icon: Users },
  { name: 'Configurações', href: '/settings', icon: Settings },
];