import { useState } from 'react';
import { Modal } from '../ui/Modal';
import { Budget } from '../../types';
import { FileText, Trash2, Copy } from 'lucide-react';
import { toast } from 'react-hot-toast';

interface BudgetTemplateListProps {
  isOpen: boolean;
  onClose: () => void;
  templates: Array<{ id: string; name: string; budget: Budget }>;
  onUseTemplate: (budget: Budget) => void;
  onDeleteTemplate: (templateId: string) => Promise<void>;
}

export function BudgetTemplateList({
  isOpen,
  onClose,
  templates,
  onUseTemplate,
  onDeleteTemplate,
}: BudgetTemplateListProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async (templateId: string) => {
    try {
      setIsDeleting(true);
      await onDeleteTemplate(templateId);
      toast.success('Template excluído com sucesso!');
    } catch (error) {
      toast.error('Erro ao excluir template');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Templates de Orçamento"
    >
      <div className="space-y-4">
        {templates.length === 0 ? (
          <div className="text-center py-6">
            <FileText className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              Nenhum template encontrado
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Comece salvando um orçamento como template.
            </p>
          </div>
        ) : (
          <div className="space-y-4">
            {templates.map((template) => (
              <div
                key={template.id}
                className="bg-white border rounded-lg p-4 hover:shadow-sm transition-shadow"
              >
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">
                      {template.name}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {template.budget.items.length} itens · R$ {template.budget.total.toLocaleString('pt-BR')}
                    </p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => onUseTemplate(template.budget)}
                      className="p-2 text-gray-400 hover:text-gray-500"
                      title="Usar Template"
                    >
                      <Copy className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(template.id)}
                      disabled={isDeleting}
                      className="p-2 text-gray-400 hover:text-red-500"
                      title="Excluir Template"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="mt-6 flex justify-end">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Fechar
          </button>
        </div>
      </div>
    </Modal>
  );
}