import { useQuery } from '@tanstack/react-query';
import {
  Users,
  Building2,
  DollarSign,
  Brain,
  TrendingUp,
  Activity,
  Calendar,
  FileText,
  AlertCircle,
  CheckCircle,
  Clock,
  BarChart2,
} from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { MetricCard } from '../components/dashboard/MetricCard';
import { usePermissions } from '../hooks/usePermissions';
import { Link } from 'react-router-dom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default function Dashboard() {
  const { hasPermission } = usePermissions();

  const { data: metrics } = useQuery({
    queryKey: ['dashboard-metrics'],
    queryFn: async () => ({
      totalCustomers: 156,
      totalCompanies: 23,
      monthlyRevenue: 125000,
      activeProjects: 15,
      customerGrowth: 12.5,
      revenueGrowth: 8.3,
      pendingTasks: 8,
      upcomingDeadlines: 3,
      projectProgress: 75,
      salesPipeline: {
        totalValue: 450000,
        deals: 12,
        winRate: 65,
      },
      resourceUtilization: 85,
    }),
  });

  const { data: chartData } = useQuery({
    queryKey: ['dashboard-charts'],
    queryFn: async () => ({
      revenue: {
        labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
        datasets: [
          {
            label: 'Receita',
            data: [65000, 75000, 85000, 95000, 110000, 125000],
            borderColor: 'rgb(99, 102, 241)',
            backgroundColor: 'rgba(99, 102, 241, 0.5)',
          },
          {
            label: 'Custos',
            data: [45000, 50000, 55000, 60000, 70000, 80000],
            borderColor: 'rgb(239, 68, 68)',
            backgroundColor: 'rgba(239, 68, 68, 0.5)',
          },
        ],
      },
      projectStatus: {
        labels: ['Em Andamento', 'Concluídos', 'Atrasados', 'Planejamento'],
        datasets: [
          {
            data: [8, 4, 2, 1],
            backgroundColor: [
              'rgba(59, 130, 246, 0.8)',
              'rgba(34, 197, 94, 0.8)',
              'rgba(239, 68, 68, 0.8)',
              'rgba(234, 179, 8, 0.8)',
            ],
          },
        ],
      },
      customerSegments: {
        labels: ['Enterprise', 'Mid-Market', 'Small Business'],
        datasets: [
          {
            label: 'Clientes por Segmento',
            data: [45, 35, 20],
            backgroundColor: [
              'rgba(99, 102, 241, 0.8)',
              'rgba(147, 51, 234, 0.8)',
              'rgba(59, 130, 246, 0.8)',
            ],
          },
        ],
      },
    }),
  });

  if (!hasPermission('dashboard', 'read')) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-gray-500">
          Você não tem permissão para visualizar o dashboard.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        <p className="mt-2 text-sm text-gray-600">
          Visão geral do desempenho da empresa
        </p>
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        <Link
          to="/ai-projects"
          className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow"
        >
          <div className="flex items-center">
            <div className="p-2 bg-indigo-50 rounded-lg">
              <Brain className="h-6 w-6 text-indigo-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-900">Novo Projeto</p>
              <p className="text-xs text-gray-500">Criar projeto de IA</p>
            </div>
          </div>
        </Link>

        <Link
          to="/customers"
          className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow"
        >
          <div className="flex items-center">
            <div className="p-2 bg-green-50 rounded-lg">
              <Users className="h-6 w-6 text-green-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-900">Novo Cliente</p>
              <p className="text-xs text-gray-500">Cadastrar cliente</p>
            </div>
          </div>
        </Link>

        <Link
          to="/proposals"
          className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow"
        >
          <div className="flex items-center">
            <div className="p-2 bg-blue-50 rounded-lg">
              <FileText className="h-6 w-6 text-blue-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-900">Nova Proposta</p>
              <p className="text-xs text-gray-500">Criar proposta comercial</p>
            </div>
          </div>
        </Link>

        <Link
          to="/calendar"
          className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow"
        >
          <div className="flex items-center">
            <div className="p-2 bg-purple-50 rounded-lg">
              <Calendar className="h-6 w-6 text-purple-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-900">Agenda</p>
              <p className="text-xs text-gray-500">Ver compromissos</p>
            </div>
          </div>
        </Link>
      </div>

      {/* Key Metrics */}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        <MetricCard
          title="Receita Mensal"
          value={`R$ ${metrics?.monthlyRevenue?.toLocaleString('pt-BR')}`}
          icon={DollarSign}
          trend={{ value: metrics?.revenueGrowth ?? 0, isPositive: true }}
        />
        <MetricCard
          title="Projetos Ativos"
          value={metrics?.activeProjects ?? 0}
          icon={Brain}
          trend={{ value: metrics?.projectProgress ?? 0, isPositive: true }}
        />
        <MetricCard
          title="Pipeline de Vendas"
          value={`R$ ${metrics?.salesPipeline.totalValue?.toLocaleString('pt-BR')}`}
          icon={TrendingUp}
          trend={{ value: metrics?.salesPipeline.winRate ?? 0, isPositive: true }}
        />
        <MetricCard
          title="Utilização de Recursos"
          value={`${metrics?.resourceUtilization ?? 0}%`}
          icon={Activity}
        />
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-medium text-gray-900 mb-4">
            Receita vs. Custos
          </h2>
          <div className="h-80">
            {chartData && (
              <Line
                data={chartData.revenue}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: 'bottom',
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        callback: (value) =>
                          `R$ ${Number(value).toLocaleString('pt-BR')}`,
                      },
                    },
                  },
                }}
              />
            )}
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-medium text-gray-900 mb-4">
            Status dos Projetos
          </h2>
          <div className="h-80">
            {chartData && (
              <Doughnut
                data={chartData.projectStatus}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: 'bottom',
                    },
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>

      {/* Additional Information */}
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        {/* Upcoming Deadlines */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <Clock className="h-5 w-5 mr-2 text-gray-400" />
            Próximos Prazos
          </h2>
          <div className="space-y-4">
            {metrics?.upcomingDeadlines > 0 ? (
              <div className="flex items-center justify-between p-3 bg-yellow-50 rounded-lg">
                <div className="flex items-center">
                  <AlertCircle className="h-5 w-5 text-yellow-400 mr-2" />
                  <span className="text-sm text-yellow-700">
                    {metrics.upcomingDeadlines} entregas próximas
                  </span>
                </div>
                <Link
                  to="/calendar"
                  className="text-sm font-medium text-yellow-700 hover:text-yellow-800"
                >
                  Ver
                </Link>
              </div>
            ) : (
              <div className="flex items-center p-3 bg-green-50 rounded-lg">
                <CheckCircle className="h-5 w-5 text-green-400 mr-2" />
                <span className="text-sm text-green-700">
                  Nenhum prazo próximo
                </span>
              </div>
            )}
          </div>
        </div>

        {/* Customer Distribution */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <Users className="h-5 w-5 mr-2 text-gray-400" />
            Distribuição de Clientes
          </h2>
          <div className="h-64">
            {chartData && (
              <Bar
                data={chartData.customerSegments}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        callback: (value) => `${value}%`,
                      },
                    },
                  },
                }}
              />
            )}
          </div>
        </div>

        {/* Pending Tasks */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <CheckCircle className="h-5 w-5 mr-2 text-gray-400" />
            Tarefas Pendentes
          </h2>
          <div className="space-y-4">
            {metrics?.pendingTasks > 0 ? (
              <div className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
                <div className="flex items-center">
                  <Clock className="h-5 w-5 text-blue-400 mr-2" />
                  <span className="text-sm text-blue-700">
                    {metrics.pendingTasks} tarefas aguardando
                  </span>
                </div>
                <Link
                  to="/ai-projects"
                  className="text-sm font-medium text-blue-700 hover:text-blue-800"
                >
                  Ver
                </Link>
              </div>
            ) : (
              <div className="flex items-center p-3 bg-green-50 rounded-lg">
                <CheckCircle className="h-5 w-5 text-green-400 mr-2" />
                <span className="text-sm text-green-700">
                  Nenhuma tarefa pendente
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}