import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Plus, MoreVertical, DollarSign, Calendar, User } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { PipelineStage } from './PipelineStage';
import { PipelineDeal } from './PipelineDeal';

interface Deal {
  id: string;
  title: string;
  value: number;
  customer: string;
  dueDate: string;
  owner: string;
  stage: string;
  probability: number;
}

interface Stage {
  id: string;
  name: string;
  color: string;
  deals: Deal[];
}

const initialStages: Stage[] = [
  {
    id: 'prospecting',
    name: 'Prospecção',
    color: 'bg-gray-200',
    deals: [],
  },
  {
    id: 'qualification',
    name: 'Qualificação',
    color: 'bg-blue-200',
    deals: [],
  },
  {
    id: 'proposal',
    name: 'Proposta',
    color: 'bg-yellow-200',
    deals: [],
  },
  {
    id: 'negotiation',
    name: 'Negociação',
    color: 'bg-orange-200',
    deals: [],
  },
  {
    id: 'closed_won',
    name: 'Ganhos',
    color: 'bg-green-200',
    deals: [],
  },
  {
    id: 'closed_lost',
    name: 'Perdidos',
    color: 'bg-red-200',
    deals: [],
  },
];

export function SalesPipeline() {
  const [stages, setStages] = useState(initialStages);
  const [activeId, setActiveId] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { data: deals, isLoading } = useQuery({
    queryKey: ['pipeline-deals'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        {
          id: '1',
          title: 'AI Chatbot Project',
          value: 50000,
          customer: 'Tech Corp',
          dueDate: '2024-04-15',
          owner: 'Maria Santos',
          stage: 'proposal',
          probability: 60,
        },
        {
          id: '2',
          title: 'ML Pipeline Implementation',
          value: 75000,
          customer: 'Data Inc',
          dueDate: '2024-04-30',
          owner: 'João Silva',
          stage: 'negotiation',
          probability: 80,
        },
      ];
    },
  });

  const updateDealStageMutation = useMutation({
    mutationFn: async ({ dealId, newStage }: { dealId: string; newStage: string }) => {
      // TODO: Replace with actual API call
      console.log('Updating deal stage:', { dealId, newStage });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pipeline-deals'] });
      toast.success('Oportunidade movida com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao mover oportunidade');
    },
  });

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;

    if (!over) return;

    const activeDeal = stages
      .find((s) => s.deals.some((d) => d.id === active.id))
      ?.deals.find((d) => d.id === active.id);

    if (!activeDeal) return;

    const sourceStage = stages.find((s) => s.deals.some((d) => d.id === active.id));
    const targetStage = stages.find((s) => s.id === over.id);

    if (!sourceStage || !targetStage) return;

    if (sourceStage.id !== targetStage.id) {
      setStages((prevStages) =>
        prevStages.map((stage) => {
          if (stage.id === sourceStage.id) {
            return {
              ...stage,
              deals: stage.deals.filter((d) => d.id !== active.id),
            };
          }
          if (stage.id === targetStage.id) {
            return {
              ...stage,
              deals: [...stage.deals, { ...activeDeal, stage: stage.id }],
            };
          }
          return stage;
        })
      );

      try {
        await updateDealStageMutation.mutateAsync({
          dealId: active.id,
          newStage: targetStage.id,
        });
      } catch (error) {
        // Revert changes on error
        setStages(stages);
      }
    }

    setActiveId(null);
  };

  // Calculate metrics
  const calculateMetrics = () => {
    const totalValue = stages.reduce(
      (sum, stage) =>
        sum + stage.deals.reduce((stageSum, deal) => stageSum + deal.value, 0),
      0
    );

    const stageMetrics = stages.map((stage) => ({
      id: stage.id,
      name: stage.name,
      count: stage.deals.length,
      value: stage.deals.reduce((sum, deal) => sum + deal.value, 0),
      conversionRate:
        stage.deals.length > 0
          ? (stage.deals.filter((d) => d.probability >= 50).length /
              stage.deals.length) *
            100
          : 0,
    }));

    return { totalValue, stageMetrics };
  };

  const { totalValue, stageMetrics } = calculateMetrics();

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  // Distribute deals to stages
  if (deals) {
    stages.forEach((stage) => {
      stage.deals = deals.filter((deal) => deal.stage === stage.id);
    });
  }

  return (
    <div className="space-y-6">
      {/* Pipeline Metrics */}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="flex items-center">
            <DollarSign className="h-6 w-6 text-gray-400" />
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-500">Valor Total</p>
              <p className="text-lg font-semibold text-gray-900">
                R$ {totalValue.toLocaleString('pt-BR')}
              </p>
            </div>
          </div>
        </div>

        {stageMetrics.slice(0, 3).map((metric) => (
          <div key={metric.id} className="bg-white p-4 rounded-lg shadow-sm">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">{metric.name}</p>
                <p className="text-lg font-semibold text-gray-900">
                  {metric.count} oportunidades
                </p>
              </div>
              <div className="text-right">
                <p className="text-sm text-gray-500">Conversão</p>
                <p className="text-lg font-semibold text-gray-900">
                  {metric.conversionRate.toFixed(1)}%
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pipeline Stages */}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <div className="flex gap-4 overflow-x-auto pb-4">
          {stages.map((stage) => (
            <PipelineStage
              key={stage.id}
              stage={stage}
              deals={stage.deals}
            />
          ))}
        </div>

        <DragOverlay>
          {activeId ? (
            <PipelineDeal
              deal={stages
                .find((s) => s.deals.some((d) => d.id === activeId))
                ?.deals.find((d) => d.id === activeId)!
              }
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
}