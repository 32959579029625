import axios from 'axios';

const BASE_URL = '/.netlify/functions/mongodb';

export const mongoApi = {
  async find(collection: string, query = {}) {
    const response = await axios.post(BASE_URL, {
      collection,
      action: 'find',
      query,
    });
    return response.data;
  },

  async findOne(collection: string, query = {}) {
    const response = await axios.post(BASE_URL, {
      collection,
      action: 'findOne',
      query,
    });
    return response.data;
  },

  async insert(collection: string, data: any) {
    const response = await axios.post(BASE_URL, {
      collection,
      action: 'insert',
      data,
    });
    return response.data;
  },

  async update(collection: string, query: any, data: any) {
    const response = await axios.post(BASE_URL, {
      collection,
      action: 'update',
      query,
      data,
    });
    return response.data;
  },

  async delete(collection: string, query: any) {
    const response = await axios.post(BASE_URL, {
      collection,
      action: 'delete',
      query,
    });
    return response.data;
  },
};