import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Calendar, Search, Filter, AlertCircle } from 'lucide-react';

export function CapacityPlanning() {
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7));
  const [filter, setFilter] = useState('all');

  const { data: capacityData, isLoading } = useQuery({
    queryKey: ['capacity-planning', { month: selectedMonth, filter }],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return {
        resources: [
          {
            id: '1',
            name: 'João Silva',
            role: 'Developer',
            capacity: 160,
            allocated: 140,
            projects: [
              { name: 'Project A', hours: 80 },
              { name: 'Project B', hours: 60 },
            ],
          },
        ],
        summary: {
          totalCapacity: 1600,
          totalAllocated: 1400,
          utilizationRate: 87.5,
        },
      };
    },
  });

  if (isLoading) return <div>Carregando...</div>;

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <input
            type="month"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />

          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="all">Todas as Funções</option>
            <option value="developer">Desenvolvedores</option>
            <option value="designer">Designers</option>
            <option value="manager">Gerentes</option>
          </select>
        </div>

        <div className="text-sm text-gray-500">
          <span className="font-medium">Utilização Total:</span>{' '}
          <span
            className={`${
              capacityData?.summary.utilizationRate > 90
                ? 'text-red-600'
                : capacityData?.summary.utilizationRate > 80
                ? 'text-yellow-600'
                : 'text-green-600'
            }`}
          >
            {capacityData?.summary.utilizationRate}%
          </span>
        </div>
      </div>

      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Recurso
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Capacidade
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Alocado
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Utilização
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Projetos
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {capacityData?.resources.map((resource) => (
              <tr key={resource.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {resource.name}
                  </div>
                  <div className="text-sm text-gray-500">{resource.role}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {resource.capacity}h
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {resource.allocated}h
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="w-32 bg-gray-200 rounded-full h-2 mr-2">
                      <div
                        className={`h-2 rounded-full ${
                          (resource.allocated / resource.capacity) * 100 > 90
                            ? 'bg-red-500'
                            : (resource.allocated / resource.capacity) * 100 > 80
                            ? 'bg-yellow-500'
                            : 'bg-green-500'
                        }`}
                        style={{
                          width: `${(resource.allocated / resource.capacity) * 100}%`,
                        }}
                      />
                    </div>
                    <span className="text-sm text-gray-500">
                      {((resource.allocated / resource.capacity) * 100).toFixed(1)}%
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="space-y-1">
                    {resource.projects.map((project, index) => (
                      <div
                        key={index}
                        className="flex justify-between text-sm text-gray-500"
                      >
                        <span>{project.name}</span>
                        <span>{project.hours}h</span>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Capacity Warnings */}
      <div className="space-y-4">
        {capacityData?.resources
          .filter((r) => (r.allocated / r.capacity) * 100 > 90)
          .map((resource) => (
            <div
              key={resource.id}
              className="flex items-center justify-between p-4 bg-red-50 rounded-lg"
            >
              <div className="flex items-center">
                <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
                <div>
                  <p className="text-sm font-medium text-red-800">
                    {resource.name} está sobrecarregado
                  </p>
                  <p className="text-sm text-red-600">
                    Utilização:{' '}
                    {((resource. allocated / resource.capacity) * 100).toFixed(1)}%
                  </p>
                </div>
              </div>
              <button className="text-sm font-medium text-red-600 hover:text-red-500">
                Ajustar Alocação
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}