import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Database, Server, Key, Shield } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { mongoApi } from '@/lib/api/mongodb';

const connectionSchema = z.object({
  host: z.string().min(1, 'Host é obrigatório'),
  port: z.string().regex(/^\d+$/, 'Porta deve ser um número'),
  database: z.string().min(1, 'Nome do banco é obrigatório'),
  username: z.string().min(1, 'Usuário é obrigatório'),
  password: z.string().min(1, 'Senha é obrigatória'),
  authSource: z.string().optional(),
  ssl: z.boolean().default(true),
});

type ConnectionFormData = z.infer<typeof connectionSchema>;

export function DatabaseConnection() {
  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConnectionFormData>({
    resolver: zodResolver(connectionSchema),
    defaultValues: {
      ssl: true,
      port: '27017',
      authSource: 'admin',
    },
  });

  const onSubmit = async (data: ConnectionFormData) => {
    setIsConnecting(true);
    try {
      // Construct MongoDB URI
      const uri = `mongodb+srv://${data.username}:${encodeURIComponent(data.password)}@${
        data.host
      }/${data.database}?retryWrites=true&w=majority${
        data.ssl ? '' : '&ssl=false'
      }${data.authSource ? `&authSource=${data.authSource}` : ''}`;

      // Test connection
      const response = await mongoApi.find('system.version', {});
      
      // Save connection details securely
      localStorage.setItem('mongodb_config', JSON.stringify({
        uri,
        database: data.database,
      }));

      setIsConnected(true);
      toast.success('Conexão estabelecida com sucesso!');
    } catch (error) {
      console.error('Connection error:', error);
      toast.error('Erro ao conectar com o MongoDB');
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium text-gray-900">Conexão com MongoDB</h2>
        <p className="mt-1 text-sm text-gray-500">
          Configure a conexão com seu banco de dados MongoDB
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Host
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Server className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                {...register('host')}
                className="block w-full pl-10 sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="cluster0.xxxxx.mongodb.net"
              />
            </div>
            {errors.host && (
              <p className="mt-1 text-sm text-red-600">{errors.host.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Porta
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                {...register('port')}
                className="block w-full sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="27017"
              />
            </div>
            {errors.port && (
              <p className="mt-1 text-sm text-red-600">{errors.port.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Banco de Dados
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Database className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                {...register('database')}
                className="block w-full pl-10 sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="meu_banco"
              />
            </div>
            {errors.database && (
              <p className="mt-1 text-sm text-red-600">{errors.database.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Auth Source
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Shield className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                {...register('authSource')}
                className="block w-full pl-10 sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="admin"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Usuário
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Key className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                {...register('username')}
                className="block w-full pl-10 sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            {errors.username && (
              <p className="mt-1 text-sm text-red-600">{errors.username.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Senha
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Key className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="password"
                {...register('password')}
                className="block w-full pl-10 sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            {errors.password && (
              <p className="mt-1 text-sm text-red-600">{errors.password.message}</p>
            )}
          </div>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            {...register('ssl')}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label className="ml-2 block text-sm text-gray-900">
            Usar SSL/TLS
          </label>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => {
              localStorage.removeItem('mongodb_config');
              setIsConnected(false);
              toast.success('Configuração removida');
            }}
            className="px-4 py-2 text-sm font-medium text-red-700 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Remover Conexão
          </button>
          <button
            type="submit"
            disabled={isConnecting}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
          >
            {isConnecting ? 'Conectando...' : isConnected ? 'Reconectar' : 'Conectar'}
          </button>
        </div>
      </form>

      {isConnected && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <Database className="h-5 w-5 text-green-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                Conexão estabelecida
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p>
                  Seu aplicativo está conectado ao MongoDB. Todas as operações de
                  banco de dados estão funcionando normalmente.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}