import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Download, Filter } from 'lucide-react';
import { toast } from 'react-hot-toast';

export function SalesReport() {
  const [period, setPeriod] = useState('month');
  const [reportType, setReportType] = useState('summary');

  const { data: reportData, isLoading } = useQuery({
    queryKey: ['sales-report', { period, reportType }],
    queryFn: async () => ({
      summary: {
        totalSales: 450000,
        totalDeals: 15,
        averageTicket: 30000,
        conversionRate: 65,
        topProducts: [
          { name: 'AI Chatbot', value: 150000, quantity: 3 },
          { name: 'ML Pipeline', value: 120000, quantity: 2 },
          { name: 'Data Analytics', value: 100000, quantity: 2 },
        ],
        topSalespeople: [
          { name: 'Maria Santos', value: 180000, deals: 6 },
          { name: 'João Silva', value: 150000, deals: 5 },
          { name: 'Ana Costa', value: 120000, deals: 4 },
        ],
        salesByStatus: {
          completed: 10,
          pending: 3,
          cancelled: 2,
        },
        salesByPaymentMethod: {
          transfer: 8,
          credit_card: 4,
          pix: 3,
        },
      },
    }),
  });

  const downloadReport = () => {
    // TODO: Implement report download
    toast.success('Download do relatório iniciado');
  };

  if (isLoading) return <div>Carregando...</div>;

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <select
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            className="border rounded-md px-3 py-2"
          >
            <option value="month">Este Mês</option>
            <option value="quarter">Este Trimestre</option>
            <option value="year">Este Ano</option>
            <option value="custom">Personalizado</option>
          </select>

          <select
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            className="border rounded-md px-3 py-2"
          >
            <option value="summary">Resumo de Vendas</option>
            <option value="detailed">Relatório Detalhado</option>
            <option value="performance">Performance da Equipe</option>
            <option value="products">Análise de Produtos</option>
          </select>
        </div>

        <button
          onClick={downloadReport}
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
          <Download className="h-4 w-4 mr-2" />
          Exportar Relatório
        </button>
      </div>

      <div className="grid grid-cols-1 gap-6">
        {/* Summary Section */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Resumo do Período
          </h3>
          <dl className="grid grid-cols-1 gap-5 sm:grid-cols-4">
            <div className="px-4 py-5 bg-gray-50 rounded-lg overflow-hidden">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Total de Vendas
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                R$ {reportData.summary.totalSales.toLocaleString('pt-BR')}
              </dd>
            </div>
            <div className="px-4 py-5 bg-gray-50 rounded-lg overflow-hidden">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Negócios Fechados
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {reportData.summary.totalDeals}
              </dd>
            </div>
            <div className="px-4 py-5 bg-gray-50 rounded-lg overflow-hidden">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Ticket Médio
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                R$ {reportData.summary.averageTicket.toLocaleString('pt-BR')}
              </dd>
            </div>
            <div className="px-4 py-5 bg-gray-50 rounded-lg overflow-hidden">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Taxa de Conversão
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {reportData.summary.conversionRate}%
              </dd>
            </div>
          </dl>
        </div>

        {/* Top Products */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Produtos Mais Ven didos
          </h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Produto
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Quantidade
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Valor Total
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    % das Vendas
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {reportData.summary.topProducts.map((product) => (
                  <tr key={product.name}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {product.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {product.quantity}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      R$ {product.value.toLocaleString('pt-BR')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {((product.value / reportData.summary.totalSales) * 100).toFixed(1)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Sales Team Performance */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Performance da Equipe
          </h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Vendedor
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Vendas
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Valor Total
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ticket Médio
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    % da Meta
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {reportData.summary.topSalespeople.map((person) => (
                  <tr key={person.name}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {person.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {person.deals}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      R$ {person.value.toLocaleString('pt-BR')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      R$ {(person.value / person.deals).toLocaleString('pt-BR')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-1 h-2 bg-gray-200 rounded-full mr-2">
                          <div
                            className="h-2 bg-indigo-600 rounded-full"
                            style={{
                              width: `${(person.value / reportData.summary.totalSales) * 100}%`,
                            }}
                          />
                        </div>
                        <span className="text-sm font-medium text-gray-900">
                          {((person.value / reportData.summary.totalSales) * 100).toFixed(1)}%
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Sales Distribution */}
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Status das Vendas
            </h3>
            <div className="space-y-4">
              {Object.entries(reportData.summary.salesByStatus).map(([status, count]) => (
                <div key={status}>
                  <div className="flex justify-between items-center mb-1">
                    <span className="text-sm font-medium text-gray-600">
                      {status === 'completed'
                        ? 'Concluídas'
                        : status === 'pending'
                        ? 'Pendentes'
                        : 'Canceladas'}
                    </span>
                    <span className="text-sm font-medium text-gray-900">
                      {count}
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className={`h-2 rounded-full ${
                        status === 'completed'
                          ? 'bg-green-600'
                          : status === 'pending'
                          ? 'bg-yellow-600'
                          : 'bg-red-600'
                      }`}
                      style={{
                        width: `${(count / reportData.summary.totalDeals) * 100}%`,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Formas de Pagamento
            </h3>
            <div className="space-y-4">
              {Object.entries(reportData.summary.salesByPaymentMethod).map(([method, count]) => (
                <div key={method}>
                  <div className="flex justify-between items-center mb-1">
                    <span className="text-sm font-medium text-gray-600">
                      {method === 'transfer'
                        ? 'Transferência'
                        : method === 'credit_card'
                        ? 'Cartão de Crédito'
                        : 'PIX'}
                    </span>
                    <span className="text-sm font-medium text-gray-900">
                      {count}
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-indigo-600 h-2 rounded-full"
                      style={{
                        width: `${(count / reportData.summary.totalDeals) * 100}%`,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}