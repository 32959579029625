import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Tab } from '@headlessui/react';
import { 
  Users, 
  Clock, 
  TrendingUp, 
  Calendar,
  UserCog,
  BarChart2,
  FileText
} from 'lucide-react';
import { ResourceAllocation } from '../components/resources/ResourceAllocation';
import { TimeTracking } from '../components/resources/TimeTracking';
import { CapacityPlanning } from '../components/resources/CapacityPlanning';
import { ResourceForecast } from '../components/resources/ResourceForecast';
import { ResourceDashboard } from '../components/resources/ResourceDashboard';

export default function ResourceManagement() {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [
    { name: 'Dashboard', icon: BarChart2 },
    { name: 'Alocação', icon: Users },
    { name: 'Horas', icon: Clock },
    { name: 'Capacidade', icon: Calendar },
    { name: 'Previsão', icon: TrendingUp },
  ];

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-semibold text-gray-900">
          Gestão de Recursos
        </h1>
        <p className="mt-1 text-sm text-gray-500">
          Gerencie alocações, horas e capacidade da equipe
        </p>
      </div>

      <div className="bg-white shadow rounded-lg">
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.List className="flex space-x-4 p-4 border-b">
            {tabs.map((tab) => (
              <Tab
                key={tab.name}
                className={({ selected }) =>
                  `flex items-center px-4 py-2 text-sm font-medium rounded-md transition-colors ${
                    selected
                      ? 'bg-indigo-50 text-indigo-700'
                      : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                  }`
                }
              >
                <tab.icon className="h-5 w-5 mr-2" />
                {tab.name}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel>
              <div className="p-6">
                <ResourceDashboard />
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="p-6">
                <ResourceAllocation />
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="p-6">
                <TimeTracking />
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="p-6">
                <CapacityPlanning />
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="p-6">
                <ResourceForecast />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}