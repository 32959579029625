import { useState } from 'react';
import { Plus, Search, Calendar } from 'lucide-react';
import { Company } from '../../types';

interface CompanyProjectsProps {
  company: Company;
}

export function CompanyProjects({ company }: CompanyProjectsProps) {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');

  const projects = [
    {
      id: '1',
      name: 'AI Chatbot Development',
      status: 'in_progress',
      progress: 65,
      startDate: '2024-03-01',
      endDate: '2024-06-30',
      budget: 150000,
      team: ['John Doe', 'Jane Smith'],
    },
    {
      id: '2',
      name: 'ML Pipeline Implementation',
      status: 'planning',
      progress: 25,
      startDate: '2024-04-01',
      endDate: '2024-07-31',
      budget: 200000,
      team: ['Alice Johnson', 'Bob Wilson'],
    },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex-1 max-w-xs">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar projetos..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="all">Todos os Status</option>
            <option value="planning">Planejamento</option>
            <option value="in_progress">Em Progresso</option>
            <option value="completed">Concluído</option>
          </select>

          <button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700">
            <Plus className="h-4 w-4 mr-2" />
            Novo Projeto
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        {projects.map((project) => (
          <div
            key={project.id}
            className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
          >
            <div className="p-6">
              <div className="flex justify-between items-start">
                <h3 className="text-lg font-medium text-gray-900">
                  {project.name}
                </h3>
                <span
                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    project.status === 'completed'
                      ? 'bg-green-100 text-green-800'
                      : project.status === 'in_progress'
                      ? 'bg-blue-100 text-blue-800'
                      : 'bg-yellow-100 text-yellow-800'
                  }`}
                >
                  {project.status === 'completed'
                    ? 'Concluído'
                    : project.status === 'in_progress'
                    ? 'Em Progresso'
                    : 'Planejamento'}
                </span>
              </div>

              <div className="mt-4">
                <div className="flex justify-between text-sm text-gray-500">
                  <span>Progresso</span>
                  <span>{project.progress}%</span>
                </div>
                <div className="mt-1 w-full bg-gray-200 rounded-full h-2">
                  <div
                    className="bg-indigo-600 rounded-full h-2"
                    style={{ width: `${project.progress}%` }}
                  />
                </div>
              </div>

              <div className="mt-4 grid grid-cols-2 gap-4">
                <div>
                  <div className="text-sm font-medium text-gray-500">Início</div>
                  <div className="mt-1 text-sm text-gray-900">
                    {new Date(project.startDate).toLocaleDateString()}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-500">Término</div>
                  <div className="mt-1 text-sm text-gray-900">
                    {new Date(project.endDate).toLocaleDateString()}
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="text-sm font-medium text-gray-500">Orçamento</div>
                <div className="mt-1 text-sm text-gray-900">
                  R$ {project.budget.toLocaleString('pt-BR')}
                </div>
              </div>

              <div className="mt-4">
                <div className="text-sm font-medium text-gray-500">Equipe</div>
                <div className="mt-1 flex flex-wrap gap-2">
                  {project.team.map((member) => (
                    <span
                      key={member}
                      className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                    >
                      {member}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}