import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MoreVertical, DollarSign, Calendar, User } from 'lucide-react';

interface Deal {
  id: string;
  title: string;
  value: number;
  customer: string;
  dueDate: string;
  owner: string;
  probability: number;
}

interface PipelineDealProps {
  deal: Deal;
}

export function PipelineDeal({ deal }: PipelineDealProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: deal.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="bg-white p-3 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-grab active:cursor-grabbing touch-manipulation"
    >
      <div className="flex items-center justify-between">
        <h4 className="text-sm font-medium text-gray-900">{deal.title}</h4>
        <button className="text-gray-400 hover:text-gray-500">
          <MoreVertical className="h-4 w-4" />
        </button>
      </div>
      <p className="mt-1 text-sm text-gray-500">{deal.customer}</p>
      <div className="mt-2 flex items-center justify-between text-sm">
        <div className="flex items-center text-gray-500">
          <DollarSign className="h-4 w-4 mr-1" />
          R$ {deal.value.toLocaleString('pt-BR')}
        </div>
        <div className="flex items-center text-gray-500">
          <Calendar className="h-4 w-4 mr-1" />
          {new Date(deal.dueDate).toLocaleDateString()}
        </div>
      </div>
      <div className="mt-2 flex items-center">
        <User className="h-4 w-4 text-gray-400 mr-1" />
        <span className="text-sm text-gray-500">{deal.owner}</span>
      </div>
      <div className="mt-2">
        <div className="flex justify-between text-xs text-gray-500">
          <span>Probabilidade</span>
          <span>{deal.probability}%</span>
        </div>
        <div className="mt-1 w-full bg-gray-200 rounded-full h-1.5">
          <div
            className="bg-green-500 h-1.5 rounded-full"
            style={{ width: `${deal.probability}%` }}
          />
        </div>
      </div>
    </div>
  );
}