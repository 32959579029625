import { useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { AIProject } from '../../types';
import { MetricCard } from '../dashboard/MetricCard';
import { Brain, Clock, DollarSign, Users } from 'lucide-react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

interface ProjectDashboardProps {
  projects: AIProject[];
}

export function ProjectDashboard({ projects }: ProjectDashboardProps) {
  const metrics = useMemo(() => {
    const totalBudget = projects.reduce((sum, p) => sum + p.budget, 0);
    const avgProgress = projects.reduce((sum, p) => sum + p.progress, 0) / projects.length;
    const activeProjects = projects.filter(p => p.status === 'in_progress').length;
    const completedProjects = projects.filter(p => p.status === 'completed').length;

    return {
      totalBudget,
      avgProgress,
      activeProjects,
      completedProjects,
    };
  }, [projects]);

  const statusData = useMemo(() => {
    const statusCounts = projects.reduce((acc, project) => {
      acc[project.status] = (acc[project.status] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    return {
      labels: ['Planejamento', 'Em Progresso', 'Revisão', 'Concluído', 'Em Espera'],
      datasets: [
        {
          data: [
            statusCounts.planning || 0,
            statusCounts.in_progress || 0,
            statusCounts.review || 0,
            statusCounts.completed || 0,
            statusCounts.on_hold || 0,
          ],
          backgroundColor: [
            'rgba(255, 206, 86, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(201, 203, 207, 0.8)',
          ],
        },
      ],
    };
  }, [projects]);

  const priorityData = useMemo(() => ({
    labels: ['Alta', 'Média', 'Baixa'],
    datasets: [
      {
        label: 'Projetos por Prioridade',
        data: [
          projects.filter(p => p.priority === 'high').length,
          projects.filter(p => p.priority === 'medium').length,
          projects.filter(p => p.priority === 'low').length,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(75, 192, 192, 0.8)',
        ],
      },
    ],
  }), [projects]);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <MetricCard
          title="Projetos Ativos"
          value={metrics.activeProjects}
          icon={Brain}
        />
        <MetricCard
          title="Projetos Concluídos"
          value={metrics.completedProjects}
          icon={Clock}
        />
        <MetricCard
          title="Orçamento Total"
          value={`R$ ${metrics.totalBudget.toLocaleString('pt-BR')}`}
          icon={DollarSign}
        />
        <MetricCard
          title="Progresso Médio"
          value={`${metrics.avgProgress.toFixed(1)}%`}
          icon={Users}
        />
      </div>

      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-medium text-gray-900 mb-4">
            Status dos Projetos
          </h2>
          <div className="h-64">
            <Doughnut
              data={statusData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom',
                  },
                },
              }}
            />
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-medium text-gray-900 mb-4">
            Projetos por Prioridade
          </h2>
          <div className="h-64">
            <Bar
              data={priorityData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}