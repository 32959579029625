import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Plus,
  Search,
  Calendar,
  TrendingUp,
  DollarSign,
  CreditCard,
  PieChart,
  FileText,
  ArrowUpRight,
  ArrowDownRight,
  Building2,
  Users,
} from 'lucide-react';
import { Tab } from '@headlessui/react';
import { FinanceDashboard } from '../components/finance/FinanceDashboard';
import { AccountsPayable } from '../components/finance/AccountsPayable';
import { AccountsReceivable } from '../components/finance/AccountsReceivable';
import { CostCenter } from '../components/finance/CostCenter';
import { FinancialReports } from '../components/finance/FinancialReports';
import { SuppliersList } from '../components/finance/SuppliersList';
import { TransactionModal } from '../components/finance/TransactionModal';

export default function Finance() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });

  const tabs = [
    { name: 'Dashboard', icon: PieChart },
    { name: 'Contas a Pagar', icon: ArrowDownRight },
    { name: 'Contas a Receber', icon: ArrowUpRight },
    { name: 'Centro de Custos', icon: DollarSign },
    { name: 'Fornecedores', icon: Building2 },
    { name: 'Relatórios', icon: FileText },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Financeiro</h1>
          <p className="mt-1 text-sm text-gray-500">
            Gestão financeira completa
          </p>
        </div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          Nova Transação
        </button>
      </div>

      <div className="bg-white shadow rounded-lg">
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.List className="flex space-x-4 p-4 border-b">
            {tabs.map((tab) => (
              <Tab
                key={tab.name}
                className={({ selected }) =>
                  `flex items-center px-4 py-2 text-sm font-medium rounded-md transition-colors ${
                    selected
                      ? 'bg-indigo-50 text-indigo-700'
                      : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                  }`
                }
              >
                <tab.icon className="h-5 w-5 mr-2" />
                {tab.name}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel>
              <div className="p-6">
                <FinanceDashboard />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="p-6">
                <AccountsPayable />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="p-6">
                <AccountsReceivable />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="p-6">
                <CostCenter />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="p-6">
                <SuppliersList />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="p-6">
                <FinancialReports />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      <TransactionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}