import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Plus,
  Search,
  FileText,
  Link2,
  Download,
  Copy,
  ExternalLink,
  Filter,
} from 'lucide-react';
import { Tab } from '@headlessui/react';
import { ProposalForm } from '../components/proposals/ProposalForm';
import { ProposalList } from '../components/proposals/ProposalList';
import { ProposalPreview } from '../components/proposals/ProposalPreview';
import { Modal } from '../components/ui/Modal';
import { toast } from 'react-hot-toast';

interface Proposal {
  id: string;
  title: string;
  clientId: string;
  clientName: string;
  projectId?: string;
  projectName?: string;
  content: {
    introduction: string;
    scope: string;
    deliverables: string[];
    timeline: string;
    investment: {
      total: number;
      breakdown: Array<{
        description: string;
        value: number;
      }>;
    };
    terms: string;
  };
  status: 'draft' | 'sent' | 'accepted' | 'rejected';
  validUntil: string;
  createdAt: string;
  updatedAt: string;
  publicUrl?: string;
}

export default function CommercialProposals() {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState<Proposal | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ['proposals', { search, filter }],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return {
        proposals: [
          {
            id: '1',
            title: 'AI Chatbot Development Proposal',
            clientId: '1',
            clientName: 'Tech Corp',
            projectId: '1',
            projectName: 'AI Chatbot',
            content: {
              introduction: 'Proposta para desenvolvimento de chatbot com IA...',
              scope: 'O projeto inclui desenvolvimento, treinamento e implantação...',
              deliverables: [
                'Chatbot integrado ao website',
                'Dashboard de análise',
                'Documentação técnica',
              ],
              timeline: '3 meses',
              investment: {
                total: 150000,
                breakdown: [
                  { description: 'Desenvolvimento', value: 80000 },
                  { description: 'Treinamento', value: 40000 },
                  { description: 'Implantação', value: 30000 },
                ],
              },
              terms: 'Termos e condições da proposta...',
            },
            status: 'sent',
            validUntil: '2024-04-15',
            createdAt: '2024-03-01',
            updatedAt: '2024-03-01',
            publicUrl: 'https://proposals.example.com/p/abc123',
          },
        ] as Proposal[],
        metrics: {
          totalProposals: 15,
          activeProposals: 8,
          acceptanceRate: 75,
          averageValue: 120000,
        },
      };
    },
  });

  const createMutation = useMutation({
    mutationFn: async (proposal: Partial<Proposal>) => {
      // TODO: Replace with actual API call
      console.log('Creating proposal:', proposal);
      return { ...proposal, id: Date.now().toString() };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['proposals'] });
      setIsModalOpen(false);
      setSelectedProposal(null);
      toast.success('Proposta criada com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao criar proposta');
    },
  });

  const updateMutation = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<Proposal> }) => {
      // TODO: Replace with actual API call
      console.log('Updating proposal:', { id, data });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['proposals'] });
      setIsModalOpen(false);
      setSelectedProposal(null);
      toast.success('Proposta atualizada com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao atualizar proposta');
    },
  });

  const handleSubmit = async (data: Partial<Proposal>) => {
    if (selectedProposal) {
      await updateMutation.mutateAsync({ id: selectedProposal.id, data });
    } else {
      await createMutation.mutateAsync(data);
    }
  };

  const copyPublicLink = (url: string) => {
    navigator.clipboard.writeText(url);
    toast.success('Link copiado para a área de transferência!');
  };

  if (isLoading) return <div>Carregando...</div>;

  const { proposals, metrics } = data;

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">
            Propostas Comerciais
          </h1>
          <p className="mt-1 text-sm text-gray-500">
            Gerencie suas propostas comerciais
          </p>
        </div>
        <button
          onClick={() => {
            setSelectedProposal(null);
            setIsModalOpen(true);
          }}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          Nova Proposta
        </button>
      </div>

      {/* Metrics */}
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-4">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <FileText className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Total de Propostas
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {metrics.totalProposals}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Link2 className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Propostas Ativas
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {metrics.activeProposals}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Download className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Taxa de Aceitação
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {metrics.acceptanceRate}%
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <FileText className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Valor Médio
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    R$ {metrics.averageValue.toLocaleString('pt-BR')}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Filters */}
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex-1 max-w-xs">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar propostas..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="all">Todas as Propostas</option>
            <option value="draft">Rascunhos</option>
            <option value="sent">Enviadas</option>
            <option value="accepted">Aceitas</option>
            <option value="rejected">Rejeitadas</option>
          </select>
        </div>
      </div>

      {/* Proposals List */}
      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Proposta
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Cliente
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Valor
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Validade
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {proposals.map((proposal) => (
                  <tr key={proposal.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {proposal.title}
                      </div>
                      {proposal.projectName && (
                        <div className="text-sm text-gray-500">
                          {proposal.projectName}
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {proposal.clientName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      R$ {proposal.content.investment.total.toLocaleString('pt-BR')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          proposal.status === 'accepted'
                            ? 'bg-green-100 text-green-800'
                            : proposal.status === 'rejected'
                            ? 'bg-red-100 text-red-800'
                            : proposal.status === 'sent'
                            ? 'bg-blue-100 text-blue-800'
                            : 'bg-gray-100 text-gray-800'
                        }`}
                      >
                        {proposal.status === 'accepted'
                          ? 'Aceita'
                          : proposal.status === 'rejected'
                          ? 'Rejeitada'
                          : proposal.status === 'sent'
                          ? 'Enviada'
                          : 'Rascunho'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(proposal.validUntil).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="flex items-center space-x-3">
                        <button
                          onClick={() => {
                            setSelectedProposal(proposal);
                            setIsPreviewOpen(true);
                          }}
                          className="text-gray-400 hover:text-gray-500"
                          title="Visualizar"
                        >
                          <FileText className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => {
                            setSelectedProposal(proposal);
                            setIsModalOpen(true);
                          }}
                          className="text-gray-400 hover:text-gray-500"
                          title="Editar"
                        >
                          <FileText className="h-5 w-5" />
                        </button>
                        {proposal.publicUrl && (
                          <>
                            <button
                              onClick={() => copyPublicLink(proposal.publicUrl!)}
                              className="text-gray-400 hover:text-gray-500"
                              title="Copiar Link"
                            >
                              <Copy className="h-5 w-5" />
                            </button>
                            <a
                              href={proposal.publicUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-gray-400 hover:text-gray-500"
                              title="Abrir Link"
                            >
                              <ExternalLink className="h-5 w-5" />
                            </a>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Proposal Form Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedProposal(null);
        }}
        title={selectedProposal ? 'Editar Proposta' : 'Nova Proposta'}
      >
        <ProposalForm
          proposal={selectedProposal}
          onSubmit={handleSubmit}
          onCancel={() => {
            setIsModalOpen(false);
            setSelectedProposal(null);
          }}
        />
      </Modal>

      {/* Proposal Preview Modal */}
      <Modal
        isOpen={isPreviewOpen}
        onClose={() => {
          setIsPreviewOpen(false);
          setSelectedProposal(null);
        }}
        title="Visualizar Proposta"
      >
        {selectedProposal && <ProposalPreview proposal={selectedProposal} />}
      </Modal>
    </div>
  );
}