import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Plus, Search, Filter, FileText, Download, History, Mail } from 'lucide-react';
import { Modal } from '@/components/ui/Modal';
import { BudgetModal } from '@/components/budgets/BudgetModal';
import { BudgetComparisonModal } from '@/components/budgets/BudgetComparisonModal';
import { BudgetEmailModal } from '@/components/budgets/BudgetEmailModal';
import { BudgetHistoryModal } from '@/components/budgets/BudgetHistoryModal';
import { BudgetTemplateModal } from '@/components/budgets/BudgetTemplateModal';
import { BudgetTemplateList } from '@/components/budgets/BudgetTemplateList';
import { useBudgetTemplates } from '@/hooks/useBudgetTemplates';
import { toast } from 'react-hot-toast';

function ProjectBudgets() {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [isComparisonModalOpen, setIsComparisonModalOpen] = useState(false);
  const [selectedBudgets, setSelectedBudgets] = useState<string[]>([]);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [isTemplateListOpen, setIsTemplateListOpen] = useState(false);
  const queryClient = useQueryClient();

  const { templates, saveTemplate, deleteTemplate } = useBudgetTemplates();

  const { data: budgets, isLoading } = useQuery({
    queryKey: ['budgets', { search, filter }],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return {
        budgets: [
          {
            id: '1',
            projectName: 'AI Chatbot Development',
            clientName: 'Tech Corp',
            clientEmail: 'contact@techcorp.com',
            total: 150000,
            margin: 30,
            taxRate: 15,
            status: 'draft',
            validUntil: '2024-04-15',
            items: [
              {
                description: 'Development',
                category: 'labor',
                quantity: 160,
                unitPrice: 150,
                total: 24000,
              },
            ],
            revisions: [
              {
                id: '1',
                version: '1.0',
                createdBy: 'John Doe',
                createdAt: '2024-03-01',
                changes: ['Initial version'],
              },
            ],
          },
        ],
        totalPages: 1,
      };
    },
  });

  const createMutation = useMutation({
    mutationFn: async (data: any) => {
      // TODO: Replace with actual API call
      console.log('Creating budget:', data);
      return { ...data, id: Date.now().toString() };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['budgets'] });
      setIsModalOpen(false);
      toast.success('Orçamento criado com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao criar orçamento');
    },
  });

  const updateMutation = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: any }) => {
      // TODO: Replace with actual API call
      console.log('Updating budget:', { id, data });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['budgets'] });
      setIsModalOpen(false);
      toast.success('Orçamento atualizado com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao atualizar orçamento');
    },
  });

  const handleSubmit = async (data: any) => {
    if (selectedBudget) {
      await updateMutation.mutateAsync({ id: selectedBudget.id, data });
    } else {
      await createMutation.mutateAsync(data);
    }
  };

  const handleTemplateSubmit = async (name: string, budget: any) => {
    try {
      await saveTemplate(name, budget);
      toast.success('Template salvo com sucesso!');
    } catch (error) {
      toast.error('Erro ao salvar template');
    }
  };

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Orçamentos</h1>
          <p className="mt-1 text-sm text-gray-500">
            Gerencie orçamentos de projetos
          </p>
        </div>
        <div className="flex items-center space-x-3">
          <button
            onClick={() => setIsTemplateListOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <FileText className="h-4 w-4 mr-2" />
            Templates
          </button>
          <button
            onClick={() => {
              setSelectedBudget(null);
              setIsModalOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Novo Orçamento
          </button>
        </div>
      </div>

      {/* Filters */}
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex-1 max-w-xs">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar orçamentos..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="all">Todos os Status</option>
            <option value="draft">Rascunhos</option>
            <option value="sent">Enviados</option>
            <option value="approved">Aprovados</option>
            <option value="rejected">Rejeitados</option>
          </select>

          <button
            onClick={() => setIsComparisonModalOpen(true)}
            disabled={selectedBudgets.length !== 2}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <History className="h-4 w-4 mr-2" />
            Comparar
          </button>
        </div>
      </div>

      {/* Budget List */}
      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Projeto
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Cliente
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Valor Total
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Validade
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Ações
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {budgets?.budgets.map((budget) => (
              <tr key={budget.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {budget.projectName}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {budget.clientName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  R$ {budget.total.toLocaleString('pt-BR')}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      budget.status === 'approved'
                        ? 'bg-green-100 text-green-800'
                        : budget.status === 'rejected'
                        ? 'bg-red-100 text-red-800'
                        : budget.status === 'sent'
                        ? 'bg-blue-100 text-blue-800'
                        : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    {budget.status === 'approved'
                      ? 'Aprovado'
                      : budget.status === 'rejected'
                      ? 'Rejeitado'
                      : budget.status === 'sent'
                      ? 'Enviado'
                      : 'Rascunho'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(budget.validUntil).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center space-x-3">
                    <button
                      onClick={() => {
                        setSelectedBudget(budget);
                        setIsModalOpen(true);
                      }}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      <FileText className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => {
                        setSelectedBudget(budget);
                        setIsEmailModalOpen(true);
                      }}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <Mail className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => {
                        setSelectedBudget(budget);
                        setIsHistoryModalOpen(true);
                      }}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <History className="h-5 w-5" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modals */}
      <BudgetModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedBudget(null);
        }}
        budget={selectedBudget}
        onSubmit={handleSubmit}
      />

      <BudgetComparisonModal
        isOpen={isComparisonModalOpen}
        onClose={() => {
          setIsComparisonModalOpen(false);
          setSelectedBudgets([]);
        }}
        budgetIds={selectedBudgets}
      />

      {selectedBudget && (
        <>
          <BudgetEmailModal
            isOpen={isEmailModalOpen}
            onClose={() => {
              setIsEmailModalOpen(false);
              setSelectedBudget(null);
            }}
            budget={selectedBudget}
          />

          <BudgetHistoryModal
            isOpen={isHistoryModalOpen}
            onClose={() => {
              setIsHistoryModalOpen(false);
              setSelectedBudget(null);
            }}
            budget={selectedBudget}
          />

          <BudgetTemplateModal
            isOpen={isTemplateModalOpen}
            onClose={() => {
              setIsTemplateModalOpen(false);
              setSelectedBudget(null);
            }}
            budget={selectedBudget}
            onSaveTemplate={handleTemplateSubmit}
          />
        </>
      )}

      <BudgetTemplateList
        isOpen={isTemplateListOpen}
        onClose={() => setIsTemplateListOpen(false)}
        templates={templates}
        onUseTemplate={(budget) => {
          setSelectedBudget({ ...budget, id: undefined });
          setIsModalOpen(true);
          setIsTemplateListOpen(false);
        }}
        onDeleteTemplate={deleteTemplate}
      />
    </div>
  );
}

export default ProjectBudgets;