import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Modal } from '../ui/Modal';
import { Calendar, AlertCircle } from 'lucide-react';
import { addMonths, addYears, format } from 'date-fns';

const recurringSchema = z.object({
  frequency: z.enum(['monthly', 'quarterly', 'yearly']),
  endDate: z.string().optional(),
});

type RecurringFormData = z.infer<typeof recurringSchema>;

interface RecurringCostModalProps {
  isOpen: boolean;
  onClose: () => void;
  cost: any;
  onSubmit: (data: any) => Promise<void>;
}

export function RecurringCostModal({
  isOpen,
  onClose,
  cost,
  onSubmit,
}: RecurringCostModalProps) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<RecurringFormData>({
    resolver: zodResolver(recurringSchema),
    defaultValues: {
      frequency: 'monthly',
    },
  });

  const frequency = watch('frequency');

  const calculateNextDueDate = (currentDate: string, freq: string) => {
    const date = new Date(currentDate);
    switch (freq) {
      case 'monthly':
        return addMonths(date, 1);
      case 'quarterly':
        return addMonths(date, 3);
      case 'yearly':
        return addYears(date, 1);
      default:
        return date;
    }
  };

  const handleFormSubmit = async (data: RecurringFormData) => {
    const nextDueDate = calculateNextDueDate(cost.dueDate, data.frequency);
    
    await onSubmit({
      ...cost,
      recurrence: {
        frequency: data.frequency,
        nextDueDate: format(nextDueDate, 'yyyy-MM-dd'),
        endDate: data.endDate || undefined,
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Configurar Recorrência"
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Frequência
          </label>
          <select
            {...register('frequency')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="monthly">Mensal</option>
            <option value="quarterly">Trimestral</option>
            <option value="yearly">Anual</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Data de Término (opcional)
          </label>
          <input
            type="date"
            {...register('endDate')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex items-center">
            <Calendar className="h-5 w-5 text-gray-400 mr-2" />
            <div>
              <p className="text-sm font-medium text-gray-900">
                Próximo Vencimento
              </p>
              <p className="text-sm text-gray-500">
                {format(calculateNextDueDate(cost.dueDate, frequency), 'dd/MM/yyyy')}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-blue-50 p-4 rounded-lg">
          <div className="flex items-center">
            <AlertCircle className="h-5 w-5 text-blue-400 mr-2" />
            <div>
              <p className="text-sm font-medium text-blue-800">
                Importante
              </p>
              <p className="text-sm text-blue-700">
                Este custo será automaticamente recriado conforme a frequência selecionada.
                {frequency === 'monthly' && ' Todo mês será gerado um novo lançamento.'}
                {frequency === 'quarterly' && ' A cada 3 meses será gerado um novo lançamento.'}
                {frequency === 'yearly' && ' Todo ano será gerado um novo lançamento.'}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancelar
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
          >
            {isSubmitting ? 'Salvando...' : 'Salvar'}
          </button>
        </div>
      </form>
    </Modal>
  );
}