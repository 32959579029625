import { Routes, Route, Navigate } from 'react-router-dom';
import { DashboardLayout } from './components/Layout';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Companies from './pages/Companies';
import Employees from './pages/Employees';
import Finance from './pages/Finance';
import AIProjects from './pages/AIProjects';
import Products from './pages/Products';
import Sales from './pages/Sales';
import Salespeople from './pages/Salespeople';
import Calendar from './pages/Calendar';
import ProjectBudgets from './pages/ProjectBudgets';
import ResourceManagement from './pages/ResourceManagement';
import CommercialProposals from './pages/CommercialProposals';
import Settings from './pages/Settings';
import { useAuthStore } from './store/authStore';

export default function App() {
  const { isAuthenticated } = useAuthStore();

  return (
    <Routes>
      <Route
        path="/login"
        element={isAuthenticated ? <Navigate to="/" replace /> : <Login />}
      />
      <Route path="/" element={<DashboardLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="companies" element={<Companies />} />
        <Route path="employees" element={<Employees />} />
        <Route path="products" element={<Products />} />
        <Route path="sales" element={<Sales />} />
        <Route path="salespeople" element={<Salespeople />} />
        <Route path="finance" element={<Finance />} />
        <Route path="ai-projects" element={<AIProjects />} />
        <Route path="calendar" element={<Calendar />} />
        <Route path="budgets" element={<ProjectBudgets />} />
        <Route path="proposals" element={<CommercialProposals />} />
        <Route path="resources" element={<ResourceManagement />} />
        <Route path="settings" element={<Settings />} />
      </Route>
    </Routes>
  );
}