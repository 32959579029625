import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Modal } from '../ui/Modal';
import { Plus, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Client, AIProject } from '../../types';

const budgetItemSchema = z.object({
  description: z.string().min(1, 'Descrição é obrigatória'),
  category: z.enum(['labor', 'material', 'software', 'infrastructure', 'other']),
  quantity: z.number().min(1, 'Quantidade deve ser maior que zero'),
  unitPrice: z.number().min(0, 'Preço unitário deve ser maior ou igual a zero'),
  total: z.number(),
});

const budgetSchema = z.object({
  projectId: z.string(),
  clientId: z.string().min(1, 'Cliente é obrigatório'),
  items: z.array(budgetItemSchema),
  notes: z.string().optional(),
  margin: z.number().min(0, 'Margem deve ser maior ou igual a zero').max(100, 'Margem deve ser menor ou igual a 100'),
  taxRate: z.number().min(0, 'Taxa deve ser maior ou igual a zero').max(100, 'Taxa deve ser menor ou igual a 100'),
});

type BudgetFormData = z.infer<typeof budgetSchema>;
type BudgetItem = z.infer<typeof budgetItemSchema>;

interface BudgetModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  onSubmit: (data: BudgetFormData) => Promise<void>;
  initialData?: BudgetFormData;
}

export function BudgetModal({ isOpen, onClose, projectId, onSubmit, initialData }: BudgetModalProps) {
  const [items, setItems] = useState<BudgetItem[]>(initialData?.items || []);

  const { data: clients } = useQuery<Client[]>({
    queryKey: ['clients'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        {
          id: '1',
          name: 'João Silva',
          email: 'joao@email.com',
          phone: '(11) 99999-9999',
          company: 'Tech Corp',
          status: 'active',
          createdAt: '2024-03-01',
        },
      ];
    },
  });

  const { data: project } = useQuery<AIProject>({
    queryKey: ['project', projectId],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return {
        id: projectId,
        name: 'AI Chatbot Development',
        clientId: '1',
        // ... other project fields
      } as AIProject;
    },
    enabled: !!projectId,
  });
  
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<BudgetFormData>({
    resolver: zodResolver(budgetSchema),
    defaultValues: initialData || {
      projectId,
      clientId: project?.clientId || '',
      items: [],
      margin: 30,
      taxRate: 15,
    },
  });

  const addItem = () => {
    setItems([
      ...items,
      {
        description: '',
        category: 'labor',
        quantity: 1,
        unitPrice: 0,
        total: 0,
      },
    ]);
  };

  const removeItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const calculateTotals = (formData: BudgetFormData) => {
    const subtotal = items.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0);
    const marginAmount = subtotal * (formData.margin / 100);
    const taxAmount = (subtotal + marginAmount) * (formData.taxRate / 100);
    const total = subtotal + marginAmount + taxAmount;

    return {
      subtotal,
      marginAmount,
      taxAmount,
      total,
    };
  };

  const onFormSubmit = async (data: BudgetFormData) => {
    try {
      await onSubmit({
        ...data,
        items,
      });
      onClose();
    } catch (error) {
      console.error('Error submitting budget:', error);
    }
  };

  const totals = calculateTotals(watch());

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Orçamento do Projeto"
    >
      <form onSubmit={handleSubmit(onFormSubmit)} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Cliente
          </label>
          <select
            {...register('clientId')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Selecione um cliente</option>
            {clients?.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name} - {client.company}
              </option>
            ))}
          </select>
          {errors.clientId && (
            <p className="mt-1 text-sm text-red-600">{errors.clientId.message}</p>
          )}
        </div>

        <div className="space-y-4">
          {items.map((item, index) => (
            <div key={index} className="flex gap-4 items-start p-4 bg-gray-50 rounded-lg">
              <div className="flex-1 space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Descrição
                  </label>
                  <input
                    type="text"
                    value={item.description}
                    onChange={(e) => {
                      const newItems = [...items];
                      newItems[index].description = e.target.value;
                      setItems(newItems);
                    }}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Categoria
                    </label>
                    <select
                      value={item.category}
                      onChange={(e) => {
                        const newItems = [...items];
                        newItems[index].category = e.target.value as any;
                        setItems(newItems);
                      }}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="labor">Mão de obra</option>
                      <option value="material">Material</option>
                      <option value="software">Software</option>
                      <option value="infrastructure">Infraestrutura</option>
                      <option value="other">Outros</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Quantidade
                    </label>
                    <input
                      type="number"
                      min="1"
                      value={item.quantity}
                      onChange={(e) => {
                        const newItems = [...items];
                        newItems[index].quantity = Number(e.target.value);
                        newItems[index].total = newItems[index].quantity * newItems[index].unitPrice;
                        setItems(newItems);
                      }}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Preço Unitário
                    </label>
                    <input
                      type="number"
                      min="0"
                      step="0.01"
                      value={item.unitPrice}
                      onChange={(e) => {
                        const newItems = [...items];
                        newItems[index].unitPrice = Number(e.target.value);
                        newItems[index].total = newItems[index].quantity * newItems[index].unitPrice;
                        setItems(newItems);
                      }}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Total
                    </label>
                    <input
                      type="number"
                      readOnly
                      value={item.quantity * item.unitPrice}
                      className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 shadow-sm sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              <button
                type="button"
                onClick={() => removeItem(index)}
                className="p-2 text-red-600 hover:text-red-800"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </div>
          ))}

          <button
            type="button"
            onClick={addItem}
            className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Plus className="h-5 w-5 inline-block mr-2" />
            Adicionar Item
          </button>
        </div>

        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Margem (%)
              </label>
              <input
                type="number"
                min="0"
                max="100"
                {...register('margin', { valueAsNumber: true })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              {errors.margin && (
                <p className="mt-1 text-sm text-red-600">{errors.margin.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Taxa (%)
              </label>
              <input
                type="number"
                min="0"
                max="100"
                {...register('taxRate', { valueAsNumber: true })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              {errors.taxRate && (
                <p className="mt-1 text-sm text-red-600">{errors.taxRate.message}</p>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Observações
            </label>
            <textarea
              {...register('notes')}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="border-t border-gray-200 pt-4 space-y-2">
          <div className="flex justify-between text-sm">
            <span className="font-medium">Subtotal:</span>
            <span>R$ {totals.subtotal.toFixed(2)}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="font-medium">Margem:</span>
            <span>R$ {totals.marginAmount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="font-medium">Impostos:</span>
            <span>R$ {totals.taxAmount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between text-base font-bold">
            <span>Total:</span>
            <span>R$ {totals.total.toFixed(2)}</span>
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancelar
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {isSubmitting ? 'Salvando...' : 'Salvar'}
          </button>
        </div>
      </form>
    </Modal>
  );
}