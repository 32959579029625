import { useState } from 'react';
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { FunnelStage } from './FunnelStage';
import { FunnelCard } from './FunnelCard';

const stages = [
  { id: 'prospect', name: 'Prospecto', color: 'bg-gray-100' },
  { id: 'budget', name: 'Orçamento', color: 'bg-blue-100' },
  { id: 'proposal', name: 'Confecção da Proposta', color: 'bg-indigo-100' },
  { id: 'sent', name: 'Proposta Enviada', color: 'bg-purple-100' },
  { id: 'meeting', name: 'Reunião de Alinhamento', color: 'bg-pink-100' },
  { id: 'approved', name: 'Proposta Aprovada', color: 'bg-green-100' },
  { id: 'rejected', name: 'Proposta Recusada', color: 'bg-red-100' },
  { id: 'pending', name: 'Pendente Cliente', color: 'bg-yellow-100' },
];

export function SalesFunnel() {
  const [activeId, setActiveId] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { data: proposals = [], isLoading } = useQuery({
    queryKey: ['proposals'],
    queryFn: async () => {
      // Mock data for development
      return [
        {
          id: '1',
          title: 'AI Chatbot Project',
          clientName: 'Tech Corp',
          value: 50000,
          validUntil: '2024-04-15',
          responsibleName: 'John Doe',
          stage: 'prospect',
        },
        {
          id: '2',
          title: 'ML Pipeline Implementation',
          clientName: 'Data Inc',
          value: 75000,
          validUntil: '2024-04-30',
          responsibleName: 'Jane Smith',
          stage: 'budget',
        },
      ];
    },
  });

  const updateStageMutation = useMutation({
    mutationFn: async ({ proposalId, newStage }: { proposalId: string; newStage: string }) => {
      // TODO: Replace with actual API call
      console.log('Updating proposal stage:', { proposalId, newStage });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['proposals'] });
      toast.success('Proposta movida com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao mover proposta');
    },
  });

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;

    if (!over) return;

    const proposalId = active.id;
    const newStage = over.id;

    try {
      await updateStageMutation.mutateAsync({ proposalId, newStage });
    } catch (error) {
      console.error('Error updating stage:', error);
    }

    setActiveId(null);
  };

  const getStageMetrics = (stageId: string) => {
    const stageProposals = proposals.filter(p => p.stage === stageId);
    return {
      count: stageProposals.length,
      value: stageProposals.reduce((sum, p) => sum + p.value, 0),
    };
  };

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div className="flex gap-4 overflow-x-auto pb-4">
        {stages.map((stage) => (
          <FunnelStage
            key={stage.id}
            stage={stage}
            proposals={proposals.filter(p => p.stage === stage.id)}
            metrics={getStageMetrics(stage.id)}
          />
        ))}
      </div>

      <DragOverlay>
        {activeId ? (
          <FunnelCard
            proposal={proposals.find(p => p.id === activeId)}
            isDragging
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}