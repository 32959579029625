import { useQuery } from '@tanstack/react-query';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { MetricCard } from '../dashboard/MetricCard';
import { DollarSign, TrendingUp, ArrowUpRight, ArrowDownRight } from 'lucide-react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export function FinanceDashboard() {
  const { data: metrics } = useQuery({
    queryKey: ['finance-metrics'],
    queryFn: async () => ({
      revenue: 250000,
      expenses: 180000,
      profit: 70000,
      profitMargin: 28,
      revenueGrowth: 15,
      expenseGrowth: 8,
      accountsReceivable: 85000,
      accountsPayable: 45000,
    }),
  });

  const { data: chartData } = useQuery({
    queryKey: ['finance-charts'],
    queryFn: async () => ({
      cashFlow: {
        labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
        datasets: [
          {
            label: 'Receitas',
            data: [180000, 190000, 210000, 220000, 235000, 250000],
            borderColor: 'rgb(34, 197, 94)',
            backgroundColor: 'rgba(34, 197, 94, 0.5)',
          },
          {
            label: 'Despesas',
            data: [150000, 155000, 165000, 170000, 175000, 180000],
            borderColor: 'rgb(239, 68, 68)',
            backgroundColor: 'rgba(239, 68, 68, 0.5)',
          },
        ],
      },
      expensesByCategory: {
        labels: ['Pessoal', 'Marketing', 'Infraestrutura', 'Software', 'Outros'],
        datasets: [
          {
            data: [45, 20, 15, 12, 8],
            backgroundColor: [
              'rgba(99, 102, 241, 0.8)',
              'rgba(59, 130, 246, 0.8)',
              'rgba(147, 51, 234, 0.8)',
              'rgba(236, 72, 153, 0.8)',
              'rgba(249, 115, 22, 0.8)',
            ],
          },
        ],
      },
      revenueByProject: {
        labels: ['Projeto A', 'Projeto B', 'Projeto C', 'Projeto D', 'Outros'],
        datasets: [
          {
            label: 'Receita por Projeto',
            data: [80000, 65000, 45000, 35000, 25000],
            backgroundColor: 'rgba(99, 102, 241, 0.8)',
          },
        ],
      },
    }),
  });

  if (!metrics || !chartData) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <MetricCard
          title="Receita Total"
          value={`R$ ${metrics.revenue.toLocaleString('pt-BR')}`}
          icon={DollarSign}
          trend={{ value: metrics.revenueGrowth, isPositive: true }}
        />
        <MetricCard
          title="Despesas Totais"
          value={`R$ ${metrics.expenses.toLocaleString('pt-BR')}`}
          icon={ArrowDownRight}
          trend={{ value: metrics.expenseGrowth, isPositive: false }}
        />
        <MetricCard
          title="Lucro"
          value={`R$ ${metrics.profit.toLocaleString('pt-BR')}`}
          icon={TrendingUp}
          trend={{ value: metrics.profitMargin, isPositive: true }}
        />
        <MetricCard
          title="A Receber"
          value={`R$ ${metrics.accountsReceivable.toLocaleString('pt-BR')}`}
          icon={ArrowUpRight}
        />
      </div>

      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Fluxo de Caixa
          </h3>
          <div className="h-80">
            <Line
              data={chartData.cashFlow}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom',
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      callback: (value) =>
                        `R$ ${Number(value).toLocaleString('pt-BR')}`,
                    },
                  },
                },
              }}
            />
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Despesas por Categoria
          </h3>
          <div className="h-80">
            <Doughnut
              data={chartData.expensesByCategory}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Receita por Projeto
        </h3>
        <div className="h-80">
          <Bar
            data={chartData.revenueByProject}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    callback: (value) =>
                      `R$ ${Number(value).toLocaleString('pt-BR')}`,
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}