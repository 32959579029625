import { useState } from 'react';
import { Tab } from '@headlessui/react';
import { Settings as SettingsIcon, Database, Shield, Bell } from 'lucide-react';
import { DatabaseConnection } from '../components/settings/DatabaseConnection';

export default function Settings() {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [
    { name: 'Banco de Dados', icon: Database, component: DatabaseConnection },
    { name: 'Segurança', icon: Shield },
    { name: 'Notificações', icon: Bell },
    { name: 'Geral', icon: SettingsIcon },
  ];

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-semibold text-gray-900">Configurações</h1>
        <p className="mt-1 text-sm text-gray-500">
          Gerencie as configurações do sistema
        </p>
      </div>

      <div className="bg-white shadow rounded-lg">
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.List className="flex space-x-4 p-4 border-b">
            {tabs.map((tab) => (
              <Tab
                key={tab.name}
                className={({ selected }) =>
                  `flex items-center px-4 py-2 text-sm font-medium rounded-md transition-colors ${
                    selected
                      ? 'bg-indigo-50 text-indigo-700'
                      : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                  }`
                }
              >
                <tab.icon className="h-5 w-5 mr-2" />
                {tab.name}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels>
            {tabs.map((tab, idx) => (
              <Tab.Panel key={idx} className="p-6">
                {tab.component ? <tab.component /> : (
                  <div className="text-center py-6 text-gray-500">
                    <tab.icon className="h-12 w-12 mx-auto text-gray-400 mb-4" />
                    <h3 className="text-lg font-medium text-gray-900 mb-2">
                      {tab.name}
                    </h3>
                    <p>Esta seção está em desenvolvimento.</p>
                  </div>
                )}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}