import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Plus, Search, Building2, Tag, MapPin, Phone } from 'lucide-react';
import { Modal } from '../ui/Modal';
import { SupplierForm } from './SupplierForm';
import { toast } from 'react-hot-toast';

interface Supplier {
  id: string;
  name: string;
  type: 'supplier' | 'partner';
  category: string;
  document: string;
  email: string;
  phone: string;
  address: string;
  paymentTerms?: string;
  bankInfo?: {
    bank: string;
    agency: string;
    account: string;
    type: string;
  };
  status: 'active' | 'inactive';
  rating?: number;
  tags: string[];
  notes?: string;
}

export function SuppliersList() {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(null);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ['suppliers', { search, filter }],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return {
        suppliers: [
          {
            id: '1',
            name: 'Tech Solutions Inc',
            type: 'supplier',
            category: 'Software',
            document: '12.345.678/0001-90',
            email: 'contact@techsolutions.com',
            phone: '(11) 99999-9999',
            address: 'Rua da Tecnologia, 123',
            paymentTerms: '30 dias',
            bankInfo: {
              bank: 'Banco do Brasil',
              agency: '1234-5',
              account: '12345-6',
              type: 'Corrente',
            },
            status: 'active',
            rating: 4.5,
            tags: ['Software', 'Cloud', 'Infraestrutura'],
          },
        ] as Supplier[],
        metrics: {
          totalSuppliers: 15,
          totalPartners: 8,
          activeSuppliers: 12,
          averageRating: 4.2,
        },
      };
    },
  });

  const createMutation = useMutation({
    mutationFn: async (supplier: Partial<Supplier>) => {
      // TODO: Replace with actual API call
      console.log('Creating supplier:', supplier);
      return { ...supplier, id: Date.now().toString() };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['suppliers'] });
      setIsModalOpen(false);
      setSelectedSupplier(null);
      toast.success('Fornecedor cadastrado com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao cadastrar fornecedor');
    },
  });

  const updateMutation = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<Supplier> }) => {
      // TODO: Replace with actual API call
      console.log('Updating supplier:', { id, data });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['suppliers'] });
      setIsModalOpen(false);
      setSelectedSupplier(null);
      toast.success('Fornecedor atualizado com sucesso!');
    },
    onError: () => {
      toast.error('Erro ao atualizar fornecedor');
    },
  });

  const handleSubmit = async (data: Partial<Supplier>) => {
    if (selectedSupplier) {
      await updateMutation.mutateAsync({ id: selectedSupplier.id, data });
    } else {
      await createMutation.mutateAsync(data);
    }
  };

  if (isLoading) return <div>Carregando...</div>;

  const { suppliers, metrics } = data;

  return (
    <div className="space-y-6">
      {/* Metrics Cards */}
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-4">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Building2 className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Total de Fornecedores
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {metrics.totalSuppliers}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Tag className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Total de Parceiros
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {metrics.totalPartners}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Building2 className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Fornecedores Ativos
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {metrics.activeSuppliers}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Tag className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Avaliação Média
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {metrics.averageRating.toFixed(1)}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Filters */}
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex-1 max-w-xs">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar fornecedores e parceiros..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="all">Todos</option>
            <option value="suppliers">Fornecedores</option>
            <option value="partners">Parceiros</option>
            <option value="active">Ativos</option>
            <option value="inactive">Inativos</option>
          </select>

          <button
            onClick={() => {
              setSelectedSupplier(null);
              setIsModalOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Novo Cadastro
          </button>
        </div>
      </div>

      {/* Suppliers List */}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {suppliers.map((supplier) => (
          <div
            key={supplier.id}
            className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
            onClick={() => {
              setSelectedSupplier(supplier);
              setIsModalOpen(true);
            }}
          >
            <div className="p-6">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-gray-900">
                  {supplier.name}
                </h3>
                <span
                  className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    supplier.type === 'supplier'
                      ? 'bg-blue-100 text-blue-800'
                      : 'bg-purple-100 text-purple-800'
                  }`}
                >
                  {supplier.type === 'supplier' ? 'Fornecedor' : 'Parceiro'}
                </span>
              </div>

              <div className="mt-2">
                <p className="text-sm text-gray-500">{supplier.category}</p>
              </div>

              <div className="mt-4 space-y-2">
                <div className="flex items-center text-sm">
                  <MapPin className="h-4 w-4 text-gray-400 mr-2" />
                  <span className="text-gray-600">{supplier.address}</span>
                </div>
                <div className="flex items-center text-sm">
                  <Phone className="h-4 w-4 text-gray-400 mr-2" />
                  <span className="text-gray-600">{supplier.phone}</span>
                </div>
              </div>

              <div className="mt-4">
                <div className="flex items-center">
                  <span className="text-sm text-gray-500 mr-2">Avaliação:</span>
                  <div className="flex items-center">
                    {[...Array(5)].map((_, i) => (
                      <svg
                        key={i}
                        className={`h-4 w-4 ${
                          i < Math.floor(supplier.rating || 0)
                            ? 'text-yellow-400'
                            : 'text-gray-300'
                        }`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 15.934L4.618 19.247l1.03-6.987L.836 7.653l6.342-.892L10 .453l2.822 6.308 6.342.892-4.812 4.607 1.03 6.987L10 15.934z"
                        />
                      </svg>
                    ))}
                  </div>
                </div>
              </div>

              <div className="mt-4 flex flex-wrap gap-2">
                {supplier.tags.map((tag) => (
                  <span
                    key={tag}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Supplier Form Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedSupplier(null);
        }}
        title={selectedSupplier ? 'Editar Cadastro' : 'Novo Cadastro'}
      >
        <SupplierForm
          supplier={selectedSupplier}
          onSubmit={handleSubmit}
          onCancel={() => {
            setIsModalOpen(false);
            setSelectedSupplier(null);
          }}
        />
      </Modal>
    </div>
  );
}