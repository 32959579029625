import { Brain } from 'lucide-react';
import { useAuthStore } from '@/store/authStore';

export function Logo() {
  const { company } = useAuthStore();
  
  return (
    <div className="flex flex-shrink-0 items-center px-4 py-5">
      <Brain className="h-8 w-8 text-indigo-600" />
      <span className="ml-2 text-xl font-semibold text-gray-900">
        {company?.name || 'AI Enterprise'}
      </span>
    </div>
  );
}