import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { Product } from '@/types/product';

const saleSchema = z.object({
  title: z.string().min(1, 'Título é obrigatório'),
  clientId: z.string().min(1, 'Cliente é obrigatório'),
  salesmanId: z.string().min(1, 'Vendedor é obrigatório'),
  productId: z.string().min(1, 'Produto é obrigatório'),
  value: z.number().min(0.01, 'Valor deve ser maior que zero'),
  description: z.string().optional(),
  stage: z.string().default('prospect'),
  expectedClosingDate: z.string(),
  probability: z.number().min(0).max(100).default(50),
  notes: z.string().optional(),
});

type SaleFormData = z.infer<typeof saleSchema>;

interface SaleFormProps {
  sale?: any;
  onSubmit: (data: SaleFormData) => Promise<void>;
  onCancel: () => void;
}

export function SaleForm({ sale, onSubmit, onCancel }: SaleFormProps) {
  const { data: clients } = useQuery({
    queryKey: ['clients'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        { id: '1', name: 'Tech Corp' },
        { id: '2', name: 'Data Inc' },
      ];
    },
  });

  const { data: salesmen } = useQuery({
    queryKey: ['salesmen'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        { id: '1', name: 'John Doe' },
        { id: '2', name: 'Jane Smith' },
      ];
    },
  });

  const { data: products } = useQuery<Product[]>({
    queryKey: ['products'],
    queryFn: async () => {
      // TODO: Replace with actual API call
      return [
        {
          id: '1',
          name: 'AI Chatbot',
          basePrice: 50000,
          description: 'AI-powered chatbot solution',
          category: 'AI Solutions',
          type: 'service',
          status: 'active',
          features: [],
          customizable: true,
          createdAt: '',
          updatedAt: '',
        },
      ];
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<SaleFormData>({
    resolver: zodResolver(saleSchema),
    defaultValues: sale || {
      stage: 'prospect',
      probability: 50,
      expectedClosingDate: new Date().toISOString().split('T')[0],
    },
  });

  const selectedProduct = watch('productId');
  const product = products?.find(p => p.id === selectedProduct);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">
            Título
          </label>
          <input
            type="text"
            {...register('title')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.title && (
            <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Cliente
          </label>
          <select
            {...register('clientId')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Selecione um cliente</option>
            {clients?.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </select>
          {errors.clientId && (
            <p className="mt-1 text-sm text-red-600">{errors.clientId.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Vendedor
          </label>
          <select
            {...register('salesmanId')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Selecione um vendedor</option>
            {salesmen?.map((salesman) => (
              <option key={salesman.id} value={salesman.id}>
                {salesman.name}
              </option>
            ))}
          </select>
          {errors.salesmanId && (
            <p className="mt-1 text-sm text-red-600">{errors.salesmanId.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Produto
          </label>
          <select
            {...register('productId')}
            onChange={(e) => {
              const product = products?.find(p => p.id === e.target.value);
              if (product) {
                setValue('value', product.basePrice);
              }
            }}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Selecione um produto</option>
            {products?.map((product) => (
              <option key={product.id} value={product.id}>
                {product.name} - R$ {product.basePrice.toLocaleString('pt-BR')}
              </option>
            ))}
          </select>
          {errors.productId && (
            <p className="mt-1 text-sm text-red-600">{errors.productId.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Valor
          </label>
          <input
            type="number"
            step="0.01"
            {...register('value', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          {errors.value && (
            <p className="mt-1 text-sm text-red-600">{errors.value.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Probabilidade (%)
          </label>
          <input
            type="number"
            min="0"
            max="100"
            {...register('probability', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Previsão de Fechamento
          </label>
          <input
            type="date"
            {...register('expectedClosingDate')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">
            Descrição
          </label>
          <textarea
            {...register('description')}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">
            Observações
          </label>
          <textarea
            {...register('notes')}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </div>

      <div className="mt-6 flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancelar
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
        >
          {isSubmitting ? 'Salvando...' : 'Salvar'}
        </button>
      </div>
    </form>
  );
}