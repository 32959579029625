import { useAuthStore } from '@/store/authStore';

export function CompanyInfo() {
  const { company } = useAuthStore();

  return (
    <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
      <div className="flex items-center">
        <div className="ml-3">
          <p className="text-sm font-medium text-gray-700">
            {company?.industry || 'Artificial Intelligence'}
          </p>
          <p className="text-xs text-gray-500">
            {company?.isAICompany ? 'AI Company' : 'Technology'}
          </p>
        </div>
      </div>
    </div>
  );
}